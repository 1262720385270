"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoAccountWarningController = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
class NoAccountWarningController extends AbstractViewController_1.AbstractViewController {
    constructor(application, eventBus) {
        var _a;
        super(application, eventBus);
        this.hide = () => {
            this.show = false;
            ui_services_1.storage.set(ui_services_1.StorageKey.ShowNoAccountWarning, false);
        };
        this.reset = () => {
            ui_services_1.storage.remove(ui_services_1.StorageKey.ShowNoAccountWarning);
        };
        this.show = application.hasAccount() ? false : (_a = ui_services_1.storage.get(ui_services_1.StorageKey.ShowNoAccountWarning)) !== null && _a !== void 0 ? _a : true;
        this.disposers.push(application.addEventObserver(async () => {
            (0, mobx_1.runInAction)(() => {
                this.show = false;
            });
        }, snjs_1.ApplicationEvent.SignedIn));
        this.disposers.push(application.addEventObserver(async () => {
            if (application.hasAccount()) {
                (0, mobx_1.runInAction)(() => {
                    this.show = false;
                });
            }
        }, snjs_1.ApplicationEvent.Started));
        (0, mobx_1.makeObservable)(this, {
            show: mobx_1.observable,
            hide: mobx_1.action,
        });
    }
}
exports.NoAccountWarningController = NoAccountWarningController;
