"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProtocolOperator005 = void 0;
const Algorithm_1 = require("../../Algorithm");
const Operator004_1 = require("../004/Operator004");
const VersionString = '005';
const SymmetricCiphertextPrefix = `${VersionString}_KeySym`;
const AsymmetricCiphertextPrefix = `${VersionString}_KeyAsym`;
/**
 * @experimental
 * @unreleased
 */
class ProtocolOperator005 extends Operator004_1.SNProtocolOperator004 {
    getEncryptionDisplayName() {
        return 'XChaCha20-Poly1305';
    }
    get version() {
        return VersionString;
    }
    generateKeyPair() {
        return this.crypto.sodiumCryptoBoxGenerateKeypair();
    }
    asymmetricEncryptKey(keyToEncrypt, senderSecretKey, recipientPublicKey) {
        const nonce = this.crypto.generateRandomKey(Algorithm_1.V005Algorithm.AsymmetricEncryptionNonceLength);
        const ciphertext = this.crypto.sodiumCryptoBoxEasyEncrypt(keyToEncrypt, nonce, senderSecretKey, recipientPublicKey);
        return [AsymmetricCiphertextPrefix, nonce, ciphertext].join(':');
    }
    asymmetricDecryptKey(keyToDecrypt, senderPublicKey, recipientSecretKey) {
        const components = keyToDecrypt.split(':');
        const nonce = components[1];
        return this.crypto.sodiumCryptoBoxEasyDecrypt(keyToDecrypt, nonce, senderPublicKey, recipientSecretKey);
    }
    symmetricEncryptPrivateKey(privateKey, symmetricKey) {
        if (symmetricKey.length !== 64) {
            throw new Error('Symmetric key length must be 256 bits');
        }
        const nonce = this.crypto.generateRandomKey(Algorithm_1.V005Algorithm.SymmetricEncryptionNonceLength);
        const encryptedKey = this.crypto.xchacha20Encrypt(privateKey, nonce, symmetricKey);
        return [SymmetricCiphertextPrefix, nonce, encryptedKey].join(':');
    }
    symmetricDecryptPrivateKey(encryptedPrivateKey, symmetricKey) {
        if (symmetricKey.length !== 64) {
            throw new Error('Symmetric key length must be 256 bits');
        }
        const components = encryptedPrivateKey.split(':');
        const nonce = components[1];
        return this.crypto.xchacha20Decrypt(encryptedPrivateKey, nonce, symmetricKey);
    }
}
exports.ProtocolOperator005 = ProtocolOperator005;
