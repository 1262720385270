"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSameDay = exports.assertUnreachable = exports.sleep = exports.truncateHexString = exports.hasGetter = exports.isValidUrl = exports.deepFreeze = exports.pickByCopy = exports.deepMerge = exports.Copy = exports.joinPaths = exports.omitByCopy = exports.omitInPlace = exports.withoutLastElement = exports.jsonParseEmbeddedKeys = exports.topLevelCompare = exports.dateSorted = exports.omitUndefinedCopy = exports.sortByKey = exports.sortedCopy = exports.objectToValueArray = exports.arrayByRemovingFromIndex = exports.addAtIndex = exports.removeFromIndex = exports.compareValues = exports.arrayByDifference = exports.filterFromArray = exports.addIfUnique = exports.removeFromArray = exports.subtractFromArray = exports.extendArray = exports.lastElement = exports.uniqueArrayByKey = exports.uniqueArray = exports.uniqCombineObjArrays = exports.greaterOfTwoDates = exports.isString = exports.isEmpty = exports.isNotUndefined = exports.isNullOrUndefined = exports.isFunction = exports.isObject = exports.sureSearchArray = exports.searchArray = exports.findInArray = exports.isReactNativeEnvironment = exports.isWebCryptoAvailable = exports.isWebEnvironment = exports.dictToArray = exports.getGlobalScope = void 0;
exports.pluralize = exports.spaceSeparatedStrings = exports.useBoolean = exports.assert = exports.logWithColor = exports.log = exports.secondHalfOfString = exports.firstHalfOfString = exports.splitString = exports.nonSecureRandomIdentifier = exports.dateToLocalizedString = exports.sanitizeHtmlString = exports.convertTimestampToMilliseconds = exports.arraysEqual = exports.naturalSort = void 0;
/* eslint-disable @typescript-eslint/no-explicit-any */
const dompurify_1 = require("dompurify");
const lodash_1 = require("lodash");
const collator = typeof Intl !== 'undefined' ? new Intl.Collator('en', { numeric: true }) : undefined;
function getGlobalScope() {
    return typeof window !== 'undefined' ? window : typeof global !== 'undefined' ? global : null;
}
exports.getGlobalScope = getGlobalScope;
function dictToArray(dict) {
    return Object.values(dict);
}
exports.dictToArray = dictToArray;
/**
 * Whether we are in a web browser
 */
function isWebEnvironment() {
    return getGlobalScope() !== null;
}
exports.isWebEnvironment = isWebEnvironment;
/**
 * @returns true if WebCrypto is available
 */
function isWebCryptoAvailable() {
    return ((isWebEnvironment() && !isReactNativeEnvironment() && !(document && document.documentMode)) ||
        (/Edge/.test(navigator.userAgent) && window.crypto && !!window.crypto.subtle));
}
exports.isWebCryptoAvailable = isWebCryptoAvailable;
/**
 * Whether we are in React Native app
 */
function isReactNativeEnvironment() {
    return typeof navigator !== 'undefined' && navigator.product === 'ReactNative';
}
exports.isReactNativeEnvironment = isReactNativeEnvironment;
/**
 * Searches array of objects for first object where object[key] === value
 * @returns Matching object or null if not found
 */
function findInArray(array, key, value) {
    return array.find((item) => item[key] === value);
}
exports.findInArray = findInArray;
/**
 * Searches array of objects for first object where object[key] === value
 * @returns Matching object or null if not found
 */
function searchArray(array, predicate) {
    return (0, lodash_1.find)(array, predicate);
}
exports.searchArray = searchArray;
function sureSearchArray(array, predicate) {
    return searchArray(array, predicate);
}
exports.sureSearchArray = sureSearchArray;
/**
 * @returns Whether the value is a function or object
 */
function isObject(value) {
    if (value === null) {
        return false;
    }
    return typeof value === 'function' || typeof value === 'object';
}
exports.isObject = isObject;
/**
 * @returns Whether the value is a function
 */
function isFunction(value) {
    if (value === null) {
        return false;
    }
    return typeof value === 'function';
}
exports.isFunction = isFunction;
/**
 * @returns True if the object is null or undefined, otherwise false
 */
function isNullOrUndefined(value) {
    return value === null || value === undefined;
}
exports.isNullOrUndefined = isNullOrUndefined;
function isNotUndefined(val) {
    return val != undefined;
}
exports.isNotUndefined = isNotUndefined;
/**
 * @returns True if the string is empty or undefined
 */
function isEmpty(string) {
    return !string || string.length === 0;
}
exports.isEmpty = isEmpty;
/**
 * @returns Whether the value is a string
 */
function isString(value) {
    return typeof value === 'string' || value instanceof String;
}
exports.isString = isString;
/**
 * @returns The greater of the two dates
 */
function greaterOfTwoDates(dateA, dateB) {
    if (dateA > dateB) {
        return dateA;
    }
    else {
        return dateB;
    }
}
exports.greaterOfTwoDates = greaterOfTwoDates;
/**
 * Returns a new array containing only unique values by combining the two input arrays.
 * Elements are unique based on the values of `equalityKeys`.
 * @param equalityKeys - Keys to determine element equality
 * @returns Array containing unique values
 */
function uniqCombineObjArrays(arrayA, arrayB, equalityKeys) {
    return (0, lodash_1.uniqWith)(arrayA.concat(arrayB), (a, b) => {
        for (const key of equalityKeys) {
            if (a[key] !== b[key]) {
                return false;
            }
        }
        return true;
    });
}
exports.uniqCombineObjArrays = uniqCombineObjArrays;
/**
 * Returns a new array containing only unique values
 * @returns Array containing unique values
 */
function uniqueArray(array) {
    return (0, lodash_1.uniq)(array);
}
exports.uniqueArray = uniqueArray;
/**
 * Returns a new array containing only unique values
 * @returns Array containing unique values
 */
function uniqueArrayByKey(array, key) {
    return (0, lodash_1.uniqWith)(array, (a, b) => {
        return a[key] === b[key];
    });
}
exports.uniqueArrayByKey = uniqueArrayByKey;
/**
 * Returns the last element in the array.
 * @returns The last element in the array
 */
function lastElement(array) {
    return array[array.length - 1];
}
exports.lastElement = lastElement;
/**
 * Adds all items from otherArray into inArray, in-place.
 * Does not return a value.
 */
function extendArray(inArray, otherArray) {
    for (const value of otherArray) {
        inArray.push(value);
    }
}
exports.extendArray = extendArray;
/**
 * Removes all items appearing in toSubtract from inArray, in-place
 * @param toSubtract - The list of items to remove from inArray
 */
function subtractFromArray(inArray, toSubtract) {
    for (const value of toSubtract) {
        removeFromArray(inArray, value);
    }
}
exports.subtractFromArray = subtractFromArray;
/**
 * Removes the first matching element of an array by strict equality.
 * If no matchin element is found, the array is left unchanged.
 */
function removeFromArray(array, value) {
    const valueIndex = array.indexOf(value);
    if (valueIndex === -1) {
        return;
    }
    array.splice(valueIndex, 1);
}
exports.removeFromArray = removeFromArray;
/**
 * Adds the element to the array if the array does not already include the value.
 * The array is searched via array.indexOf
 * @returns true if value was added
 */
function addIfUnique(array, value) {
    if (!array.includes(value)) {
        array.push(value);
        return true;
    }
    return false;
}
exports.addIfUnique = addIfUnique;
/**
 * Removes an object from the array in-place by searching for an object where all the
 * key/values in predicate match with the candidate element.
 */
function filterFromArray(array, predicate) {
    (0, lodash_1.remove)(array, predicate);
}
exports.filterFromArray = filterFromArray;
/**
 * Returns a new array by removing all elements in subtract from array
 */
function arrayByDifference(array, subtract) {
    return array.filter((x) => !subtract.includes(x)).concat(subtract.filter((x) => !array.includes(x)));
}
exports.arrayByDifference = arrayByDifference;
function compareValues(left, right) {
    if ((left && !right) || (!left && right)) {
        return false;
    }
    if (left instanceof Date && right instanceof Date) {
        return left.getTime() === right.getTime();
    }
    else if (left instanceof String && right instanceof String) {
        return left === right;
    }
    else {
        return topLevelCompare(left, right);
    }
}
exports.compareValues = compareValues;
/**
 * Removes the value from the array at the given index, in-place.
 */
function removeFromIndex(array, index) {
    array.splice(index, 1);
}
exports.removeFromIndex = removeFromIndex;
/**
 * Adds the value from the array at the given index, in-place.
 */
function addAtIndex(array, element, index) {
    array.splice(index, 0, element);
}
exports.addAtIndex = addAtIndex;
/**
 * Returns a new array by removeing the value from the array at the given index
 */
function arrayByRemovingFromIndex(array, index) {
    const copy = array.slice();
    removeFromIndex(copy, index);
    return copy;
}
exports.arrayByRemovingFromIndex = arrayByRemovingFromIndex;
/**
 * Returns an array where each element is the value of a top-level
 * object key.
 * Example: objectToValueArray({a: 1, b: 2}) returns [1, 2]
 */
function objectToValueArray(object) {
    const values = [];
    for (const key of Object.keys(object)) {
        values.push(object[key]);
    }
    return values;
}
exports.objectToValueArray = objectToValueArray;
/**
 * Returns a key-sorted copy of the object.
 * For example, sortedCopy({b: '1', a: '2'}) returns {a: '2', b: '1'}
 */
function sortedCopy(object) {
    const keys = Object.keys(object).sort();
    const result = {};
    for (const key of keys) {
        result[key] = object[key];
    }
    return Copy(result);
}
exports.sortedCopy = sortedCopy;
const sortByKey = (input, key) => {
    const compare = (a, b) => {
        const valueA = a[key];
        const valueB = b[key];
        if (valueA < valueB) {
            return -1;
        }
        if (valueA > valueB) {
            return 1;
        }
        return 0;
    };
    const newArray = [...input];
    newArray.sort(compare);
    return newArray;
};
exports.sortByKey = sortByKey;
/** Returns a new object by omitting any keys which have an undefined or null value  */
function omitUndefinedCopy(object) {
    const result = {};
    for (const key of Object.keys(object)) {
        if (!isNullOrUndefined(object[key])) {
            result[key] = object[key];
        }
    }
    return result;
}
exports.omitUndefinedCopy = omitUndefinedCopy;
/**
 * Returns a new array by sorting an array of elements based on a date property,
 * as indicated by the input key value.
 */
function dateSorted(elements, key, ascending = true) {
    return elements.sort((a, b) => {
        const aTimestamp = a[key].getTime();
        const bTimestamp = b[key].getTime();
        const vector = ascending ? 1 : -1;
        if (aTimestamp < bTimestamp) {
            return -1 * vector;
        }
        else if (aTimestamp > bTimestamp) {
            return 1 * vector;
        }
        else {
            return 0;
        }
    });
}
exports.dateSorted = dateSorted;
/** Compares for equality by comparing top-level keys value equality (===) */
function topLevelCompare(left, right) {
    if (!left && !right) {
        return true;
    }
    if (!left || !right) {
        return false;
    }
    const leftKeys = Object.keys(left);
    const rightKeys = Object.keys(right);
    if (leftKeys.length !== rightKeys.length) {
        return false;
    }
    for (const key of leftKeys) {
        if (left[key] !== right[key]) {
            return false;
        }
    }
    return true;
}
exports.topLevelCompare = topLevelCompare;
/**
 * Returns a new object by attempting to JSON.parse any top-level object keys.
 */
function jsonParseEmbeddedKeys(object) {
    const result = {};
    for (const key of Object.keys(object)) {
        let value;
        try {
            value = JSON.parse(object[key]);
        }
        catch (error) {
            value = object[key];
        }
        result[key] = value;
    }
    return result;
}
exports.jsonParseEmbeddedKeys = jsonParseEmbeddedKeys;
const withoutLastElement = (array) => {
    return array.slice(0, -1);
};
exports.withoutLastElement = withoutLastElement;
/**
 * Deletes keys of the input object.
 */
function omitInPlace(object, keys) {
    if (!object) {
        return;
    }
    for (const key of keys) {
        delete object[key];
    }
}
exports.omitInPlace = omitInPlace;
/**
 * Creates a new object by omitting `keys` from `object`
 */
function omitByCopy(object, keys) {
    if (isNullOrUndefined(object)) {
        return undefined;
    }
    const newObject = Object.assign({}, object);
    /**
     * Lodash's omit, which was previously used, seems to cause unexpected behavior
     * when payload is an ES6 item class. So we instead manually omit each key.
     */
    for (const key of keys) {
        delete newObject[key];
    }
    return newObject;
}
exports.omitByCopy = omitByCopy;
/**
 * Similiar to Node's path.join, this function combines an array of paths into
 * one resolved path.
 */
function joinPaths(...args) {
    return args
        .map((part, i) => {
        if (i === 0) {
            return part.trim().replace(/[/]*$/g, '');
        }
        else {
            return part.trim().replace(/(^[/]*|[/]*$)/g, '');
        }
    })
        .filter((x) => x.length)
        .join('/');
}
exports.joinPaths = joinPaths;
/**
 * Creates a copy of the input object by JSON stringifying the object then JSON parsing
 * the string (if the input is an object). If input is date, a Date copy will be created,
 * and if input is a primitive value, it will be returned as-is.
 */
function Copy(object) {
    if (object instanceof Date) {
        return new Date(object);
    }
    else if (isObject(object)) {
        return JSON.parse(JSON.stringify(object));
    }
    else {
        return object;
    }
}
exports.Copy = Copy;
/**
 * Merges the second object parameter into the first object, in-place.
 * @returns The now modified first object parameter passed into the function.
 */
function deepMerge(a, b) {
    /**
     * lodash.merge will not merge a full array with an empty one.
     * deepMerge will replace arrays wholesale
     */
    if (!a || !b) {
        throw 'Attempting to deepMerge with null values';
    }
    const customizer = (aValue, bValue) => {
        if ((0, lodash_1.isArray)(aValue)) {
            return bValue;
        }
    };
    (0, lodash_1.mergeWith)(a, b, customizer);
    return a;
}
exports.deepMerge = deepMerge;
/**
 * Returns a new object by selecting certain keys from input object.
 */
function pickByCopy(object, keys) {
    const result = {};
    for (const key of keys) {
        result[key] = object[key];
    }
    return Copy(result);
}
exports.pickByCopy = pickByCopy;
/**
 * Recursively makes an object immutable via Object.freeze
 */
function deepFreeze(object) {
    const propNames = Object.getOwnPropertyNames(object);
    for (const name of propNames) {
        const value = object[name];
        if (value && typeof value === 'object' && !Object.isFrozen(value)) {
            object[name] = deepFreeze(value);
        }
        else {
            object[name] = value;
        }
    }
    return Object.freeze(object);
}
exports.deepFreeze = deepFreeze;
function isValidUrl(url) {
    try {
        new URL(url);
        return true;
    }
    catch (error) {
        return false;
    }
}
exports.isValidUrl = isValidUrl;
/**
 * Determines if an object has a getter defined for a given property
 */
function hasGetter(object, property) {
    const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(object), property);
    return descriptor && !isNullOrUndefined(descriptor.get);
}
exports.hasGetter = hasGetter;
/**
 * Truncates a hex string into a desired number of bits
 * @returns A hexadecimal string truncated to the number of desired bits
 */
function truncateHexString(string, desiredBits) {
    const BITS_PER_HEX_CHAR = 4;
    const desiredCharLength = desiredBits / BITS_PER_HEX_CHAR;
    return string.substring(0, desiredCharLength);
}
exports.truncateHexString = truncateHexString;
/**
 * When awaited, this function allows code execution to pause for a set time.
 * Should be used primarily for testing.
 */
function sleep(milliseconds, warn = true, desc = '') {
    return __awaiter(this, void 0, void 0, function* () {
        if (warn) {
            console.warn(`Sleeping for ${milliseconds}ms ${desc}`);
        }
        return new Promise((resolve) => {
            setTimeout(function () {
                resolve();
            }, milliseconds);
        });
    });
}
exports.sleep = sleep;
function assertUnreachable(uncheckedCase) {
    throw Error('Unchecked case ' + uncheckedCase);
}
exports.assertUnreachable = assertUnreachable;
/**
 * Returns a boolean representing whether two dates are on the same day
 */
function isSameDay(dateA, dateB) {
    return (dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate());
}
exports.isSameDay = isSameDay;
/**
 * Sorts an array of objects in natural order
 * @param items - The array of objects to sort
 * @param property - The objects' property to sort by
 * @param direction - The sorting direction, either ascending (default) or descending
 * @returns Array of objects sorted in natural order
 */
function naturalSort(items, property, direction = 'asc') {
    switch (direction) {
        case 'asc':
            return [...items].sort(collator
                ? (a, b) => collator.compare(a[property], b[property])
                : (a, b) => a[property].localeCompare(b[property], 'en', { numeric: true }));
        case 'desc':
            return [...items].sort(collator
                ? (a, b) => collator.compare(b[property], a[property])
                : (a, b) => b[property].localeCompare(a[property], 'en', { numeric: true }));
    }
}
exports.naturalSort = naturalSort;
function arraysEqual(left, right) {
    if (left.length !== right.length) {
        return false;
    }
    return left.every((item) => right.includes(item)) && right.every((item) => left.includes(item));
}
exports.arraysEqual = arraysEqual;
const MicrosecondsInAMillisecond = 1000;
const MillisecondsInASecond = 1000;
var TimestampDigits;
(function (TimestampDigits) {
    TimestampDigits[TimestampDigits["Seconds"] = 10] = "Seconds";
    TimestampDigits[TimestampDigits["Milliseconds"] = 13] = "Milliseconds";
    TimestampDigits[TimestampDigits["Microseconds"] = 16] = "Microseconds";
})(TimestampDigits || (TimestampDigits = {}));
function convertTimestampToMilliseconds(timestamp) {
    const digits = String(timestamp).length;
    switch (digits) {
        case TimestampDigits.Seconds:
            return timestamp * MillisecondsInASecond;
        case TimestampDigits.Milliseconds:
            return timestamp;
        case TimestampDigits.Microseconds:
            return Math.floor(timestamp / MicrosecondsInAMillisecond);
        default:
            throw `Unhandled timestamp precision: ${timestamp}`;
    }
}
exports.convertTimestampToMilliseconds = convertTimestampToMilliseconds;
function sanitizeHtmlString(html) {
    return (0, dompurify_1.sanitize)(html);
}
exports.sanitizeHtmlString = sanitizeHtmlString;
let sharedDateFormatter;
function dateToLocalizedString(date) {
    if (typeof Intl !== 'undefined' && Intl.DateTimeFormat && typeof navigator !== 'undefined') {
        if (!sharedDateFormatter) {
            const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
            sharedDateFormatter = new Intl.DateTimeFormat(locale, {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'long',
                hour: '2-digit',
                minute: '2-digit',
            });
        }
        return sharedDateFormatter.format(date);
    }
    else {
        // IE < 11, Safari <= 9.0.
        // In English, this generates the string most similar to
        // the toLocaleDateString() result above.
        return date.toDateString() + ' ' + date.toLocaleTimeString();
    }
}
exports.dateToLocalizedString = dateToLocalizedString;
function nonSecureRandomIdentifier() {
    return `${Math.random() * 100}`.replace('.', '');
}
exports.nonSecureRandomIdentifier = nonSecureRandomIdentifier;
function splitString(string, parts) {
    const outputLength = string.length;
    const partLength = outputLength / parts;
    const partitions = [];
    for (let i = 0; i < parts; i++) {
        const partition = string.slice(partLength * i, partLength * (i + 1));
        partitions.push(partition);
    }
    return partitions;
}
exports.splitString = splitString;
function firstHalfOfString(string) {
    return string.substring(0, string.length / 2);
}
exports.firstHalfOfString = firstHalfOfString;
function secondHalfOfString(string) {
    return string.substring(string.length / 2, string.length);
}
exports.secondHalfOfString = secondHalfOfString;
function log(namespace, ...args) {
    logWithColor(namespace, 'black', ...args);
}
exports.log = log;
function logWithColor(namespace, namespaceColor, ...args) {
    const date = new Date();
    const timeString = `${date.toLocaleTimeString().replace(' PM', '').replace(' AM', '')}.${date.getMilliseconds()}`;
    customLog(`%c${namespace}%c${timeString}`, `color: ${namespaceColor}; font-weight: bold; margin-right: 4px`, 'color: gray', ...args);
}
exports.logWithColor = logWithColor;
function customLog(..._args) {
    // eslint-disable-next-line no-console, prefer-rest-params
    Function.prototype.apply.call(console.log, console, arguments);
}
function assert(value) {
    if (value === undefined) {
        throw new Error('Assertion failed; value must be defined');
    }
}
exports.assert = assert;
function useBoolean(value, defaultValue) {
    return value != undefined ? value : defaultValue;
}
exports.useBoolean = useBoolean;
function spaceSeparatedStrings(...strings) {
    return strings.join(' ');
}
exports.spaceSeparatedStrings = spaceSeparatedStrings;
function pluralize(count, singular, plural) {
    return count === 1 ? singular : plural;
}
exports.pluralize = pluralize;
