"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadOrShareBlobBasedOnPlatform = void 0;
const DownloadBlobOnAndroid_1 = require("@/NativeMobileWeb/DownloadBlobOnAndroid");
const ShareBlobOnMobile_1 = require("@/NativeMobileWeb/ShareBlobOnMobile");
const snjs_1 = require("@standardnotes/snjs");
const downloadOrShareBlobBasedOnPlatform = async (application, blob, filename, showToastOnAndroid = true) => {
    if (!application.isNativeMobileWeb()) {
        application.getArchiveService().downloadData(blob, filename);
        return;
    }
    if (application.platform === snjs_1.Platform.Ios) {
        void (0, ShareBlobOnMobile_1.shareBlobOnMobile)(application, blob, filename);
        return;
    }
    if (application.platform === snjs_1.Platform.Android) {
        void (0, DownloadBlobOnAndroid_1.downloadBlobOnAndroid)(application, blob, filename, showToastOnAndroid);
        return;
    }
};
exports.downloadOrShareBlobBasedOnPlatform = downloadOrShareBlobBasedOnPlatform;
