"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mobx_react_lite_1 = require("mobx-react-lite");
const Content_1 = require("@/Components/Preferences/PreferencesComponents/Content");
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const U2FDescription = ({ userProvider }) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    if (userProvider.getUser() === undefined) {
        return (0, jsx_runtime_1.jsx)(Content_1.Text, { children: "Sign in or register for an account to configure hardware security keys." });
    }
    return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(Content_1.Text, { children: "Authenticate with a hardware security key such as YubiKey." }), !application.isFullU2FClient && ((0, jsx_runtime_1.jsx)(Content_1.Text, { className: "italic", children: "Please visit the web app in order to add a hardware security key." }))] }));
};
exports.default = (0, mobx_react_lite_1.observer)(U2FDescription);
