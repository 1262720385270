"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileDownloader = void 0;
const responses_1 = require("@standardnotes/responses");
const utils_1 = require("@standardnotes/utils");
class FileDownloader {
    constructor(file, api) {
        this.file = file;
        this.api = api;
        this.aborted = false;
        this.abortDeferred = (0, utils_1.Deferred)();
        this.totalBytesDownloaded = 0;
    }
    getProgress() {
        const encryptedSize = this.file.encryptedChunkSizes.reduce((total, chunk) => total + chunk, 0);
        return {
            encryptedFileSize: encryptedSize,
            encryptedBytesDownloaded: this.totalBytesDownloaded,
            encryptedBytesRemaining: encryptedSize - this.totalBytesDownloaded,
            percentComplete: (this.totalBytesDownloaded / encryptedSize) * 100.0,
            source: 'network',
        };
    }
    async run(onEncryptedBytes) {
        const tokenResult = await this.getValetToken();
        if (tokenResult instanceof responses_1.ClientDisplayableError) {
            return tokenResult;
        }
        return this.performDownload(tokenResult, onEncryptedBytes);
    }
    async getValetToken() {
        const tokenResult = await this.api.createFileValetToken(this.file.remoteIdentifier, 'read');
        return tokenResult;
    }
    async performDownload(valetToken, onEncryptedBytes) {
        const chunkIndex = 0;
        const startRange = 0;
        const onRemoteBytesReceived = async (bytes) => {
            if (this.aborted) {
                return;
            }
            this.totalBytesDownloaded += bytes.byteLength;
            await onEncryptedBytes(bytes, this.getProgress(), this.abort);
        };
        const downloadPromise = this.api.downloadFile(this.file, chunkIndex, valetToken, startRange, onRemoteBytesReceived);
        const result = await Promise.race([this.abortDeferred.promise, downloadPromise]);
        return result;
    }
    abort() {
        this.aborted = true;
        this.abortDeferred.resolve('aborted');
    }
}
exports.FileDownloader = FileDownloader;
