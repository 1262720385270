"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptItemsKeyByPromptingUser = exports.DecryptItemsKeyWithUserFallback = void 0;
const models_1 = require("@standardnotes/models");
const encryption_1 = require("@standardnotes/encryption");
const ChallengePrompt_1 = require("../Challenge/Prompt/ChallengePrompt");
const ChallengeReason_1 = require("../Challenge/Types/ChallengeReason");
const ChallengeValidation_1 = require("../Challenge/Types/ChallengeValidation");
async function DecryptItemsKeyWithUserFallback(itemsKey, encryptor, challengor) {
    const decryptionResult = await encryptor.decryptSplitSingle({
        usesRootKeyWithKeyLookup: {
            items: [itemsKey],
        },
    });
    if ((0, models_1.isDecryptedPayload)(decryptionResult)) {
        return decryptionResult;
    }
    const secondDecryptionResult = await DecryptItemsKeyByPromptingUser(itemsKey, encryptor, challengor);
    if (secondDecryptionResult === 'aborted' || secondDecryptionResult === 'failed') {
        return secondDecryptionResult;
    }
    return secondDecryptionResult.decryptedKey;
}
exports.DecryptItemsKeyWithUserFallback = DecryptItemsKeyWithUserFallback;
async function DecryptItemsKeyByPromptingUser(itemsKey, encryptor, challengor, keyParams) {
    if (!keyParams) {
        keyParams = encryptor.getKeyEmbeddedKeyParams(itemsKey);
    }
    if (!keyParams) {
        return 'failed';
    }
    const challenge = challengor.createChallenge([new ChallengePrompt_1.ChallengePrompt(ChallengeValidation_1.ChallengeValidation.None, undefined, undefined, true)], ChallengeReason_1.ChallengeReason.Custom, true, encryption_1.KeyRecoveryStrings.KeyRecoveryLoginFlowPrompt(keyParams), encryption_1.KeyRecoveryStrings.KeyRecoveryPasswordRequired);
    const response = await challengor.promptForChallengeResponse(challenge);
    if (!response) {
        return 'aborted';
    }
    const password = response.values[0].value;
    const rootKey = await encryptor.computeRootKey(password, keyParams);
    const secondDecryptionResult = await encryptor.decryptSplitSingle({
        usesRootKey: {
            items: [itemsKey],
            key: rootKey,
        },
    });
    challengor.completeChallenge(challenge);
    if ((0, models_1.isDecryptedPayload)(secondDecryptionResult)) {
        return { decryptedKey: secondDecryptionResult, rootKey };
    }
    return 'failed';
}
exports.DecryptItemsKeyByPromptingUser = DecryptItemsKeyByPromptingUser;
