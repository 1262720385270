"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyboardModifier = void 0;
var KeyboardModifier;
(function (KeyboardModifier) {
    KeyboardModifier["Shift"] = "Shift";
    KeyboardModifier["Ctrl"] = "Control";
    /** ⌘ key on Mac, ⊞ key on Windows */
    KeyboardModifier["Meta"] = "Meta";
    KeyboardModifier["Alt"] = "Alt";
})(KeyboardModifier = exports.KeyboardModifier || (exports.KeyboardModifier = {}));
