"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HttpVerb = void 0;
var HttpVerb;
(function (HttpVerb) {
    HttpVerb["Get"] = "GET";
    HttpVerb["Post"] = "POST";
    HttpVerb["Put"] = "PUT";
    HttpVerb["Patch"] = "PATCH";
    HttpVerb["Delete"] = "DELETE";
})(HttpVerb = exports.HttpVerb || (exports.HttpVerb = {}));
