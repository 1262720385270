"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindNativeFeature = exports.GetFeatures = void 0;
const ServerFeatures_1 = require("../Lists/ServerFeatures");
const ClientFeatures_1 = require("../Lists/ClientFeatures");
const DeprecatedFeatures_1 = require("../Lists/DeprecatedFeatures");
const ExperimentalFeatures_1 = require("../Lists/ExperimentalFeatures");
function GetFeatures() {
    return [...(0, ServerFeatures_1.serverFeatures)(), ...(0, ClientFeatures_1.clientFeatures)(), ...(0, ExperimentalFeatures_1.experimentalFeatures)(), ...(0, DeprecatedFeatures_1.GetDeprecatedFeatures)()];
}
exports.GetFeatures = GetFeatures;
function FindNativeFeature(identifier) {
    return GetFeatures().find((f) => f.identifier === identifier);
}
exports.FindNativeFeature = FindNativeFeature;
