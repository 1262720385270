"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaRemoteRetrieved = void 0;
const Conflict_1 = require("./Conflict");
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
const Payload_1 = require("../../Abstract/Payload");
const common_1 = require("@standardnotes/common");
const ApplyDirtyState_1 = require("./Utilities/ApplyDirtyState");
const ItemsKeyDelta_1 = require("./ItemsKeyDelta");
const DeltaEmit_1 = require("./Abstract/DeltaEmit");
class DeltaRemoteRetrieved {
    constructor(baseCollection, applyCollection, itemsSavedOrSaving, historyMap) {
        this.baseCollection = baseCollection;
        this.applyCollection = applyCollection;
        this.itemsSavedOrSaving = itemsSavedOrSaving;
        this.historyMap = historyMap;
    }
    isUuidOfPayloadCurrentlySavingOrSaved(uuid) {
        return this.itemsSavedOrSaving.find((i) => i.uuid === uuid) != undefined;
    }
    result() {
        const result = {
            emits: [],
            ignored: [],
            source: Payload_1.PayloadEmitSource.RemoteRetrieved,
        };
        const conflicted = [];
        /**
         * If we have retrieved an item that was saved as part of this ongoing sync operation,
         * or if the item is locally dirty, filter it out of retrieved_items, and add to potential conflicts.
         */
        for (const apply of this.applyCollection.all()) {
            if (apply.content_type === common_1.ContentType.ItemsKey) {
                const itemsKeyDeltaEmit = new ItemsKeyDelta_1.ItemsKeyDelta(this.baseCollection, [apply]).result();
                (0, DeltaEmit_1.extendSyncDelta)(result, itemsKeyDeltaEmit);
                continue;
            }
            const isSavedOrSaving = this.isUuidOfPayloadCurrentlySavingOrSaved(apply.uuid);
            if (isSavedOrSaving) {
                conflicted.push(apply);
                continue;
            }
            const base = this.baseCollection.find(apply.uuid);
            if ((base === null || base === void 0 ? void 0 : base.dirty) && !(0, TypeCheck_1.isErrorDecryptingPayload)(base)) {
                conflicted.push(apply);
                continue;
            }
            result.emits.push((0, ApplyDirtyState_1.payloadByFinalizingSyncState)(apply, this.baseCollection));
        }
        /**
         * For any potential conflict above, we compare the values with current
         * local values, and if they differ, we create a new payload that is a copy
         * of the server payload.
         */
        for (const conflict of conflicted) {
            if (!(0, TypeCheck_1.isDecryptedPayload)(conflict)) {
                continue;
            }
            const base = this.baseCollection.find(conflict.uuid);
            if (!base) {
                continue;
            }
            const delta = new Conflict_1.ConflictDelta(this.baseCollection, base, conflict, this.historyMap);
            (0, DeltaEmit_1.extendSyncDelta)(result, delta.result());
        }
        return result;
    }
}
exports.DeltaRemoteRetrieved = DeltaRemoteRetrieved;
