"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutolockService = void 0;
const services_1 = require("@standardnotes/services");
const MILLISECONDS_PER_SECOND = 1000;
const POLL_INTERVAL = 50;
const LockInterval = {
    None: 0,
    Immediate: 1,
    OneMinute: 60 * MILLISECONDS_PER_SECOND,
    FiveMinutes: 300 * MILLISECONDS_PER_SECOND,
    OneHour: 3600 * MILLISECONDS_PER_SECOND,
};
const STORAGE_KEY_AUTOLOCK_INTERVAL = 'AutoLockIntervalKey';
class AutolockService extends services_1.AbstractService {
    constructor(application, internalEventBus) {
        super(internalEventBus);
        this.application = application;
        this.internalEventBus = internalEventBus;
        this.addAppEventObserverAfterSubclassesFinishConstructing();
    }
    onAppLaunch() {
        this.beginPolling();
    }
    deinit() {
        this.cancelAutoLockTimer();
        if (this.pollInterval) {
            clearInterval(this.pollInterval);
        }
        ;
        this.application = undefined;
        this.unsubApp();
        this.unsubApp = undefined;
        super.deinit();
    }
    addAppEventObserverAfterSubclassesFinishConstructing() {
        setTimeout(() => {
            this.addAppEventObserver();
        }, 0);
    }
    addAppEventObserver() {
        if (this.application.isLaunched()) {
            void this.onAppLaunch();
        }
        this.unsubApp = this.application.addEventObserver(async (event) => {
            if (event === services_1.ApplicationEvent.Launched) {
                void this.onAppLaunch();
            }
        });
    }
    lockApplication() {
        if (!this.application.hasPasscode()) {
            throw Error('Attempting to lock application with no passcode');
        }
        this.application.lock().catch(console.error);
    }
    async setAutoLockInterval(interval) {
        return this.application.setValue(STORAGE_KEY_AUTOLOCK_INTERVAL, interval);
    }
    async getAutoLockInterval() {
        const interval = (await this.application.getValue(STORAGE_KEY_AUTOLOCK_INTERVAL));
        if (interval) {
            return interval;
        }
        else {
            return LockInterval.None;
        }
    }
    async deleteAutolockPreference() {
        await this.application.removeValue(STORAGE_KEY_AUTOLOCK_INTERVAL);
        this.cancelAutoLockTimer();
    }
    /**
     *  Verify document is in focus every so often as visibilitychange event is
     *  not triggered on a typical window blur event but rather on tab changes.
     */
    beginPolling() {
        this.pollInterval = setInterval(async () => {
            const locked = await this.application.isLocked();
            if (!locked && this.lockAfterDate && new Date() > this.lockAfterDate) {
                this.lockApplication();
            }
            const hasFocus = document.hasFocus();
            if (hasFocus && this.lastFocusState === 'hidden') {
                this.documentVisibilityChanged(true).catch(console.error);
            }
            else if (!hasFocus && this.lastFocusState === 'visible') {
                this.documentVisibilityChanged(false).catch(console.error);
            }
            /* Save this to compare against next time around */
            this.lastFocusState = hasFocus ? 'visible' : 'hidden';
        }, POLL_INTERVAL);
    }
    getAutoLockIntervalOptions() {
        return [
            {
                value: LockInterval.None,
                label: 'Off',
            },
            {
                value: LockInterval.Immediate,
                label: 'Immediately',
            },
            {
                value: LockInterval.OneMinute,
                label: '1m',
            },
            {
                value: LockInterval.FiveMinutes,
                label: '5m',
            },
            {
                value: LockInterval.OneHour,
                label: '1h',
            },
        ];
    }
    async documentVisibilityChanged(visible) {
        if (visible) {
            this.cancelAutoLockTimer();
        }
        else {
            this.beginAutoLockTimer().catch(console.error);
        }
    }
    async beginAutoLockTimer() {
        const interval = await this.getAutoLockInterval();
        if (interval === LockInterval.None) {
            return;
        }
        const addToNow = (seconds) => {
            const date = new Date();
            date.setSeconds(date.getSeconds() + seconds);
            return date;
        };
        this.lockAfterDate = addToNow(interval / MILLISECONDS_PER_SECOND);
    }
    cancelAutoLockTimer() {
        this.lockAfterDate = undefined;
    }
}
exports.AutolockService = AutolockService;
