"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModalAnimation = exports.IosModalAnimationEasing = void 0;
const useLifecycleAnimation_1 = require("@/Hooks/useLifecycleAnimation");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
exports.IosModalAnimationEasing = 'cubic-bezier(.36,.66,.04,1)';
const Animations = {
    vertical: {
        enter: {
            keyframes: [
                {
                    transform: 'translateY(100%)',
                },
                {
                    transform: 'translateY(0)',
                },
            ],
            transformOrigin: 'bottom',
        },
        exit: {
            keyframes: [
                {
                    transform: 'translateY(0)',
                },
                {
                    transform: 'translateY(100%)',
                },
            ],
            transformOrigin: 'bottom',
        },
    },
    horizontal: {
        enter: {
            keyframes: [
                {
                    transform: 'translateX(100%)',
                },
                {
                    transform: 'translateX(0)',
                },
            ],
            transformOrigin: 'right',
        },
        exit: {
            keyframes: [
                {
                    transform: 'translateX(0)',
                },
                {
                    transform: 'translateX(100%)',
                },
            ],
            transformOrigin: 'right',
        },
    },
};
const useModalAnimation = (isOpen, variant = 'vertical') => {
    const isMobileScreen = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm);
    return (0, useLifecycleAnimation_1.useLifecycleAnimation)({
        open: isOpen,
        enter: {
            keyframes: Animations[variant].enter.keyframes,
            options: {
                easing: exports.IosModalAnimationEasing,
                duration: 250,
                fill: 'forwards',
            },
            initialStyle: {
                transformOrigin: Animations[variant].enter.transformOrigin,
            },
        },
        enterCallback: (element) => {
            element.scrollTop = 0;
        },
        exit: {
            keyframes: Animations[variant].exit.keyframes,
            options: {
                easing: exports.IosModalAnimationEasing,
                duration: 250,
                fill: 'forwards',
            },
            initialStyle: {
                transformOrigin: Animations[variant].exit.transformOrigin,
            },
        },
    }, !isMobileScreen);
};
exports.useModalAnimation = useModalAnimation;
