"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBase64FromBlob = exports.isTabletOrMobileScreen = exports.isTabletScreen = exports.isMobileScreen = exports.disableIosTextFieldZoom = exports.isAndroid = exports.isIOS = exports.convertStringifiedBooleanToBoolean = exports.openInNewTab = exports.isEmailValid = exports.getDesktopVersion = exports.isDesktopApplication = exports.destroyAllObjectProperties = exports.preventRefreshing = exports.debounce = exports.isSameDay = exports.getPlatform = exports.getPlatformString = exports.isDev = void 0;
const snjs_1 = require("@standardnotes/snjs");
const Version_1 = require("@/Constants/Version");
const Constants_1 = require("../Constants/Constants");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
exports.isDev = process.env.NODE_ENV === 'development';
function getPlatformString() {
    try {
        const platform = navigator.platform.toLowerCase();
        let trimmed = '';
        if (platform.includes('mac')) {
            trimmed = 'mac';
        }
        else if (platform.includes('win')) {
            trimmed = 'windows';
        }
        else if (platform.includes('linux')) {
            trimmed = 'linux';
        }
        else {
            /** Treat other platforms as linux */
            trimmed = 'linux';
        }
        return trimmed + (isDesktopApplication() ? '-desktop' : '-web');
    }
    catch (e) {
        return 'linux-web';
    }
}
exports.getPlatformString = getPlatformString;
function getPlatform(device) {
    if ('platform' in device) {
        return device.platform;
    }
    return (0, snjs_1.platformFromString)(getPlatformString());
}
exports.getPlatform = getPlatform;
function isSameDay(dateA, dateB) {
    return (dateA.getFullYear() === dateB.getFullYear() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getDate() === dateB.getDate());
}
exports.isSameDay = isSameDay;
/** Via https://davidwalsh.name/javascript-debounce-function */
function debounce(func, wait, immediate = false) {
    let timeout;
    return () => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias, no-invalid-this
        const context = this;
        // eslint-disable-next-line prefer-rest-params
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        const callNow = immediate && !timeout;
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}
exports.debounce = debounce;
// https://tc39.github.io/ecma262/#sec-array.prototype.includes
if (!Array.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, 'includes', {
        value: function (searchElement, fromIndex) {
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }
            // 1. Let O be ? ToObject(this value).
            const o = Object(this);
            // 2. Let len be ? ToLength(? Get(O, "length")).
            const len = o.length >>> 0;
            // 3. If len is 0, return false.
            if (len === 0) {
                return false;
            }
            // 4. Let n be ? ToInteger(fromIndex).
            //    (If fromIndex is undefined, this step produces the value 0.)
            const n = fromIndex | 0;
            // 5. If n ≥ 0, then
            //  a. Let k be n.
            // 6. Else n < 0,
            //  a. Let k be len + n.
            //  b. If k < 0, let k be 0.
            let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);
            function sameValueZero(x, y) {
                return x === y || (typeof x === 'number' && typeof y === 'number' && isNaN(x) && isNaN(y));
            }
            // 7. Repeat, while k < len
            while (k < len) {
                // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                // b. If SameValueZero(searchElement, elementK) is true, return true.
                if (sameValueZero(o[k], searchElement)) {
                    return true;
                }
                // c. Increase k by 1.
                k++;
            }
            // 8. Return false
            return false;
        },
    });
}
async function preventRefreshing(message, operation) {
    const onBeforeUnload = window.onbeforeunload;
    try {
        window.onbeforeunload = () => message;
        await operation();
    }
    finally {
        window.onbeforeunload = onBeforeUnload;
    }
}
exports.preventRefreshing = preventRefreshing;
if (!Version_1.IsWebPlatform && !Version_1.IsDesktopPlatform) {
    throw Error('Neither __WEB__ nor __DESKTOP__ is true. Check your configuration files.');
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function destroyAllObjectProperties(object) {
    for (const prop of Object.getOwnPropertyNames(object)) {
        try {
            delete object[prop];
            // eslint-disable-next-line no-empty
        }
        catch (error) { }
    }
}
exports.destroyAllObjectProperties = destroyAllObjectProperties;
function isDesktopApplication() {
    return Version_1.IsDesktopPlatform;
}
exports.isDesktopApplication = isDesktopApplication;
function getDesktopVersion() {
    return window.electronAppVersion;
}
exports.getDesktopVersion = getDesktopVersion;
const isEmailValid = (email) => {
    return Constants_1.EMAIL_REGEX.test(email);
};
exports.isEmailValid = isEmailValid;
const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) {
        newWindow.opener = null;
    }
};
exports.openInNewTab = openInNewTab;
const convertStringifiedBooleanToBoolean = (value) => {
    return value !== 'false';
};
exports.convertStringifiedBooleanToBoolean = convertStringifiedBooleanToBoolean;
// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
const isIOS = () => (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document && navigator.maxTouchPoints > 1) ||
    window.platform === snjs_1.Platform.Ios;
exports.isIOS = isIOS;
const isAndroid = () => navigator.userAgent.toLowerCase().includes('android');
exports.isAndroid = isAndroid;
// https://stackoverflow.com/a/57527009/2504429
const disableIosTextFieldZoom = () => {
    const addMaximumScaleToMetaViewport = () => {
        const viewportMetaElement = document.querySelector('meta[name=viewport]');
        if (viewportMetaElement !== null) {
            let content = viewportMetaElement.getAttribute('content');
            if (!content) {
                return;
            }
            const maximumScaleRegex = /maximum-scale=[0-9.]+/g;
            if (maximumScaleRegex.test(content)) {
                content = content.replace(maximumScaleRegex, 'maximum-scale=1.0');
            }
            else {
                content = [content, 'maximum-scale=1.0'].join(', ');
            }
            viewportMetaElement.setAttribute('content', content);
        }
    };
    if ((0, exports.isIOS)()) {
        addMaximumScaleToMetaViewport();
    }
};
exports.disableIosTextFieldZoom = disableIosTextFieldZoom;
const isMobileScreen = () => window.matchMedia(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm).matches;
exports.isMobileScreen = isMobileScreen;
const isTabletScreen = () => window.matchMedia(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.md).matches;
exports.isTabletScreen = isTabletScreen;
const isTabletOrMobileScreen = () => (0, exports.isMobileScreen)() || (0, exports.isTabletScreen)();
exports.isTabletOrMobileScreen = isTabletOrMobileScreen;
const getBase64FromBlob = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                resolve(reader.result.toString());
            }
            else {
                reject();
            }
        };
        reader.readAsDataURL(blob);
    });
};
exports.getBase64FromBlob = getBase64FromBlob;
