"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.log = exports.LoggingDomain = exports.isDev = void 0;
const utils_1 = require("@standardnotes/utils");
exports.isDev = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';
var LoggingDomain;
(function (LoggingDomain) {
    LoggingDomain[LoggingDomain["FilesService"] = 0] = "FilesService";
    LoggingDomain[LoggingDomain["FilesBackups"] = 1] = "FilesBackups";
})(LoggingDomain = exports.LoggingDomain || (exports.LoggingDomain = {}));
const LoggingStatus = {
    [LoggingDomain.FilesService]: false,
    [LoggingDomain.FilesBackups]: false,
};
const LoggingColor = {
    [LoggingDomain.FilesService]: 'blue',
    [LoggingDomain.FilesBackups]: 'yellow',
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function log(domain, ...args) {
    if (!exports.isDev || !LoggingStatus[domain]) {
        return;
    }
    (0, utils_1.logWithColor)(LoggingDomain[domain], LoggingColor[domain], ...args);
}
exports.log = log;
