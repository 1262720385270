"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const utils_1 = require("@standardnotes/utils");
const react_1 = require("react");
const getClassNames = (hasLeftDecorations, hasRightDecorations, roundedFull) => {
    return {
        container: `position-relative flex items-stretch overflow-hidden border border-solid border-passive-3 bg-default text-sm focus-within:ring-2 focus-within:ring-info bg-clip-padding ${!hasLeftDecorations && !hasRightDecorations ? 'px-2 py-1.5' : ''} ${roundedFull ? 'rounded-full' : 'rounded'}`,
        input: `focus:ring-none w-full border-0 bg-transparent text-text focus:shadow-none focus:outline-none ${!hasLeftDecorations && hasRightDecorations ? 'pl-2' : ''} ${hasRightDecorations ? 'pr-2' : ''}`,
        disabled: 'bg-passive-5 cursor-not-allowed',
    };
};
/**
 * Input that can be decorated on the left and right side
 */
const DecoratedInput = (0, react_1.forwardRef)(({ autocomplete = false, spellcheck = true, className, disabled = false, id, left, onBlur, onChange, onFocus, onKeyDown, onKeyUp, onEnter, placeholder = '', right, type = 'text', title, value, defaultValue, roundedFull, autofocus = false, }, ref) => {
    const hasLeftDecorations = Boolean(left === null || left === void 0 ? void 0 : left.length);
    const hasRightDecorations = Boolean(right === null || right === void 0 ? void 0 : right.length);
    const computedClassNames = getClassNames(hasLeftDecorations, hasRightDecorations, roundedFull);
    const handleKeyUp = (0, react_1.useCallback)((e) => {
        if (e.key === 'Enter') {
            onEnter === null || onEnter === void 0 ? void 0 : onEnter();
        }
        onKeyUp === null || onKeyUp === void 0 ? void 0 : onKeyUp(e);
    }, [onKeyUp, onEnter]);
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, utils_1.classNames)(computedClassNames.container, disabled ? computedClassNames.disabled : '', className === null || className === void 0 ? void 0 : className.container), children: [left && ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.classNames)('flex items-center px-2 py-1.5', className === null || className === void 0 ? void 0 : className.left), children: left.map((leftChild, index) => ((0, jsx_runtime_1.jsx)(react_1.Fragment, { children: leftChild }, index))) })), (0, jsx_runtime_1.jsx)("input", { autoComplete: autocomplete ? 'on' : 'off', autoFocus: autofocus, className: `${computedClassNames.input} ${disabled ? computedClassNames.disabled : ''} ${className === null || className === void 0 ? void 0 : className.input}`, "data-lpignore": type !== 'password' ? true : false, disabled: disabled, id: id, onBlur: onBlur, onChange: (e) => onChange && onChange(e.target.value), onFocus: onFocus, onKeyDown: onKeyDown, onKeyUp: handleKeyUp, placeholder: placeholder, ref: ref, title: title, type: type, value: value, defaultValue: defaultValue, spellCheck: spellcheck }), right && ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.classNames)('flex items-center px-2 py-1.5', className === null || className === void 0 ? void 0 : className.right), children: right.map((rightChild, index) => ((0, jsx_runtime_1.jsx)("div", { className: index > 0 ? 'ml-3' : '', children: rightChild }, index))) }))] }));
});
exports.default = DecoratedInput;
