"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorFontSize = exports.EditorLineWidth = exports.EditorLineHeight = exports.NewNoteTitleFormat = exports.PrefKey = void 0;
var PrefKey;
(function (PrefKey) {
    PrefKey["TagsPanelWidth"] = "tagsPanelWidth";
    PrefKey["NotesPanelWidth"] = "notesPanelWidth";
    PrefKey["EditorWidth"] = "editorWidth";
    PrefKey["EditorLeft"] = "editorLeft";
    PrefKey["EditorMonospaceEnabled"] = "monospaceFont";
    PrefKey["EditorSpellcheck"] = "spellcheck";
    PrefKey["EditorResizersEnabled"] = "marginResizersEnabled";
    PrefKey["EditorLineHeight"] = "editorLineHeight";
    PrefKey["EditorLineWidth"] = "editorLineWidth";
    PrefKey["EditorFontSize"] = "editorFontSize";
    PrefKey["SortNotesBy"] = "sortBy";
    PrefKey["SortNotesReverse"] = "sortReverse";
    PrefKey["NotesShowArchived"] = "showArchived";
    PrefKey["NotesShowTrashed"] = "showTrashed";
    PrefKey["NotesHideProtected"] = "hideProtected";
    PrefKey["NotesHidePinned"] = "hidePinned";
    PrefKey["NotesHideNotePreview"] = "hideNotePreview";
    PrefKey["NotesHideDate"] = "hideDate";
    PrefKey["NotesHideTags"] = "hideTags";
    PrefKey["NotesHideEditorIcon"] = "hideEditorIcon";
    PrefKey["UseSystemColorScheme"] = "useSystemColorScheme";
    PrefKey["AutoLightThemeIdentifier"] = "autoLightThemeIdentifier";
    PrefKey["AutoDarkThemeIdentifier"] = "autoDarkThemeIdentifier";
    PrefKey["NoteAddToParentFolders"] = "noteAddToParentFolders";
    PrefKey["NewNoteTitleFormat"] = "newNoteTitleFormat";
    PrefKey["CustomNoteTitleFormat"] = "customNoteTitleFormat";
    PrefKey["UpdateSavingStatusIndicator"] = "updateSavingStatusIndicator";
    PrefKey["DefaultEditorIdentifier"] = "defaultEditorIdentifier";
    PrefKey["MomentsDefaultTagUuid"] = "momentsDefaultTagUuid";
    PrefKey["ClipperDefaultTagUuid"] = "clipperDefaultTagUuid";
    PrefKey["SystemViewPreferences"] = "systemViewPreferences";
    PrefKey["SuperNoteExportFormat"] = "superNoteExportFormat";
    PrefKey["AuthenticatorNames"] = "authenticatorNames";
    PrefKey["PaneGesturesEnabled"] = "paneGesturesEnabled";
})(PrefKey = exports.PrefKey || (exports.PrefKey = {}));
var NewNoteTitleFormat;
(function (NewNoteTitleFormat) {
    NewNoteTitleFormat["CurrentDateAndTime"] = "CurrentDateAndTime";
    NewNoteTitleFormat["CurrentNoteCount"] = "CurrentNoteCount";
    NewNoteTitleFormat["CustomFormat"] = "CustomFormat";
    NewNoteTitleFormat["Empty"] = "Empty";
})(NewNoteTitleFormat = exports.NewNoteTitleFormat || (exports.NewNoteTitleFormat = {}));
var EditorLineHeight;
(function (EditorLineHeight) {
    EditorLineHeight["None"] = "None";
    EditorLineHeight["Tight"] = "Tight";
    EditorLineHeight["Snug"] = "Snug";
    EditorLineHeight["Normal"] = "Normal";
    EditorLineHeight["Relaxed"] = "Relaxed";
    EditorLineHeight["Loose"] = "Loose";
})(EditorLineHeight = exports.EditorLineHeight || (exports.EditorLineHeight = {}));
var EditorLineWidth;
(function (EditorLineWidth) {
    EditorLineWidth["Narrow"] = "Narrow";
    EditorLineWidth["Wide"] = "Wide";
    EditorLineWidth["Dynamic"] = "Dynamic";
    EditorLineWidth["FullWidth"] = "FullWidth";
})(EditorLineWidth = exports.EditorLineWidth || (exports.EditorLineWidth = {}));
var EditorFontSize;
(function (EditorFontSize) {
    EditorFontSize["ExtraSmall"] = "ExtraSmall";
    EditorFontSize["Small"] = "Small";
    EditorFontSize["Normal"] = "Normal";
    EditorFontSize["Medium"] = "Medium";
    EditorFontSize["Large"] = "Large";
})(EditorFontSize = exports.EditorFontSize || (exports.EditorFontSize = {}));
