"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AegisToAuthenticatorConverter = void 0;
const common_1 = require("@standardnotes/common");
const Utils_1 = require("../Utils");
const features_1 = require("@standardnotes/features");
const AegisEntryTypes = ['hotp', 'totp', 'steam', 'yandex'];
class AegisToAuthenticatorConverter {
    constructor(application) {
        this.application = application;
    }
    static isValidAegisJson(json) {
        return json.db && json.db.entries && json.db.entries.every((entry) => AegisEntryTypes.includes(entry.type));
    }
    async convertAegisBackupFileToNote(file, addEditorInfo) {
        const content = await (0, Utils_1.readFileAsText)(file);
        const entries = this.parseEntries(content);
        if (!entries) {
            throw new Error('Could not parse entries');
        }
        return this.createNoteFromEntries(entries, file, addEditorInfo);
    }
    createNoteFromEntries(entries, file, addEditorInfo) {
        return {
            created_at: new Date(file.lastModified),
            created_at_timestamp: file.lastModified,
            updated_at: new Date(file.lastModified),
            updated_at_timestamp: file.lastModified,
            uuid: this.application.generateUUID(),
            content_type: common_1.ContentType.Note,
            content: {
                title: file.name.split('.')[0],
                text: JSON.stringify(entries),
                references: [],
                ...(addEditorInfo && {
                    noteType: features_1.NoteType.Authentication,
                    editorIdentifier: features_1.FeatureIdentifier.TokenVaultEditor,
                }),
            },
        };
    }
    parseEntries(data) {
        try {
            const json = JSON.parse(data);
            const entries = json.db.entries.map((entry) => {
                return {
                    service: entry.issuer,
                    account: entry.name,
                    secret: entry.info.secret,
                    notes: entry.note,
                };
            });
            return entries;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
}
exports.AegisToAuthenticatorConverter = AegisToAuthenticatorConverter;
