"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesMenu = void 0;
const mobx_1 = require("mobx");
const PackageProvider_1 = require("./Panes/General/Advanced/Packages/Provider/PackageProvider");
const securityPrefsHasBubble_1 = require("./Panes/Security/securityPrefsHasBubble");
/**
 * Items are in order of appearance
 */
const PREFERENCES_MENU_ITEMS = [
    { id: 'whats-new', label: "What's New", icon: 'asterisk' },
    { id: 'account', label: 'Account', icon: 'user' },
    { id: 'general', label: 'General', icon: 'settings' },
    { id: 'security', label: 'Security', icon: 'security' },
    { id: 'backups', label: 'Backups', icon: 'restore' },
    { id: 'appearance', label: 'Appearance', icon: 'themes' },
    { id: 'listed', label: 'Listed', icon: 'listed' },
    { id: 'shortcuts', label: 'Shortcuts', icon: 'keyboard' },
    { id: 'accessibility', label: 'Accessibility', icon: 'accessibility' },
    { id: 'get-free-month', label: 'Get a free month', icon: 'star' },
    { id: 'help-feedback', label: 'Help & feedback', icon: 'help' },
];
const READY_PREFERENCES_MENU_ITEMS = [
    { id: 'whats-new', label: "What's New", icon: 'asterisk' },
    { id: 'account', label: 'Account', icon: 'user' },
    { id: 'general', label: 'General', icon: 'settings' },
    { id: 'security', label: 'Security', icon: 'security' },
    { id: 'backups', label: 'Backups', icon: 'restore' },
    { id: 'appearance', label: 'Appearance', icon: 'themes' },
    { id: 'listed', label: 'Listed', icon: 'listed' },
    { id: 'help-feedback', label: 'Help & feedback', icon: 'help' },
];
class PreferencesMenu {
    constructor(application, _enableUnfinishedFeatures) {
        this.application = application;
        this._enableUnfinishedFeatures = _enableUnfinishedFeatures;
        this._selectedPane = 'account';
        this._extensionLatestVersions = new PackageProvider_1.PackageProvider(new Map());
        this.selectPane = (key) => {
            this._selectedPane = key;
        };
        this._menu = this._enableUnfinishedFeatures ? PREFERENCES_MENU_ITEMS : READY_PREFERENCES_MENU_ITEMS;
        this.loadLatestVersions();
        (0, mobx_1.makeAutoObservable)(this, {
            _twoFactorAuth: mobx_1.observable,
            _selectedPane: mobx_1.observable,
            _extensionPanes: mobx_1.observable.ref,
            _extensionLatestVersions: mobx_1.observable.ref,
            loadLatestVersions: mobx_1.action,
        });
    }
    loadLatestVersions() {
        PackageProvider_1.PackageProvider.load(this.application)
            .then((versions) => {
            if (versions) {
                this._extensionLatestVersions = versions;
            }
        })
            .catch(console.error);
    }
    get extensionsLatestVersions() {
        return this._extensionLatestVersions;
    }
    get menuItems() {
        const menuItems = this._menu.map((preference) => {
            const item = {
                ...preference,
                selected: preference.id === this._selectedPane,
                hasBubble: this.sectionHasBubble(preference.id),
            };
            return item;
        });
        return menuItems;
    }
    get selectedMenuItem() {
        return this._menu.find((item) => item.id === this._selectedPane);
    }
    get selectedPaneId() {
        if (this.selectedMenuItem != undefined) {
            return this.selectedMenuItem.id;
        }
        return 'account';
    }
    sectionHasBubble(id) {
        if (id === 'security') {
            return (0, securityPrefsHasBubble_1.securityPrefsHasBubble)(this.application);
        }
        return false;
    }
}
exports.PreferencesMenu = PreferencesMenu;
