"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = require("react");
const UpgradeNow = ({ application, featuresController, subscriptionContoller }) => {
    const shouldShowCTA = !featuresController.hasFolders;
    const hasAccount = subscriptionContoller.hasAccount;
    const hasAccessToFeatures = subscriptionContoller.hasFirstPartySubscription;
    const onClick = (0, react_1.useCallback)(() => {
        if (hasAccount && application.isNativeIOS()) {
            application.showPremiumModal();
        }
        else {
            void application.openPurchaseFlow();
        }
    }, [application, hasAccount]);
    if (!shouldShowCTA || hasAccessToFeatures) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex h-full items-center px-2", children: (0, jsx_runtime_1.jsx)("button", { className: "rounded bg-info py-0.5 px-1.5 text-sm font-bold uppercase text-info-contrast hover:brightness-125 lg:text-xs", onClick: onClick, children: !hasAccount ? 'Sign up to sync' : 'Unlock features' }) }));
};
exports.default = (0, mobx_react_lite_1.observer)(UpgradeNow);
