"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIconForItem = void 0;
const snjs_1 = require("@standardnotes/snjs");
const getIconAndTintForNoteType_1 = require("./getIconAndTintForNoteType");
const getIconForFileType_1 = require("./getIconForFileType");
function getIconForItem(item, application) {
    if (item instanceof snjs_1.SNNote) {
        const editorForNote = application.componentManager.editorForNote(item);
        const [icon, tint] = (0, getIconAndTintForNoteType_1.getIconAndTintForNoteType)(editorForNote === null || editorForNote === void 0 ? void 0 : editorForNote.package_info.note_type);
        const className = `text-accessory-tint-${tint}`;
        return [icon, className];
    }
    else if (item instanceof snjs_1.FileItem) {
        const icon = (0, getIconForFileType_1.getIconForFileType)(item.mimeType);
        return [icon, 'text-info'];
    }
    else if (item instanceof snjs_1.SNTag) {
        return [item.iconString, 'text-info'];
    }
    throw new Error('Unhandled case in getItemIcon');
}
exports.getIconForItem = getIconForItem;
