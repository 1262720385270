"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConflictStrategy = void 0;
var ConflictStrategy;
(function (ConflictStrategy) {
    ConflictStrategy[ConflictStrategy["KeepBase"] = 1] = "KeepBase";
    ConflictStrategy[ConflictStrategy["KeepApply"] = 2] = "KeepApply";
    ConflictStrategy[ConflictStrategy["KeepBaseDuplicateApply"] = 3] = "KeepBaseDuplicateApply";
    ConflictStrategy[ConflictStrategy["DuplicateBaseKeepApply"] = 4] = "DuplicateBaseKeepApply";
    ConflictStrategy[ConflictStrategy["KeepBaseMergeRefs"] = 5] = "KeepBaseMergeRefs";
})(ConflictStrategy = exports.ConflictStrategy || (exports.ConflictStrategy = {}));
