"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAndroidBackHandler = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Defaults_1 = require("@/Components/Preferences/Panes/General/Defaults");
const mobx_react_lite_1 = require("mobx-react-lite");
const react_1 = require("react");
const BackHandlerContext = (0, react_1.createContext)(null);
const useAndroidBackHandler = () => {
    const value = (0, react_1.useContext)(BackHandlerContext);
    if (!value) {
        throw new Error('Component must be a child of <AndroidBackHandlerProvider />');
    }
    return value;
};
exports.useAndroidBackHandler = useAndroidBackHandler;
const MemoizedChildren = (0, react_1.memo)(({ children }) => (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children }));
const AndroidBackHandlerProvider = ({ application, children }) => {
    const addAndroidBackHandler = (0, react_1.useCallback)((listener) => application.addAndroidBackHandlerEventListener(listener), [application]);
    (0, react_1.useEffect)(() => {
        application.setAndroidBackHandlerFallbackListener(() => {
            var _a;
            const shouldConfirm = (_a = application.getValue(Defaults_1.AndroidConfirmBeforeExitKey)) !== null && _a !== void 0 ? _a : true;
            application.mobileDevice().exitApp(shouldConfirm);
            return true;
        });
    }, [application]);
    return ((0, jsx_runtime_1.jsx)(BackHandlerContext.Provider, { value: addAndroidBackHandler, children: (0, jsx_runtime_1.jsx)(MemoizedChildren, { children: children }) }));
};
exports.default = (0, mobx_react_lite_1.observer)(AndroidBackHandlerProvider);
