"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeValidation = void 0;
var ChallengeValidation;
(function (ChallengeValidation) {
    ChallengeValidation[ChallengeValidation["None"] = 0] = "None";
    ChallengeValidation[ChallengeValidation["LocalPasscode"] = 1] = "LocalPasscode";
    ChallengeValidation[ChallengeValidation["AccountPassword"] = 2] = "AccountPassword";
    ChallengeValidation[ChallengeValidation["Biometric"] = 3] = "Biometric";
    ChallengeValidation[ChallengeValidation["ProtectionSessionDuration"] = 4] = "ProtectionSessionDuration";
    ChallengeValidation[ChallengeValidation["Authenticator"] = 5] = "Authenticator";
})(ChallengeValidation = exports.ChallengeValidation || (exports.ChallengeValidation = {}));
