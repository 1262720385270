"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileUploader = void 0;
class FileUploader {
    constructor(apiService) {
        this.apiService = apiService;
    }
    async uploadBytes(encryptedBytes, chunkId, apiToken) {
        const result = await this.apiService.uploadFileBytes(apiToken, chunkId, encryptedBytes);
        return result;
    }
}
exports.FileUploader = FileUploader;
