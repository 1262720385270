"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNProtocolOperator001 = void 0;
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const Algorithm_1 = require("../../Algorithm");
const ItemsKey_1 = require("../../Keys/ItemsKey/ItemsKey");
const Functions_1 = require("../../Keys/RootKey/Functions");
const KeyParamsFunctions_1 = require("../../Keys/RootKey/KeyParamsFunctions");
const NO_IV = '00000000000000000000000000000000';
/**
 * @deprecated
 * A legacy operator no longer used to generate new accounts
 */
class SNProtocolOperator001 {
    constructor(crypto) {
        this.crypto = crypto;
    }
    getEncryptionDisplayName() {
        return 'AES-256';
    }
    get version() {
        return common_1.ProtocolVersion.V001;
    }
    generateNewItemsKeyContent() {
        const keyLength = Algorithm_1.V001Algorithm.EncryptionKeyLength;
        const itemsKey = this.crypto.generateRandomKey(keyLength);
        const response = (0, models_1.FillItemContent)({
            itemsKey: itemsKey,
            version: common_1.ProtocolVersion.V001,
        });
        return response;
    }
    /**
     * Creates a new random items key to use for item encryption.
     * The consumer must save/sync this item.
     */
    createItemsKey() {
        const payload = new models_1.DecryptedPayload(Object.assign({ uuid: utils_1.UuidGenerator.GenerateUuid(), content_type: common_1.ContentType.ItemsKey, content: this.generateNewItemsKeyContent() }, (0, models_1.PayloadTimestampDefaults)()));
        return (0, models_1.CreateDecryptedItemFromPayload)(payload);
    }
    createRootKey(identifier, password, origination) {
        return __awaiter(this, void 0, void 0, function* () {
            const pwCost = Algorithm_1.V001Algorithm.PbkdfMinCost;
            const pwNonce = this.crypto.generateRandomKey(Algorithm_1.V001Algorithm.SaltSeedLength);
            const pwSalt = yield this.crypto.unsafeSha1(identifier + 'SN' + pwNonce);
            const keyParams = (0, KeyParamsFunctions_1.Create001KeyParams)({
                email: identifier,
                pw_cost: pwCost,
                pw_nonce: pwNonce,
                pw_salt: pwSalt,
                version: common_1.ProtocolVersion.V001,
                origination,
                created: `${Date.now()}`,
            });
            return this.deriveKey(password, keyParams);
        });
    }
    getPayloadAuthenticatedData(_encrypted) {
        return undefined;
    }
    computeRootKey(password, keyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.deriveKey(password, keyParams);
        });
    }
    decryptString(ciphertext, key) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.crypto.aes256CbcDecrypt(ciphertext, NO_IV, key);
        });
    }
    encryptString(text, key) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.crypto.aes256CbcEncrypt(text, NO_IV, key);
        });
    }
    generateEncryptedParametersAsync(payload, key) {
        return __awaiter(this, void 0, void 0, function* () {
            /**
             * Generate new item key that is double the key size.
             * Will be split to create encryption key and authentication key.
             */
            const itemKey = this.crypto.generateRandomKey(Algorithm_1.V001Algorithm.EncryptionKeyLength * 2);
            const encItemKey = yield this.encryptString(itemKey, key.itemsKey);
            /** Encrypt content */
            const ek = (0, utils_1.firstHalfOfString)(itemKey);
            const ak = (0, utils_1.secondHalfOfString)(itemKey);
            const contentCiphertext = yield this.encryptString(JSON.stringify(payload.content), ek);
            const ciphertext = key.keyVersion + contentCiphertext;
            const authHash = yield this.crypto.hmac256(ciphertext, ak);
            if (!authHash) {
                throw Error('Error generating hmac256 authHash');
            }
            return {
                uuid: payload.uuid,
                items_key_id: (0, ItemsKey_1.isItemsKey)(key) ? key.uuid : undefined,
                content: ciphertext,
                enc_item_key: encItemKey,
                auth_hash: authHash,
                version: this.version,
            };
        });
    }
    generateDecryptedParametersAsync(encrypted, key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!encrypted.enc_item_key) {
                console.error(Error('Missing item encryption key, skipping decryption.'));
                return {
                    uuid: encrypted.uuid,
                    errorDecrypting: true,
                };
            }
            let encryptedItemKey = encrypted.enc_item_key;
            encryptedItemKey = this.version + encryptedItemKey;
            const itemKeyComponents = this.encryptionComponentsFromString(encryptedItemKey, key.itemsKey);
            const itemKey = yield this.decryptString(itemKeyComponents.ciphertext, itemKeyComponents.key);
            if (!itemKey) {
                console.error('Error decrypting parameters', encrypted);
                return {
                    uuid: encrypted.uuid,
                    errorDecrypting: true,
                };
            }
            const ek = (0, utils_1.firstHalfOfString)(itemKey);
            const itemParams = this.encryptionComponentsFromString(encrypted.content, ek);
            const content = yield this.decryptString(itemParams.ciphertext, itemParams.key);
            if (!content) {
                return {
                    uuid: encrypted.uuid,
                    errorDecrypting: true,
                };
            }
            else {
                return {
                    uuid: encrypted.uuid,
                    content: JSON.parse(content),
                };
            }
        });
    }
    encryptionComponentsFromString(string, encryptionKey) {
        const encryptionVersion = string.substring(0, common_1.ProtocolVersionLength);
        return {
            ciphertext: string.substring(common_1.ProtocolVersionLength, string.length),
            version: encryptionVersion,
            key: encryptionKey,
        };
    }
    deriveKey(password, keyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            const derivedKey = yield this.crypto.pbkdf2(password, keyParams.content001.pw_salt, keyParams.content001.pw_cost, Algorithm_1.V001Algorithm.PbkdfOutputLength);
            if (!derivedKey) {
                throw Error('Error deriving PBKDF2 key');
            }
            const partitions = (0, utils_1.splitString)(derivedKey, 2);
            return (0, Functions_1.CreateNewRootKey)({
                serverPassword: partitions[0],
                masterKey: partitions[1],
                version: common_1.ProtocolVersion.V001,
                keyParams: keyParams.getPortableValue(),
            });
        });
    }
}
exports.SNProtocolOperator001 = SNProtocolOperator001;
