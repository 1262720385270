"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PREFERENCE_IDS = [
    'general',
    'account',
    'security',
    'appearance',
    'backups',
    'listed',
    'shortcuts',
    'accessibility',
    'get-free-month',
    'help-feedback',
    'whats-new',
];
