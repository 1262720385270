"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePopoverCloseOnClickOutside = void 0;
const react_1 = require("react");
const usePopoverCloseOnClickOutside = ({ popoverElement, anchorElement, togglePopover, childPopovers, disabled, }) => {
    (0, react_1.useEffect)(() => {
        const closeIfClickedOutside = (event) => {
            var _a;
            const target = event.target;
            const isDescendantOfMenu = popoverElement === null || popoverElement === void 0 ? void 0 : popoverElement.contains(target);
            const isAnchorElement = anchorElement ? anchorElement === event.target || anchorElement.contains(target) : false;
            const closestPopoverId = (_a = target.closest('[data-popover]')) === null || _a === void 0 ? void 0 : _a.getAttribute('data-popover');
            const isDescendantOfChildPopover = closestPopoverId && childPopovers.has(closestPopoverId);
            const isPopoverInModal = popoverElement === null || popoverElement === void 0 ? void 0 : popoverElement.closest('[data-dialog]');
            const isDescendantOfModal = isPopoverInModal ? false : !!target.closest('[data-dialog]');
            if (!isDescendantOfMenu && !isAnchorElement && !isDescendantOfChildPopover && !isDescendantOfModal) {
                if (!disabled) {
                    togglePopover === null || togglePopover === void 0 ? void 0 : togglePopover();
                }
            }
        };
        document.addEventListener('click', closeIfClickedOutside, { capture: true });
        document.addEventListener('contextmenu', closeIfClickedOutside, { capture: true });
        return () => {
            document.removeEventListener('click', closeIfClickedOutside, { capture: true });
            document.removeEventListener('contextmenu', closeIfClickedOutside, { capture: true });
        };
    }, [anchorElement, childPopovers, popoverElement, togglePopover, disabled]);
};
exports.usePopoverCloseOnClickOutside = usePopoverCloseOnClickOutside;
