"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrossControllerEvent = void 0;
var CrossControllerEvent;
(function (CrossControllerEvent) {
    CrossControllerEvent["TagChanged"] = "TagChanged";
    CrossControllerEvent["ActiveEditorChanged"] = "ActiveEditorChanged";
    CrossControllerEvent["HydrateFromPersistedValues"] = "HydrateFromPersistedValues";
    CrossControllerEvent["RequestValuePersistence"] = "RequestValuePersistence";
    CrossControllerEvent["DisplayPremiumModal"] = "DisplayPremiumModal";
})(CrossControllerEvent = exports.CrossControllerEvent || (exports.CrossControllerEvent = {}));
