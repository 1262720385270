"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadSelectedNotesOnAndroid = void 0;
const NoteExportUtils_1 = require("@/Utils/NoteExportUtils");
const filepicker_1 = require("@standardnotes/filepicker");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const DownloadBlobOnAndroid_1 = require("./DownloadBlobOnAndroid");
const downloadSelectedNotesOnAndroid = async (application, notes) => {
    if (!application.isNativeMobileWeb() || application.platform !== snjs_1.Platform.Android) {
        throw new Error('Function being used on non-android platform');
    }
    if (notes.length === 1) {
        const note = notes[0];
        const blob = (0, NoteExportUtils_1.getNoteBlob)(application, note);
        const { name, ext } = (0, filepicker_1.parseFileName)((0, NoteExportUtils_1.getNoteFileName)(application, note));
        const filename = `${(0, ui_services_1.sanitizeFileName)(name)}.${ext}`;
        await (0, DownloadBlobOnAndroid_1.downloadBlobOnAndroid)(application, blob, filename);
        return;
    }
    if (notes.length > 1) {
        const zippedDataBlob = await application.getArchiveService().zipData(notes.map((note) => {
            return {
                name: (0, NoteExportUtils_1.getNoteFileName)(application, note),
                content: (0, NoteExportUtils_1.getNoteBlob)(application, note),
            };
        }));
        const filename = `Standard Notes Export - ${application.getArchiveService().formattedDateForExports()}.zip`;
        await (0, DownloadBlobOnAndroid_1.downloadBlobOnAndroid)(application, zippedDataBlob, filename);
    }
};
exports.downloadSelectedNotesOnAndroid = downloadSelectedNotesOnAndroid;
