"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDropdownItemsForAllEditors = exports.noteTypeForEditorOptionValue = void 0;
const snjs_1 = require("@standardnotes/snjs");
const features_1 = require("@standardnotes/features");
const Constants_1 = require("@/Constants/Constants");
const getIconAndTintForNoteType_1 = require("./Items/Icons/getIconAndTintForNoteType");
function noteTypeForEditorOptionValue(value, application) {
    if (value === snjs_1.FeatureIdentifier.PlainEditor) {
        return features_1.NoteType.Plain;
    }
    else if (value === snjs_1.FeatureIdentifier.SuperEditor) {
        return features_1.NoteType.Super;
    }
    const matchingEditor = application.componentManager
        .componentsForArea(features_1.ComponentArea.Editor)
        .find((editor) => editor.identifier === value);
    return matchingEditor ? matchingEditor.noteType : features_1.NoteType.Unknown;
}
exports.noteTypeForEditorOptionValue = noteTypeForEditorOptionValue;
function getDropdownItemsForAllEditors(application) {
    const plaintextOption = {
        icon: Constants_1.PlainEditorMetadata.icon,
        iconClassName: Constants_1.PlainEditorMetadata.iconClassName,
        label: Constants_1.PlainEditorMetadata.name,
        value: snjs_1.FeatureIdentifier.PlainEditor,
    };
    const options = application.componentManager.componentsForArea(features_1.ComponentArea.Editor).map((editor) => {
        const identifier = editor.package_info.identifier;
        const [iconType, tint] = (0, getIconAndTintForNoteType_1.getIconAndTintForNoteType)(editor.package_info.note_type);
        return {
            label: editor.displayName,
            value: identifier,
            ...(iconType ? { icon: iconType } : null),
            ...(tint ? { iconClassName: `text-accessory-tint-${tint}` } : null),
        };
    });
    options.push(plaintextOption);
    if (application.features.getFeatureStatus(snjs_1.FeatureIdentifier.SuperEditor) === snjs_1.FeatureStatus.Entitled) {
        options.push({
            icon: Constants_1.SuperEditorMetadata.icon,
            iconClassName: Constants_1.SuperEditorMetadata.iconClassName,
            label: Constants_1.SuperEditorMetadata.name,
            value: snjs_1.FeatureIdentifier.SuperEditor,
            isLabs: true,
        });
    }
    options.sort((a, b) => {
        return a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1;
    });
    return options;
}
exports.getDropdownItemsForAllEditors = getDropdownItemsForAllEditors;
