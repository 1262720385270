"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkingController = void 0;
const PopoverFileItemAction_1 = require("@/Components/AttachedFilesPopover/PopoverFileItemAction");
const NoteViewController_1 = require("@/Components/NoteView/Controller/NoteViewController");
const AppPaneMetadata_1 = require("@/Components/Panes/AppPaneMetadata");
const PrefDefaults_1 = require("@/Constants/PrefDefaults");
const createLinkFromItem_1 = require("@/Utils/Items/Search/createLinkFromItem");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
const CrossControllerEvent_1 = require("./CrossControllerEvent");
class LinkingController extends AbstractViewController_1.AbstractViewController {
    constructor(application, navigationController, selectionController, eventBus) {
        super(application, eventBus);
        this.navigationController = navigationController;
        this.selectionController = selectionController;
        this.isLinkingPanelOpen = false;
        this.setIsLinkingPanelOpen = (open) => {
            this.isLinkingPanelOpen = open;
        };
        this.getFilesLinksForItem = (item) => {
            if (!item || this.application.items.isTemplateItem(item)) {
                return {
                    filesLinkedToItem: [],
                    filesLinkingToItem: [],
                };
            }
            const referencesOfItem = (0, snjs_1.naturalSort)(this.application.items.referencesForItem(item).filter(snjs_1.isFile), 'title');
            const referencingItem = (0, snjs_1.naturalSort)(this.application.items.itemsReferencingItem(item).filter(snjs_1.isFile), 'title');
            if (item.content_type === snjs_1.ContentType.File) {
                return {
                    filesLinkedToItem: referencesOfItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked')),
                    filesLinkingToItem: referencingItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked-by')),
                };
            }
            else {
                return {
                    filesLinkedToItem: referencingItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked')),
                    filesLinkingToItem: referencesOfItem.map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked-by')),
                };
            }
        };
        this.getLinkedTagsForItem = (item) => {
            if (!item) {
                return;
            }
            return this.application.items.getSortedTagsForItem(item).map((tag) => (0, createLinkFromItem_1.createLinkFromItem)(tag, 'linked'));
        };
        this.getLinkedNotesForItem = (item) => {
            if (!item || this.application.items.isTemplateItem(item)) {
                return [];
            }
            return (0, snjs_1.naturalSort)(this.application.items.referencesForItem(item).filter(snjs_1.isNote), 'title').map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked'));
        };
        this.getNotesLinkingToItem = (item) => {
            if (!item) {
                return [];
            }
            return (0, snjs_1.naturalSort)(this.application.items.itemsReferencingItem(item).filter(snjs_1.isNote), 'title').map((item) => (0, createLinkFromItem_1.createLinkFromItem)(item, 'linked-by'));
        };
        this.activateItem = async (item) => {
            this.setIsLinkingPanelOpen(false);
            if (item instanceof snjs_1.SNTag) {
                await this.navigationController.setSelectedTag(item, 'all');
                return AppPaneMetadata_1.AppPaneId.Items;
            }
            else if (item instanceof snjs_1.SNNote) {
                await this.navigationController.selectHomeNavigationView();
                const { didSelect } = await this.selectionController.selectItem(item.uuid, true);
                if (didSelect) {
                    return AppPaneMetadata_1.AppPaneId.Editor;
                }
            }
            else if (item instanceof snjs_1.FileItem) {
                await this.filesController.handleFileAction({
                    type: PopoverFileItemAction_1.FileItemActionType.PreviewFile,
                    payload: {
                        file: item,
                        otherFiles: [],
                    },
                });
            }
            return undefined;
        };
        this.unlinkItems = async (item, itemToUnlink) => {
            await this.application.items.unlinkItems(item, itemToUnlink);
            void this.application.sync.sync();
        };
        this.unlinkItemFromSelectedItem = async (itemToUnlink) => {
            const selectedItem = this.selectionController.firstSelectedItem;
            if (!selectedItem) {
                return;
            }
            void this.unlinkItems(selectedItem, itemToUnlink);
        };
        this.ensureActiveItemIsInserted = async () => {
            const activeItemController = this.itemListController.getActiveItemController();
            if (activeItemController instanceof NoteViewController_1.NoteViewController && activeItemController.isTemplateNote) {
                await activeItemController.insertTemplatedNote();
            }
        };
        this.linkItems = async (item, itemToLink) => {
            var _a;
            if (item instanceof snjs_1.SNNote) {
                if (itemToLink instanceof snjs_1.SNNote && !this.isEntitledToNoteLinking) {
                    void this.publishCrossControllerEventSync(CrossControllerEvent_1.CrossControllerEvent.DisplayPremiumModal, {
                        featureName: 'Note linking',
                    });
                    return;
                }
                if (item.uuid === ((_a = this.activeItem) === null || _a === void 0 ? void 0 : _a.uuid)) {
                    await this.ensureActiveItemIsInserted();
                }
                if (itemToLink instanceof snjs_1.FileItem) {
                    await this.application.items.associateFileWithNote(itemToLink, item);
                }
                else if (itemToLink instanceof snjs_1.SNNote) {
                    await this.application.items.linkNoteToNote(item, itemToLink);
                }
                else if (itemToLink instanceof snjs_1.SNTag) {
                    await this.addTagToItem(itemToLink, item);
                }
                else {
                    throw Error('Invalid item type');
                }
            }
            else if (item instanceof snjs_1.FileItem) {
                if (itemToLink instanceof snjs_1.SNNote) {
                    await this.application.items.associateFileWithNote(item, itemToLink);
                }
                else if (itemToLink instanceof snjs_1.FileItem) {
                    await this.application.items.linkFileToFile(item, itemToLink);
                }
                else if (itemToLink instanceof snjs_1.SNTag) {
                    await this.addTagToItem(itemToLink, item);
                }
                else {
                    throw Error('Invalid item to link');
                }
            }
            else {
                throw new Error('First item must be a note or file');
            }
            void this.application.sync.sync();
        };
        this.linkItemToSelectedItem = async (itemToLink) => {
            const cannotLinkItem = !this.isEntitledToNoteLinking && itemToLink instanceof snjs_1.SNNote;
            if (cannotLinkItem) {
                void this.publishCrossControllerEventSync(CrossControllerEvent_1.CrossControllerEvent.DisplayPremiumModal, {
                    featureName: 'Note linking',
                });
                return false;
            }
            await this.ensureActiveItemIsInserted();
            const activeItem = this.activeItem;
            if (!activeItem) {
                return false;
            }
            await this.linkItems(activeItem, itemToLink);
            return true;
        };
        this.createAndAddNewTag = async (title) => {
            await this.ensureActiveItemIsInserted();
            const activeItem = this.activeItem;
            const newTag = await this.application.mutator.findOrCreateTag(title);
            if (activeItem) {
                await this.addTagToItem(newTag, activeItem);
            }
            return newTag;
        };
        this.addTagToItem = async (tag, item) => {
            if (item instanceof snjs_1.SNNote) {
                await this.application.items.addTagToNote(item, tag, this.shouldLinkToParentFolders);
            }
            else if (item instanceof snjs_1.FileItem) {
                await this.application.items.addTagToFile(item, tag, this.shouldLinkToParentFolders);
            }
            this.application.sync.sync().catch(console.error);
        };
        (0, mobx_1.makeObservable)(this, {
            isLinkingPanelOpen: mobx_1.observable,
            isEntitledToNoteLinking: mobx_1.computed,
            setIsLinkingPanelOpen: mobx_1.action,
        });
        this.shouldLinkToParentFolders = application.getPreference(snjs_1.PrefKey.NoteAddToParentFolders, PrefDefaults_1.PrefDefaults[snjs_1.PrefKey.NoteAddToParentFolders]);
        this.disposers.push(this.application.addSingleEventObserver(snjs_1.ApplicationEvent.PreferencesChanged, async () => {
            this.shouldLinkToParentFolders = this.application.getPreference(snjs_1.PrefKey.NoteAddToParentFolders, PrefDefaults_1.PrefDefaults[snjs_1.PrefKey.NoteAddToParentFolders]);
        }));
    }
    setServicesPostConstruction(itemListController, filesController, subscriptionController) {
        this.itemListController = itemListController;
        this.filesController = filesController;
        this.subscriptionController = subscriptionController;
    }
    get isEntitledToNoteLinking() {
        return !!this.subscriptionController.onlineSubscription;
    }
    get activeItem() {
        var _a;
        return (_a = this.application.itemControllerGroup.activeItemViewController) === null || _a === void 0 ? void 0 : _a.item;
    }
}
exports.LinkingController = LinkingController;
