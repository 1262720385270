"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateServerSyncSavedPayload = void 0;
const utils_1 = require("@standardnotes/utils");
function CreateServerSyncSavedPayload(from) {
    return {
        content_type: from.content_type,
        created_at_timestamp: from.created_at_timestamp,
        created_at: from.created_at,
        deleted: (0, utils_1.useBoolean)(from.deleted, false),
        updated_at_timestamp: from.updated_at_timestamp,
        updated_at: from.updated_at,
        uuid: from.uuid,
    };
}
exports.CreateServerSyncSavedPayload = CreateServerSyncSavedPayload;
