"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsTabletOrMobileScreen = void 0;
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const Utils_1 = require("@/Utils");
const react_1 = require("react");
function getIsTabletOrMobileScreen(application) {
    const isNativeMobile = application.isNativeMobileWeb();
    const isTabletOrMobile = (0, Utils_1.isTabletOrMobileScreen)() || isNativeMobile;
    const isTablet = (0, Utils_1.isTabletScreen)() || (isNativeMobile && !(0, Utils_1.isMobileScreen)());
    const isMobile = (0, Utils_1.isMobileScreen)() || (isNativeMobile && !isTablet);
    if (isTablet && isMobile) {
        throw Error('isTablet and isMobile cannot both be true');
    }
    return {
        isTabletOrMobile,
        isTablet,
        isMobile,
    };
}
exports.getIsTabletOrMobileScreen = getIsTabletOrMobileScreen;
function useIsTabletOrMobileScreen() {
    const [_windowSize, setWindowSize] = (0, react_1.useState)(0);
    const application = (0, ApplicationProvider_1.useApplication)();
    (0, react_1.useEffect)(() => {
        const handleResize = (0, Utils_1.debounce)(() => {
            setWindowSize(window.innerWidth);
        }, 100);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return getIsTabletOrMobileScreen(application);
}
exports.default = useIsTabletOrMobileScreen;
