"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrefDefaults = void 0;
const models_1 = require("@standardnotes/models");
const snjs_1 = require("@standardnotes/snjs");
exports.PrefDefaults = {
    [models_1.PrefKey.TagsPanelWidth]: 220,
    [models_1.PrefKey.NotesPanelWidth]: 350,
    [models_1.PrefKey.EditorWidth]: null,
    [models_1.PrefKey.EditorLeft]: null,
    [models_1.PrefKey.EditorMonospaceEnabled]: false,
    [models_1.PrefKey.EditorSpellcheck]: true,
    [models_1.PrefKey.EditorResizersEnabled]: false,
    [models_1.PrefKey.EditorLineHeight]: models_1.EditorLineHeight.Normal,
    [models_1.PrefKey.EditorLineWidth]: models_1.EditorLineWidth.FullWidth,
    [models_1.PrefKey.EditorFontSize]: models_1.EditorFontSize.Normal,
    [models_1.PrefKey.SortNotesBy]: models_1.CollectionSort.CreatedAt,
    [models_1.PrefKey.SortNotesReverse]: false,
    [models_1.PrefKey.NotesShowArchived]: false,
    [models_1.PrefKey.NotesShowTrashed]: false,
    [models_1.PrefKey.NotesHidePinned]: false,
    [models_1.PrefKey.NotesHideProtected]: false,
    [models_1.PrefKey.NotesHideNotePreview]: false,
    [models_1.PrefKey.NotesHideDate]: false,
    [models_1.PrefKey.NotesHideTags]: false,
    [models_1.PrefKey.NotesHideEditorIcon]: false,
    [models_1.PrefKey.UseSystemColorScheme]: false,
    [models_1.PrefKey.AutoLightThemeIdentifier]: 'Default',
    [models_1.PrefKey.AutoDarkThemeIdentifier]: snjs_1.FeatureIdentifier.DarkTheme,
    [models_1.PrefKey.NoteAddToParentFolders]: true,
    [models_1.PrefKey.NewNoteTitleFormat]: models_1.NewNoteTitleFormat.CurrentDateAndTime,
    [models_1.PrefKey.CustomNoteTitleFormat]: 'YYYY-MM-DD [at] hh:mm A',
    [models_1.PrefKey.UpdateSavingStatusIndicator]: true,
    [models_1.PrefKey.PaneGesturesEnabled]: true,
    [models_1.PrefKey.MomentsDefaultTagUuid]: undefined,
    [models_1.PrefKey.ClipperDefaultTagUuid]: undefined,
    [models_1.PrefKey.DefaultEditorIdentifier]: snjs_1.FeatureIdentifier.PlainEditor,
    [models_1.PrefKey.SuperNoteExportFormat]: 'json',
    [models_1.PrefKey.SystemViewPreferences]: {},
    [models_1.PrefKey.AuthenticatorNames]: '',
};
