"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortThemes = void 0;
const snjs_1 = require("@standardnotes/snjs");
const isDarkModeTheme = (theme) => theme.identifier === snjs_1.FeatureIdentifier.DarkTheme;
const sortThemes = (a, b) => {
    var _a, _b;
    const aIsLayerable = (_a = a.component) === null || _a === void 0 ? void 0 : _a.isLayerable();
    const bIsLayerable = (_b = b.component) === null || _b === void 0 ? void 0 : _b.isLayerable();
    if (aIsLayerable && !bIsLayerable) {
        return 1;
    }
    else if (!aIsLayerable && bIsLayerable) {
        return -1;
    }
    else if (!isDarkModeTheme(a) && isDarkModeTheme(b)) {
        return 1;
    }
    else {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    }
};
exports.sortThemes = sortThemes;
