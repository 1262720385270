"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDocumentRect = void 0;
const Utils_1 = require("@/Utils");
const react_1 = require("react");
const DebounceTimeInMs = 100;
const getBoundingClientRect = () => {
    return (0, Utils_1.isIOS)() ? document.body.getBoundingClientRect() : document.documentElement.getBoundingClientRect();
};
const useDocumentRect = () => {
    const [documentRect, setDocumentRect] = (0, react_1.useState)(getBoundingClientRect());
    (0, react_1.useEffect)(() => {
        let debounceTimeout;
        const handleWindowResize = () => {
            window.clearTimeout(debounceTimeout);
            window.setTimeout(() => {
                setDocumentRect(getBoundingClientRect());
            }, DebounceTimeInMs);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    return documentRect;
};
exports.useDocumentRect = useDocumentRect;
