"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteMutator = void 0;
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
const common_1 = require("@standardnotes/common");
const Item_1 = require("../../Abstract/Item");
class NoteMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    set title(title) {
        this.mutableContent.title = title;
    }
    set text(text) {
        this.mutableContent.text = text;
    }
    set hidePreview(hidePreview) {
        this.mutableContent.hidePreview = hidePreview;
    }
    set preview_plain(preview_plain) {
        this.mutableContent.preview_plain = preview_plain;
    }
    set preview_html(preview_html) {
        this.mutableContent.preview_html = preview_html;
    }
    set spellcheck(spellcheck) {
        this.mutableContent.spellcheck = spellcheck;
    }
    set editorWidth(editorWidth) {
        this.mutableContent.editorWidth = editorWidth;
    }
    set noteType(noteType) {
        this.mutableContent.noteType = noteType;
    }
    set editorIdentifier(identifier) {
        this.mutableContent.editorIdentifier = identifier;
    }
    set authorizedForListed(authorizedForListed) {
        this.mutableContent.authorizedForListed = authorizedForListed;
    }
    toggleSpellcheck() {
        if (this.mutableContent.spellcheck == undefined) {
            this.mutableContent.spellcheck = false;
        }
        else {
            this.mutableContent.spellcheck = !this.mutableContent.spellcheck;
        }
    }
    addNote(note) {
        if (this.immutableItem.isReferencingItem(note)) {
            return;
        }
        const reference = {
            uuid: note.uuid,
            content_type: common_1.ContentType.Note,
            reference_type: Item_1.ContentReferenceType.NoteToNote,
        };
        this.mutableContent.references.push(reference);
    }
    removeNote(note) {
        this.mutableContent.references = this.mutableContent.references.filter((r) => r.uuid !== note.uuid);
    }
}
exports.NoteMutator = NoteMutator;
