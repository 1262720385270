"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRemotePayloadAllowed = exports.FilterDisallowedRemotePayloadsAndMap = exports.CreateFilteredServerItem = void 0;
const TransferPayload_1 = require("../TransferPayload");
function CreateFilteredServerItem(item) {
    return Object.assign(Object.assign({}, item), { __passed_filter__: true });
}
exports.CreateFilteredServerItem = CreateFilteredServerItem;
function FilterDisallowedRemotePayloadsAndMap(payloads) {
    return payloads.filter(isRemotePayloadAllowed).map(CreateFilteredServerItem);
}
exports.FilterDisallowedRemotePayloadsAndMap = FilterDisallowedRemotePayloadsAndMap;
function isRemotePayloadAllowed(payload) {
    if ((0, TransferPayload_1.isCorruptTransferPayload)(payload)) {
        return false;
    }
    return (0, TransferPayload_1.isEncryptedTransferPayload)(payload) || payload.content == undefined;
}
exports.isRemotePayloadAllowed = isRemotePayloadAllowed;
