"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function (o, m, k, k2) {
    if (k2 === undefined)
        k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
        desc = { enumerable: true, get: function () { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function (o, m, k, k2) {
    if (k2 === undefined)
        k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function (o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function (o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule)
        return mod;
    var result = {};
    if (mod != null)
        for (var k in mod)
            if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k))
                __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNWebCrypto = void 0;
const sncrypto_common_1 = require("@standardnotes/sncrypto-common");
const Utils = __importStar(require("./utils"));
const sodium = __importStar(require("./libsodium"));
var WebCryptoAlgs;
(function (WebCryptoAlgs) {
    WebCryptoAlgs["AesCbc"] = "AES-CBC";
    WebCryptoAlgs["Sha512"] = "SHA-512";
    WebCryptoAlgs["Sha256"] = "SHA-256";
    WebCryptoAlgs["Pbkdf2"] = "PBKDF2";
    WebCryptoAlgs["Sha1"] = "SHA-1";
    WebCryptoAlgs["Hmac"] = "HMAC";
})(WebCryptoAlgs || (WebCryptoAlgs = {}));
var WebCryptoActions;
(function (WebCryptoActions) {
    WebCryptoActions["DeriveBits"] = "deriveBits";
    WebCryptoActions["Encrypt"] = "encrypt";
    WebCryptoActions["Decrypt"] = "decrypt";
    WebCryptoActions["Sign"] = "sign";
})(WebCryptoActions || (WebCryptoActions = {}));
/**
 * The web crypto class allows access to a set of cryptographic primitives available
 * in a web environment, consisting of two main sources:
 * — Built-in browser WebCrypto
 * — Libsodium.js library integration
 */
class SNWebCrypto {
    constructor() {
        /** Functions using Libsodium must await this
         * promise before performing any library functions */
        this.ready = sodium.ready;
    }
    async initialize() {
        await this.ready;
    }
    deinit() {
        this.ready = null;
    }
    generateUUID() {
        return Utils.generateUUID();
    }
    timingSafeEqual(a, b) {
        return (0, sncrypto_common_1.timingSafeEqual)(a, b);
    }
    base64Encode(text) {
        return Utils.base64Encode(text);
    }
    base64URLEncode(text) {
        return Utils.base64URLEncode(text);
    }
    base64Decode(base64String) {
        return Utils.base64Decode(base64String);
    }
    async pbkdf2(password, salt, iterations, length) {
        const keyData = Utils.stringToArrayBuffer(password);
        const key = await this.webCryptoImportKey(keyData, WebCryptoAlgs.Pbkdf2, [WebCryptoActions.DeriveBits]);
        if (!key) {
            console.error('Key is null, unable to continue');
            return null;
        }
        return this.webCryptoDeriveBits(key, salt, iterations, length);
    }
    generateRandomKey(bits) {
        const bytes = bits / 8;
        const arrayBuffer = Utils.getGlobalScope().crypto.getRandomValues(new Uint8Array(bytes));
        return Utils.arrayBufferToHexString(arrayBuffer);
    }
    async aes256CbcEncrypt(plaintext, iv, key) {
        const keyData = Utils.hexStringToArrayBuffer(key);
        const ivData = Utils.hexStringToArrayBuffer(iv);
        const alg = { name: WebCryptoAlgs.AesCbc, iv: ivData };
        const importedKeyData = await this.webCryptoImportKey(keyData, alg.name, [WebCryptoActions.Encrypt]);
        const textData = Utils.stringToArrayBuffer(plaintext);
        const result = await crypto.subtle.encrypt(alg, importedKeyData, textData);
        return Utils.arrayBufferToBase64(result);
    }
    async aes256CbcDecrypt(ciphertext, iv, key) {
        const keyData = Utils.hexStringToArrayBuffer(key);
        const ivData = Utils.hexStringToArrayBuffer(iv);
        const alg = { name: WebCryptoAlgs.AesCbc, iv: ivData };
        const importedKeyData = await this.webCryptoImportKey(keyData, alg.name, [WebCryptoActions.Decrypt]);
        const textData = Utils.base64ToArrayBuffer(ciphertext);
        try {
            const result = await crypto.subtle.decrypt(alg, importedKeyData, textData);
            return Utils.arrayBufferToString(result);
        }
        catch {
            return null;
        }
    }
    async hmac256(message, key) {
        const keyHexData = Utils.hexStringToArrayBuffer(key);
        const keyData = await this.webCryptoImportKey(keyHexData, WebCryptoAlgs.Hmac, [WebCryptoActions.Sign], {
            name: WebCryptoAlgs.Sha256,
        });
        const messageData = Utils.stringToArrayBuffer(message);
        const funcParams = { name: WebCryptoAlgs.Hmac };
        try {
            const signature = await crypto.subtle.sign(funcParams, keyData, messageData);
            return Utils.arrayBufferToHexString(signature);
        }
        catch (error) {
            console.error('Error computing HMAC:', error);
            return null;
        }
    }
    async sha256(text) {
        const textData = Utils.stringToArrayBuffer(text);
        const digest = await crypto.subtle.digest(WebCryptoAlgs.Sha256, textData);
        return Utils.arrayBufferToHexString(digest);
    }
    async hmac1(message, key) {
        const keyHexData = Utils.hexStringToArrayBuffer(key);
        const keyData = await this.webCryptoImportKey(keyHexData, WebCryptoAlgs.Hmac, [WebCryptoActions.Sign], {
            name: WebCryptoAlgs.Sha1,
        });
        const messageData = Utils.stringToArrayBuffer(message);
        const funcParams = { name: WebCryptoAlgs.Hmac };
        try {
            const signature = await crypto.subtle.sign(funcParams, keyData, messageData);
            return Utils.arrayBufferToHexString(signature);
        }
        catch (error) {
            console.error('Error computing HMAC:', error);
            return null;
        }
    }
    async unsafeSha1(text) {
        const textData = Utils.stringToArrayBuffer(text);
        const digest = await crypto.subtle.digest(WebCryptoAlgs.Sha1, textData);
        return Utils.arrayBufferToHexString(digest);
    }
    /**
     * Converts a raw string key to a WebCrypto CryptoKey object.
     * @param keyData
     * @param alg
     *    The name of the algorithm this key will be used for (i.e 'AES-CBC' or 'HMAC')
     * @param actions
     *    The actions this key will be used for (i.e 'deriveBits' or 'encrypt')
     * @param hash
     *    An optional object representing the hashing function this key is intended to be
     *    used for. This option is only supplied when the `alg` is HMAC.
     * @param hash.name
     *    The name of the hashing function to use with HMAC.
     * @returns A WebCrypto CryptoKey object
     */
    async webCryptoImportKey(keyData, alg, actions, hash) {
        return Utils.getSubtleCrypto().importKey('raw', keyData, {
            name: alg,
            hash: hash,
        }, false, actions);
    }
    /**
     * Performs WebCrypto PBKDF2 derivation.
     * @param key - A WebCrypto CryptoKey object
     * @param length - In bits
     */
    async webCryptoDeriveBits(key, salt, iterations, length) {
        const params = {
            name: WebCryptoAlgs.Pbkdf2,
            salt: Utils.stringToArrayBuffer(salt),
            iterations: iterations,
            hash: { name: WebCryptoAlgs.Sha512 },
        };
        return Utils.getSubtleCrypto()
            .deriveBits(params, key, length)
            .then((bits) => {
            return Utils.arrayBufferToHexString(new Uint8Array(bits));
        });
    }
    argon2(password, salt, iterations, bytes, length) {
        const result = sodium.crypto_pwhash(length, Utils.stringToArrayBuffer(password), Utils.hexStringToArrayBuffer(salt), iterations, bytes, sodium.crypto_pwhash_ALG_DEFAULT, 'hex');
        return result;
    }
    xchacha20Encrypt(plaintext, nonce, key, assocData) {
        if (nonce.length !== 48) {
            throw Error('Nonce must be 24 bytes');
        }
        const arrayBuffer = sodium.crypto_aead_xchacha20poly1305_ietf_encrypt(plaintext, assocData || null, null, Utils.hexStringToArrayBuffer(nonce), Utils.hexStringToArrayBuffer(key));
        return Utils.arrayBufferToBase64(arrayBuffer);
    }
    xchacha20Decrypt(ciphertext, nonce, key, assocData) {
        if (nonce.length !== 48) {
            throw Error('Nonce must be 24 bytes');
        }
        try {
            return sodium.crypto_aead_xchacha20poly1305_ietf_decrypt(null, Utils.base64ToArrayBuffer(ciphertext), assocData || null, Utils.hexStringToArrayBuffer(nonce), Utils.hexStringToArrayBuffer(key), 'text');
        }
        catch {
            return null;
        }
    }
    xchacha20StreamInitEncryptor(key) {
        const res = sodium.crypto_secretstream_xchacha20poly1305_init_push(Utils.hexStringToArrayBuffer(key));
        return {
            state: res.state,
            header: Utils.arrayBufferToBase64(res.header),
        };
    }
    xchacha20StreamEncryptorPush(encryptor, plainBuffer, assocData, tag = sncrypto_common_1.SodiumConstant.CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PUSH) {
        const encryptedBuffer = sodium.crypto_secretstream_xchacha20poly1305_push(encryptor.state, plainBuffer, assocData && assocData.length > 0 ? Utils.stringToArrayBuffer(assocData) : null, tag);
        return encryptedBuffer;
    }
    xchacha20StreamInitDecryptor(header, key) {
        const rawHeader = Utils.base64ToArrayBuffer(header);
        if (rawHeader.length !== sncrypto_common_1.SodiumConstant.CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_HEADERBYTES) {
            throw new Error(`Header must be ${sncrypto_common_1.SodiumConstant.CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_HEADERBYTES} bytes long`);
        }
        const state = sodium.crypto_secretstream_xchacha20poly1305_init_pull(rawHeader, Utils.hexStringToArrayBuffer(key));
        return { state };
    }
    xchacha20StreamDecryptorPush(decryptor, encryptedBuffer, assocData) {
        if (encryptedBuffer.length < sncrypto_common_1.SodiumConstant.CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_ABYTES) {
            throw new Error('Invalid ciphertext size');
        }
        const result = sodium.crypto_secretstream_xchacha20poly1305_pull(decryptor.state, encryptedBuffer, assocData && assocData.length > 0 ? Utils.stringToArrayBuffer(assocData) : null);
        if (result === false) {
            return false;
        }
        return result;
    }
    /**
     * https://doc.libsodium.org/public-key_cryptography/authenticated_encryption
     */
    sodiumCryptoBoxEasyEncrypt(message, nonce, senderSecretKey, recipientPublicKey) {
        const result = sodium.crypto_box_easy(message, Utils.hexStringToArrayBuffer(nonce), Utils.hexStringToArrayBuffer(recipientPublicKey), Utils.hexStringToArrayBuffer(senderSecretKey));
        return Utils.arrayBufferToBase64(result);
    }
    sodiumCryptoBoxEasyDecrypt(ciphertext, nonce, senderPublicKey, recipientSecretKey) {
        const result = sodium.crypto_box_open_easy(Utils.base64ToArrayBuffer(ciphertext), Utils.hexStringToArrayBuffer(nonce), Utils.hexStringToArrayBuffer(senderPublicKey), Utils.hexStringToArrayBuffer(recipientSecretKey), 'text');
        return result;
    }
    sodiumCryptoBoxGenerateKeypair() {
        const result = sodium.crypto_box_keypair();
        const publicKey = Utils.arrayBufferToHexString(result.publicKey);
        const privateKey = Utils.arrayBufferToHexString(result.privateKey);
        return { publicKey, privateKey, keyType: result.keyType };
    }
    /**
     * Generates a random secret for TOTP authentication
     *
     * RFC4226 reccomends a length of at least 160 bits = 32 b32 chars
     * https://datatracker.ietf.org/doc/html/rfc4226#section-4
     */
    async generateOtpSecret() {
        const bits = 160;
        const bytes = bits / 8;
        const secretBytes = Utils.getGlobalScope().crypto.getRandomValues(new Uint8Array(bytes));
        const secret = Utils.base32Encode(secretBytes);
        return secret;
    }
    /**
     * Generates a HOTP code as per RFC4226 specification
     * using HMAC-SHA1
     * https://datatracker.ietf.org/doc/html/rfc4226
     *
     * @param secret OTP shared secret
     * @param counter HOTP counter
     * @returns HOTP auth code
     */
    async hotpToken(secret, counter, tokenLength = 6) {
        const bytes = new Uint8Array(Utils.base32Decode(secret));
        const key = await this.webCryptoImportKey(bytes, WebCryptoAlgs.Hmac, [WebCryptoActions.Sign], {
            name: WebCryptoAlgs.Sha1,
        });
        const counterArray = Utils.padStart(counter);
        const hs = await Utils.getSubtleCrypto().sign('HMAC', key, counterArray);
        const sNum = Utils.truncateOTP(hs);
        const padded = ('0'.repeat(tokenLength) + (sNum % 10 ** tokenLength)).slice(-tokenLength);
        return padded;
    }
    /**
     * Generates a TOTP code as per RFC6238 specification
     * using HMAC-SHA1
     * https://datatracker.ietf.org/doc/html/rfc6238
     *
     * @param secret OTP shared secret
     * @param timestamp time specified in milliseconds since UNIX epoch
     * @param step time step specified in seconds
     * @returns TOTP auth code
     */
    async totpToken(secret, timestamp, tokenLength = 6, step = 30) {
        const time = Math.floor(timestamp / step / 1000.0);
        const token = await this.hotpToken(secret, time, tokenLength);
        return token;
    }
}
exports.SNWebCrypto = SNWebCrypto;
