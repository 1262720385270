"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppDataField = void 0;
var AppDataField;
(function (AppDataField) {
    AppDataField["Pinned"] = "pinned";
    AppDataField["Archived"] = "archived";
    AppDataField["Locked"] = "locked";
    AppDataField["UserModifiedDate"] = "client_updated_at";
    AppDataField["DefaultEditor"] = "defaultEditor";
    AppDataField["MobileRules"] = "mobileRules";
    AppDataField["NotAvailableOnMobile"] = "notAvailableOnMobile";
    AppDataField["MobileActive"] = "mobileActive";
    AppDataField["LastSize"] = "lastSize";
    AppDataField["LegacyPrefersPlainEditor"] = "prefersPlainEditor";
    AppDataField["ComponentInstallError"] = "installError";
})(AppDataField = exports.AppDataField || (exports.AppDataField = {}));
