"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootKeyEncryptionService = void 0;
const common_1 = require("@standardnotes/common");
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const AbstractService_1 = require("../Service/AbstractService");
const StorageKeys_1 = require("../Storage/StorageKeys");
const StorageTypes_1 = require("../Storage/StorageTypes");
class RootKeyEncryptionService extends AbstractService_1.AbstractService {
    constructor(itemManager, operatorManager, deviceInterface, storageService, identifier, internalEventBus) {
        super(internalEventBus);
        this.itemManager = itemManager;
        this.operatorManager = operatorManager;
        this.deviceInterface = deviceInterface;
        this.storageService = storageService;
        this.identifier = identifier;
        this.internalEventBus = internalEventBus;
        this.keyMode = encryption_1.KeyMode.RootKeyNone;
    }
    deinit() {
        ;
        this.itemManager = undefined;
        this.rootKey = undefined;
        this.memoizedRootKeyParams = undefined;
        super.deinit();
    }
    async initialize() {
        const wrappedRootKey = this.getWrappedRootKey();
        const accountKeyParams = await this.recomputeAccountKeyParams();
        const hasWrapper = await this.hasRootKeyWrapper();
        const hasRootKey = wrappedRootKey != undefined || accountKeyParams != undefined;
        if (hasWrapper && hasRootKey) {
            this.keyMode = encryption_1.KeyMode.RootKeyPlusWrapper;
        }
        else if (hasWrapper && !hasRootKey) {
            this.keyMode = encryption_1.KeyMode.WrapperOnly;
        }
        else if (!hasWrapper && hasRootKey) {
            this.keyMode = encryption_1.KeyMode.RootKeyOnly;
        }
        else if (!hasWrapper && !hasRootKey) {
            this.keyMode = encryption_1.KeyMode.RootKeyNone;
        }
        else {
            throw 'Invalid key mode condition';
        }
        if (this.keyMode === encryption_1.KeyMode.RootKeyOnly) {
            this.setRootKeyInstance(await this.getRootKeyFromKeychain());
            await this.handleKeyStatusChange();
        }
    }
    async handleKeyStatusChange() {
        await this.recomputeAccountKeyParams();
        void this.notifyEvent(encryption_1.RootKeyServiceEvent.RootKeyStatusChanged);
    }
    async passcodeUpgradeAvailable() {
        const passcodeParams = await this.getRootKeyWrapperKeyParams();
        if (!passcodeParams) {
            return false;
        }
        return passcodeParams.version !== common_1.ProtocolVersionLatest;
    }
    async hasRootKeyWrapper() {
        const wrapper = await this.getRootKeyWrapperKeyParams();
        return wrapper != undefined;
    }
    hasAccount() {
        switch (this.keyMode) {
            case encryption_1.KeyMode.RootKeyNone:
            case encryption_1.KeyMode.WrapperOnly:
                return false;
            case encryption_1.KeyMode.RootKeyOnly:
            case encryption_1.KeyMode.RootKeyPlusWrapper:
                return true;
            default:
                throw Error(`Unhandled keyMode value '${this.keyMode}'.`);
        }
    }
    hasRootKeyEncryptionSource() {
        return this.hasAccount() || this.hasPasscode();
    }
    hasPasscode() {
        return this.keyMode === encryption_1.KeyMode.WrapperOnly || this.keyMode === encryption_1.KeyMode.RootKeyPlusWrapper;
    }
    async getEncryptionSourceVersion() {
        if (this.hasAccount()) {
            return this.getSureUserVersion();
        }
        else if (this.hasPasscode()) {
            const passcodeParams = await this.getSureRootKeyWrapperKeyParams();
            return passcodeParams.version;
        }
        throw Error('Attempting to access encryption source version without source');
    }
    getUserVersion() {
        const keyParams = this.memoizedRootKeyParams;
        return keyParams === null || keyParams === void 0 ? void 0 : keyParams.version;
    }
    getSureUserVersion() {
        const keyParams = this.memoizedRootKeyParams;
        return keyParams.version;
    }
    async getRootKeyFromKeychain() {
        const rawKey = (await this.deviceInterface.getNamespacedKeychainValue(this.identifier));
        if (rawKey == undefined) {
            return undefined;
        }
        const keyParams = await this.getSureRootKeyParams();
        return (0, encryption_1.CreateNewRootKey)({
            ...rawKey,
            keyParams: keyParams.getPortableValue(),
        });
    }
    async saveRootKeyToKeychain() {
        if (this.getRootKey() == undefined) {
            throw 'Attempting to non-existent root key to the keychain.';
        }
        if (this.keyMode !== encryption_1.KeyMode.RootKeyOnly) {
            throw 'Should not be persisting wrapped key to keychain.';
        }
        const rawKey = this.getSureRootKey().getKeychainValue();
        return this.executeCriticalFunction(() => {
            return this.deviceInterface.setNamespacedKeychainValue(rawKey, this.identifier);
        });
    }
    async getRootKeyWrapperKeyParams() {
        const rawKeyParams = await this.storageService.getValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        if (!rawKeyParams) {
            return undefined;
        }
        return (0, encryption_1.CreateAnyKeyParams)(rawKeyParams);
    }
    async getSureRootKeyWrapperKeyParams() {
        return this.getRootKeyWrapperKeyParams();
    }
    async getRootKeyParams() {
        if (this.keyMode === encryption_1.KeyMode.WrapperOnly) {
            return this.getRootKeyWrapperKeyParams();
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyOnly || this.keyMode === encryption_1.KeyMode.RootKeyPlusWrapper) {
            return this.recomputeAccountKeyParams();
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyNone) {
            return undefined;
        }
        else {
            throw `Unhandled key mode for getRootKeyParams ${this.keyMode}`;
        }
    }
    async getSureRootKeyParams() {
        return this.getRootKeyParams();
    }
    async computeRootKey(password, keyParams) {
        const version = keyParams.version;
        const operator = this.operatorManager.operatorForVersion(version);
        return operator.computeRootKey(password, keyParams);
    }
    async createRootKey(identifier, password, origination, version) {
        const operator = version ? this.operatorManager.operatorForVersion(version) : this.operatorManager.defaultOperator();
        return operator.createRootKey(identifier, password, origination);
    }
    getSureMemoizedRootKeyParams() {
        return this.memoizedRootKeyParams;
    }
    async validateAccountPassword(password) {
        const key = await this.computeRootKey(password, this.getSureMemoizedRootKeyParams());
        const valid = this.getSureRootKey().compare(key);
        if (valid) {
            return { valid, artifacts: { rootKey: key } };
        }
        else {
            return { valid: false };
        }
    }
    async validatePasscode(passcode) {
        const keyParams = await this.getSureRootKeyWrapperKeyParams();
        const key = await this.computeRootKey(passcode, keyParams);
        const valid = await this.validateWrappingKey(key);
        if (valid) {
            return { valid, artifacts: { wrappingKey: key } };
        }
        else {
            return { valid: false };
        }
    }
    /**
     * We know a wrappingKey is correct if it correctly decrypts
     * wrapped root key.
     */
    async validateWrappingKey(wrappingKey) {
        const wrappedRootKey = this.getWrappedRootKey();
        /** If wrapper only, storage is encrypted directly with wrappingKey */
        if (this.keyMode === encryption_1.KeyMode.WrapperOnly) {
            return this.storageService.canDecryptWithKey(wrappingKey);
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyOnly || this.keyMode === encryption_1.KeyMode.RootKeyPlusWrapper) {
            /**
             * In these modes, storage is encrypted with account keys, and
             * account keys are encrypted with wrappingKey. Here we validate
             * by attempting to decrypt account keys.
             */
            const wrappedKeyPayload = new models_1.EncryptedPayload(wrappedRootKey);
            const decrypted = await this.decryptPayload(wrappedKeyPayload, wrappingKey);
            return !(0, encryption_1.isErrorDecryptingParameters)(decrypted);
        }
        else {
            throw 'Unhandled case in validateWrappingKey';
        }
    }
    async recomputeAccountKeyParams() {
        const rawKeyParams = await this.storageService.getValue(StorageKeys_1.StorageKey.RootKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        if (!rawKeyParams) {
            return;
        }
        this.memoizedRootKeyParams = (0, encryption_1.CreateAnyKeyParams)(rawKeyParams);
        return this.memoizedRootKeyParams;
    }
    /**
     * Wraps the current in-memory root key value using the wrappingKey,
     * then persists the wrapped value to disk.
     */
    async wrapAndPersistRootKey(wrappingKey) {
        const rootKey = this.getSureRootKey();
        const value = {
            ...rootKey.payload.ejected(),
            content: (0, models_1.FillItemContentSpecialized)(rootKey.persistableValueWhenWrapping()),
        };
        const payload = new models_1.DecryptedPayload(value);
        const wrappedKey = await this.encryptPayload(payload, wrappingKey);
        const wrappedKeyPayload = new models_1.EncryptedPayload({
            ...payload.ejected(),
            ...wrappedKey,
            errorDecrypting: false,
            waitingForKey: false,
        });
        this.storageService.setValue(StorageKeys_1.StorageKey.WrappedRootKey, wrappedKeyPayload.ejected(), StorageTypes_1.StorageValueModes.Nonwrapped);
    }
    async unwrapRootKey(wrappingKey) {
        if (this.keyMode === encryption_1.KeyMode.WrapperOnly) {
            this.setRootKeyInstance(wrappingKey);
            return;
        }
        if (this.keyMode !== encryption_1.KeyMode.RootKeyPlusWrapper) {
            throw 'Invalid key mode condition for unwrapping.';
        }
        const wrappedKey = this.getWrappedRootKey();
        const payload = new models_1.EncryptedPayload(wrappedKey);
        const decrypted = await this.decryptPayload(payload, wrappingKey);
        if ((0, encryption_1.isErrorDecryptingParameters)(decrypted)) {
            throw Error('Unable to decrypt root key with provided wrapping key.');
        }
        else {
            const decryptedPayload = new models_1.DecryptedPayload({
                ...payload.ejected(),
                ...decrypted,
            });
            this.setRootKeyInstance(new encryption_1.SNRootKey(decryptedPayload));
            await this.handleKeyStatusChange();
        }
    }
    /**
     * Encrypts rootKey and saves it in storage instead of keychain, and then
     * clears keychain. This is because we don't want to store large encrypted
     * payloads in the keychain. If the root key is not wrapped, it is stored
     * in plain form in the user's secure keychain.
     */
    async setNewRootKeyWrapper(wrappingKey) {
        if (this.keyMode === encryption_1.KeyMode.RootKeyNone) {
            this.keyMode = encryption_1.KeyMode.WrapperOnly;
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyOnly) {
            this.keyMode = encryption_1.KeyMode.RootKeyPlusWrapper;
        }
        else {
            throw Error('Attempting to set wrapper on already wrapped key.');
        }
        await this.deviceInterface.clearNamespacedKeychainValue(this.identifier);
        if (this.keyMode === encryption_1.KeyMode.WrapperOnly || this.keyMode === encryption_1.KeyMode.RootKeyPlusWrapper) {
            if (this.keyMode === encryption_1.KeyMode.WrapperOnly) {
                this.setRootKeyInstance(wrappingKey);
                await this.reencryptItemsKeys();
            }
            else {
                await this.wrapAndPersistRootKey(wrappingKey);
            }
            this.storageService.setValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, wrappingKey.keyParams.getPortableValue(), StorageTypes_1.StorageValueModes.Nonwrapped);
            await this.handleKeyStatusChange();
        }
        else {
            throw Error('Invalid keyMode on setNewRootKeyWrapper');
        }
    }
    /**
     * Removes root key wrapper from local storage and stores root key bare in secure keychain.
     */
    async removeRootKeyWrapper() {
        if (this.keyMode !== encryption_1.KeyMode.WrapperOnly && this.keyMode !== encryption_1.KeyMode.RootKeyPlusWrapper) {
            throw Error('Attempting to remove root key wrapper on unwrapped key.');
        }
        if (this.keyMode === encryption_1.KeyMode.WrapperOnly) {
            this.keyMode = encryption_1.KeyMode.RootKeyNone;
            this.setRootKeyInstance(undefined);
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyPlusWrapper) {
            this.keyMode = encryption_1.KeyMode.RootKeyOnly;
        }
        await this.storageService.removeValue(StorageKeys_1.StorageKey.WrappedRootKey, StorageTypes_1.StorageValueModes.Nonwrapped);
        await this.storageService.removeValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        if (this.keyMode === encryption_1.KeyMode.RootKeyOnly) {
            await this.saveRootKeyToKeychain();
        }
        await this.handleKeyStatusChange();
    }
    async setRootKey(key, wrappingKey) {
        if (!key.keyParams) {
            throw Error('keyParams must be supplied if setting root key.');
        }
        if (this.getRootKey() === key) {
            throw Error('Attempting to set root key as same current value.');
        }
        if (this.keyMode === encryption_1.KeyMode.WrapperOnly) {
            this.keyMode = encryption_1.KeyMode.RootKeyPlusWrapper;
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyNone) {
            this.keyMode = encryption_1.KeyMode.RootKeyOnly;
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyOnly || this.keyMode === encryption_1.KeyMode.RootKeyPlusWrapper) {
            /** Root key is simply changing, mode stays the same */
            /** this.keyMode = this.keyMode; */
        }
        else {
            throw Error(`Unhandled key mode for setNewRootKey ${this.keyMode}`);
        }
        this.setRootKeyInstance(key);
        this.storageService.setValue(StorageKeys_1.StorageKey.RootKeyParams, key.keyParams.getPortableValue(), StorageTypes_1.StorageValueModes.Nonwrapped);
        if (this.keyMode === encryption_1.KeyMode.RootKeyOnly) {
            await this.saveRootKeyToKeychain();
        }
        else if (this.keyMode === encryption_1.KeyMode.RootKeyPlusWrapper) {
            if (!wrappingKey) {
                throw Error('wrappingKey must be supplied');
            }
            await this.wrapAndPersistRootKey(wrappingKey);
        }
        await this.handleKeyStatusChange();
    }
    /**
     * Deletes root key and wrapper from keychain. Used when signing out of application.
     */
    async deleteWorkspaceSpecificKeyStateFromDevice() {
        await this.deviceInterface.clearNamespacedKeychainValue(this.identifier);
        await this.storageService.removeValue(StorageKeys_1.StorageKey.WrappedRootKey, StorageTypes_1.StorageValueModes.Nonwrapped);
        await this.storageService.removeValue(StorageKeys_1.StorageKey.RootKeyWrapperKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        await this.storageService.removeValue(StorageKeys_1.StorageKey.RootKeyParams, StorageTypes_1.StorageValueModes.Nonwrapped);
        this.keyMode = encryption_1.KeyMode.RootKeyNone;
        this.setRootKeyInstance(undefined);
        await this.handleKeyStatusChange();
    }
    getWrappedRootKey() {
        return this.storageService.getValue(StorageKeys_1.StorageKey.WrappedRootKey, StorageTypes_1.StorageValueModes.Nonwrapped);
    }
    setRootKeyInstance(rootKey) {
        this.rootKey = rootKey;
    }
    getRootKey() {
        return this.rootKey;
    }
    getSureRootKey() {
        return this.rootKey;
    }
    getItemsKeys() {
        return this.itemManager.getDisplayableItemsKeys();
    }
    async encrypPayloadWithKeyLookup(payload) {
        const key = this.getRootKey();
        if (key == undefined) {
            throw Error('Attempting root key encryption with no root key');
        }
        return this.encryptPayload(payload, key);
    }
    async encryptPayloadsWithKeyLookup(payloads) {
        return Promise.all(payloads.map((payload) => this.encrypPayloadWithKeyLookup(payload)));
    }
    async encryptPayload(payload, key) {
        return (0, encryption_1.encryptPayload)(payload, key, this.operatorManager);
    }
    async encryptPayloads(payloads, key) {
        return Promise.all(payloads.map((payload) => this.encryptPayload(payload, key)));
    }
    async decryptPayloadWithKeyLookup(payload) {
        const key = this.getRootKey();
        if (key == undefined) {
            return {
                uuid: payload.uuid,
                errorDecrypting: true,
                waitingForKey: true,
            };
        }
        return this.decryptPayload(payload, key);
    }
    async decryptPayload(payload, key) {
        return (0, encryption_1.decryptPayload)(payload, key, this.operatorManager);
    }
    async decryptPayloadsWithKeyLookup(payloads) {
        return Promise.all(payloads.map((payload) => this.decryptPayloadWithKeyLookup(payload)));
    }
    async decryptPayloads(payloads, key) {
        return Promise.all(payloads.map((payload) => this.decryptPayload(payload, key)));
    }
    /**
     * When the root key changes (non-null only), we must re-encrypt all items
     * keys with this new root key (by simply re-syncing).
     */
    async reencryptItemsKeys() {
        const itemsKeys = this.getItemsKeys();
        if (itemsKeys.length > 0) {
            /**
             * Do not call sync after marking dirty.
             * Re-encrypting items keys is called by consumers who have specific flows who
             * will sync on their own timing
             */
            await this.itemManager.setItemsDirty(itemsKeys);
        }
    }
    /**
     * Creates a new random items key to use for item encryption, and adds it to model management.
     * Consumer must call sync. If the protocol version <= 003, only one items key should be created,
     * and its .itemsKey value should be equal to the root key masterKey value.
     */
    async createNewDefaultItemsKey() {
        const rootKey = this.getSureRootKey();
        const operatorVersion = rootKey ? rootKey.keyVersion : common_1.ProtocolVersionLatest;
        let itemTemplate;
        if ((0, common_1.compareVersions)(operatorVersion, common_1.ProtocolVersionLastNonrootItemsKey) <= 0) {
            /** Create root key based items key */
            const payload = new models_1.DecryptedPayload({
                uuid: utils_1.UuidGenerator.GenerateUuid(),
                content_type: common_1.ContentType.ItemsKey,
                content: (0, models_1.FillItemContentSpecialized)({
                    itemsKey: rootKey.masterKey,
                    dataAuthenticationKey: rootKey.dataAuthenticationKey,
                    version: operatorVersion,
                }),
                ...(0, models_1.PayloadTimestampDefaults)(),
            });
            itemTemplate = (0, models_1.CreateDecryptedItemFromPayload)(payload);
        }
        else {
            /** Create independent items key */
            itemTemplate = this.operatorManager.operatorForVersion(operatorVersion).createItemsKey();
        }
        const itemsKeys = this.getItemsKeys();
        const defaultKeys = itemsKeys.filter((key) => {
            return key.isDefault;
        });
        for (const key of defaultKeys) {
            await this.itemManager.changeItemsKey(key, (mutator) => {
                mutator.isDefault = false;
            });
        }
        const itemsKey = (await this.itemManager.insertItem(itemTemplate));
        await this.itemManager.changeItemsKey(itemsKey, (mutator) => {
            mutator.isDefault = true;
        });
        return itemsKey;
    }
    async createNewItemsKeyWithRollback() {
        const currentDefaultItemsKey = (0, encryption_1.findDefaultItemsKey)(this.getItemsKeys());
        const newDefaultItemsKey = await this.createNewDefaultItemsKey();
        const rollback = async () => {
            await this.itemManager.setItemToBeDeleted(newDefaultItemsKey);
            if (currentDefaultItemsKey) {
                await this.itemManager.changeItem(currentDefaultItemsKey, (mutator) => {
                    mutator.isDefault = true;
                });
            }
        };
        return rollback;
    }
    async getDiagnostics() {
        return {
            rootKeyEncryption: {
                hasRootKey: this.rootKey != undefined,
                keyMode: encryption_1.KeyMode[this.keyMode],
                hasRootKeyWrapper: await this.hasRootKeyWrapper(),
                hasAccount: this.hasAccount(),
                hasRootKeyEncryptionSource: this.hasRootKeyEncryptionSource(),
                hasPasscode: this.hasPasscode(),
                getEncryptionSourceVersion: this.hasRootKeyEncryptionSource() && (await this.getEncryptionSourceVersion()),
                getUserVersion: this.getUserVersion(),
            },
        };
    }
}
exports.RootKeyEncryptionService = RootKeyEncryptionService;
