"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PackageProvider = void 0;
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
class PackageProvider {
    static async load(application) {
        var _a, _b;
        const response = await application.getAvailableSubscriptions();
        if (!response || response instanceof snjs_1.ClientDisplayableError) {
            return undefined;
        }
        const versionMap = new Map();
        collectFeatures((_a = response.PLUS_PLAN) === null || _a === void 0 ? void 0 : _a.features, versionMap);
        collectFeatures((_b = response.PRO_PLAN) === null || _b === void 0 ? void 0 : _b.features, versionMap);
        return new PackageProvider(versionMap);
    }
    constructor(latestVersionsMap) {
        this.latestVersionsMap = latestVersionsMap;
        (0, mobx_1.makeAutoObservable)(this, {
            latestVersionsMap: mobx_1.observable.ref,
        });
    }
    getVersion(extension) {
        return this.latestVersionsMap.get(extension.package_info.identifier);
    }
}
exports.PackageProvider = PackageProvider;
function collectFeatures(features, versionMap) {
    if (features == undefined) {
        return;
    }
    for (const feature of features) {
        versionMap.set(feature.identifier, 'Latest');
    }
}
