"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackupFileType = void 0;
var BackupFileType;
(function (BackupFileType) {
    BackupFileType["Encrypted"] = "Encrypted";
    /**
     * Generated when an export is made from an application with no account and no passcode. The
     * items are encrypted, but the items keys are not.
     */
    BackupFileType["EncryptedWithNonEncryptedItemsKey"] = "EncryptedWithNonEncryptedItemsKey";
    BackupFileType["FullyDecrypted"] = "FullyDecrypted";
    BackupFileType["Corrupt"] = "Corrupt";
})(BackupFileType = exports.BackupFileType || (exports.BackupFileType = {}));
