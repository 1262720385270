"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValueModesKeys = exports.StorageValueModes = exports.StoragePersistencePolicies = void 0;
/* istanbul ignore file */
var StoragePersistencePolicies;
(function (StoragePersistencePolicies) {
    StoragePersistencePolicies[StoragePersistencePolicies["Default"] = 1] = "Default";
    StoragePersistencePolicies[StoragePersistencePolicies["Ephemeral"] = 2] = "Ephemeral";
})(StoragePersistencePolicies = exports.StoragePersistencePolicies || (exports.StoragePersistencePolicies = {}));
var StorageValueModes;
(function (StorageValueModes) {
    /** Stored inside wrapped encrypted storage object */
    StorageValueModes[StorageValueModes["Default"] = 1] = "Default";
    /** Stored outside storage object, unencrypted */
    StorageValueModes[StorageValueModes["Nonwrapped"] = 2] = "Nonwrapped";
})(StorageValueModes = exports.StorageValueModes || (exports.StorageValueModes = {}));
var ValueModesKeys;
(function (ValueModesKeys) {
    /* Is encrypted */
    ValueModesKeys["Wrapped"] = "wrapped";
    /* Is decrypted */
    ValueModesKeys["Unwrapped"] = "unwrapped";
    /* Lives outside of wrapped/unwrapped */
    ValueModesKeys["Nonwrapped"] = "nonwrapped";
})(ValueModesKeys = exports.ValueModesKeys || (exports.ValueModesKeys = {}));
