"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FillEditorComponentDefaults = void 0;
const ComponentAction_1 = require("../../Component/ComponentAction");
const common_1 = require("@standardnotes/common");
const ComponentArea_1 = require("../../Component/ComponentArea");
function FillEditorComponentDefaults(component) {
    if (!component.index_path) {
        component.index_path = 'dist/index.html';
    }
    if (!component.component_permissions) {
        component.component_permissions = [
            {
                name: ComponentAction_1.ComponentAction.StreamContextItem,
                content_types: [common_1.ContentType.Note],
            },
        ];
    }
    component.content_type = common_1.ContentType.Component;
    if (!component.area) {
        component.area = ComponentArea_1.ComponentArea.Editor;
    }
    if (component.interchangeable == undefined) {
        component.interchangeable = true;
    }
    return component;
}
exports.FillEditorComponentDefaults = FillEditorComponentDefaults;
