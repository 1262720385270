"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaOutOfSync = void 0;
const Payload_1 = require("../../Abstract/Payload");
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
const PayloadContentsEqual_1 = require("../../Utilities/Payload/PayloadContentsEqual");
const Conflict_1 = require("./Conflict");
const common_1 = require("@standardnotes/common");
const ItemsKeyDelta_1 = require("./ItemsKeyDelta");
const ApplyDirtyState_1 = require("./Utilities/ApplyDirtyState");
const DeltaEmit_1 = require("./Abstract/DeltaEmit");
class DeltaOutOfSync {
    constructor(baseCollection, applyCollection, historyMap) {
        this.baseCollection = baseCollection;
        this.applyCollection = applyCollection;
        this.historyMap = historyMap;
    }
    result() {
        const result = {
            emits: [],
            ignored: [],
            source: Payload_1.PayloadEmitSource.RemoteRetrieved,
        };
        for (const apply of this.applyCollection.all()) {
            if (apply.content_type === common_1.ContentType.ItemsKey) {
                const itemsKeyDeltaEmit = new ItemsKeyDelta_1.ItemsKeyDelta(this.baseCollection, [apply]).result();
                (0, DeltaEmit_1.extendSyncDelta)(result, itemsKeyDeltaEmit);
                continue;
            }
            const base = this.baseCollection.find(apply.uuid);
            if (!base) {
                result.emits.push((0, ApplyDirtyState_1.payloadByFinalizingSyncState)(apply, this.baseCollection));
                continue;
            }
            const isBaseDecrypted = (0, TypeCheck_1.isDecryptedPayload)(base);
            const isApplyDecrypted = (0, TypeCheck_1.isDecryptedPayload)(apply);
            const needsConflict = isApplyDecrypted !== isBaseDecrypted ||
                (isApplyDecrypted && isBaseDecrypted && !(0, PayloadContentsEqual_1.PayloadContentsEqual)(apply, base));
            if (needsConflict) {
                const delta = new Conflict_1.ConflictDelta(this.baseCollection, base, apply, this.historyMap);
                (0, DeltaEmit_1.extendSyncDelta)(result, delta.result());
            }
            else {
                result.emits.push((0, ApplyDirtyState_1.payloadByFinalizingSyncState)(apply, this.baseCollection));
            }
        }
        return result;
    }
}
exports.DeltaOutOfSync = DeltaOutOfSync;
