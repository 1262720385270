"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePaneSwipeGesture = void 0;
const useStateRef_1 = require("@/Hooks/useStateRef");
const react_1 = require("react");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const ApplicationProvider_1 = require("../ApplicationProvider");
const snjs_1 = require("@standardnotes/snjs");
const PrefDefaults_1 = require("@/Constants/PrefDefaults");
function getScrollParent(node) {
    if (!node) {
        return null;
    }
    if (node.scrollHeight > node.clientHeight || node.scrollWidth > node.clientWidth) {
        return node;
    }
    else {
        return getScrollParent(node.parentElement);
    }
}
const supportsPassive = (() => {
    let supportsPassive = false;
    try {
        const opts = Object.defineProperty({}, 'passive', {
            get: () => {
                supportsPassive = true;
            },
        });
        window.addEventListener('test', null, opts);
        window.removeEventListener('test', null, opts);
    }
    catch (e) {
        /* empty */
    }
    return supportsPassive;
})();
const usePaneSwipeGesture = (direction, onSwipeEnd, options) => {
    const { gesture = 'pan', requiresStartFromEdge = true } = options || {};
    const application = (0, ApplicationProvider_1.useApplication)();
    const underlayElementRef = (0, react_1.useRef)(null);
    const [element, setElement] = (0, react_1.useState)(null);
    const onSwipeEndRef = (0, useStateRef_1.useStateRef)(onSwipeEnd);
    const isMobileScreen = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm);
    const prefersReducedMotion = (0, useMediaQuery_1.useMediaQuery)('(prefers-reduced-motion: reduce)');
    const adjustedGesture = gesture === 'pan' && prefersReducedMotion ? 'swipe' : gesture;
    const [isEnabled, setIsEnabled] = (0, react_1.useState)(() => application.getPreference(snjs_1.PrefKey.PaneGesturesEnabled, PrefDefaults_1.PrefDefaults[snjs_1.PrefKey.PaneGesturesEnabled]));
    (0, react_1.useEffect)(() => {
        return application.addSingleEventObserver(snjs_1.ApplicationEvent.PreferencesChanged, async () => {
            setIsEnabled(application.getPreference(snjs_1.PrefKey.PaneGesturesEnabled, PrefDefaults_1.PrefDefaults[snjs_1.PrefKey.PaneGesturesEnabled]));
        });
    }, [application]);
    (0, react_1.useEffect)(() => {
        var _a;
        if (!element) {
            return;
        }
        if (!isMobileScreen) {
            return;
        }
        if (!isEnabled) {
            return;
        }
        underlayElementRef.current = ((_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector(`[data-pane-underlay="${element.id}"]`)) || null;
        let startX = 0;
        let clientX = 0;
        let closestScrollContainer;
        let scrollContainerAxis = null;
        let canceled = false;
        const TouchMoveThreshold = 25;
        const TouchStartThreshold = direction === 'right' ? 25 : window.innerWidth - 25;
        const SwipeFinishThreshold = window.innerWidth / 2.5;
        const scrollListener = (event) => {
            canceled = true;
            setTimeout(() => {
                if (event.target.style.overflowY === 'hidden') {
                    canceled = false;
                }
            }, 5);
        };
        const touchStartListener = (event) => {
            scrollContainerAxis = null;
            canceled = false;
            const touch = event.touches[0];
            startX = touch.clientX;
            const isStartOutOfThreshold = (direction === 'right' && startX > TouchStartThreshold) ||
                (direction === 'left' && startX < TouchStartThreshold);
            if (isStartOutOfThreshold && requiresStartFromEdge) {
                canceled = true;
                return;
            }
            closestScrollContainer = getScrollParent(event.target);
            if (closestScrollContainer) {
                closestScrollContainer.addEventListener('scroll', scrollListener, supportsPassive ? { passive: true } : false);
                if (closestScrollContainer.scrollWidth > closestScrollContainer.clientWidth) {
                    scrollContainerAxis = 'x';
                }
            }
            element.style.willChange = 'transform';
        };
        const updateElement = (x) => {
            if (!underlayElementRef.current) {
                const underlayElement = document.createElement('div');
                underlayElement.style.position = 'fixed';
                underlayElement.style.top = '0';
                underlayElement.style.left = '0';
                underlayElement.style.width = '100%';
                underlayElement.style.height = '100%';
                underlayElement.style.pointerEvents = 'none';
                if (adjustedGesture === 'pan') {
                    underlayElement.style.backgroundColor = '#000';
                }
                else {
                    underlayElement.style.background =
                        direction === 'right'
                            ? 'linear-gradient(to right, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))'
                            : 'linear-gradient(to left, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))';
                }
                underlayElement.style.opacity = '0';
                underlayElement.style.willChange = 'opacity';
                underlayElement.setAttribute('role', 'presentation');
                underlayElement.ariaHidden = 'true';
                underlayElement.setAttribute('data-pane-underlay', element.id);
                if (adjustedGesture === 'pan') {
                    element.before(underlayElement);
                }
                else {
                    element.after(underlayElement);
                }
                underlayElementRef.current = underlayElement;
            }
            if (adjustedGesture === 'pan') {
                element.animate([
                    {
                        transform: `translate3d(${x}px, 0, 0)`,
                    },
                ], {
                    duration: 0,
                    fill: 'forwards',
                });
            }
            const percent = adjustedGesture === 'pan'
                ? Math.min(window.innerWidth / Math.abs(x) / 10, 0.65)
                : Math.min(Math.abs(x) / 100, 0.65);
            underlayElementRef.current.animate([{ opacity: percent }], {
                duration: 0,
                fill: 'forwards',
            });
        };
        const touchMoveListener = (event) => {
            if (scrollContainerAxis === 'x') {
                return;
            }
            if (canceled) {
                return;
            }
            const touch = event.touches[0];
            clientX = touch.clientX;
            const deltaX = clientX - startX;
            if (Math.abs(deltaX) < TouchMoveThreshold) {
                return;
            }
            if (closestScrollContainer && closestScrollContainer.style.overflowY !== 'hidden') {
                closestScrollContainer.style.overflowY = 'hidden';
            }
            if (document.activeElement) {
                ;
                document.activeElement.blur();
            }
            if (adjustedGesture === 'pan') {
                const x = direction === 'right' ? Math.max(deltaX - TouchMoveThreshold, 0) : Math.min(deltaX + TouchMoveThreshold, 0);
                updateElement(x);
            }
            else {
                const x = direction === 'right' ? Math.max(deltaX, 0) : Math.min(deltaX, 0);
                updateElement(x);
            }
        };
        const disposeUnderlay = () => {
            if (!underlayElementRef.current) {
                return;
            }
            underlayElementRef.current
                .animate([{ opacity: 0 }], {
                easing: 'cubic-bezier(.36,.66,.04,1)',
                duration: 500,
                fill: 'forwards',
            })
                .finished.then(() => {
                if (underlayElementRef.current) {
                    underlayElementRef.current.remove();
                    underlayElementRef.current = null;
                }
            })
                .catch(console.error);
        };
        const touchEndListener = () => {
            if (closestScrollContainer) {
                closestScrollContainer.removeEventListener('scroll', scrollListener);
                closestScrollContainer.style.overflowY = '';
            }
            if (canceled) {
                updateElement(0);
                disposeUnderlay();
                return;
            }
            const deltaX = clientX - startX;
            element.style.willChange = '';
            if ((direction === 'right' && deltaX > SwipeFinishThreshold) ||
                (direction === 'left' && deltaX < -SwipeFinishThreshold)) {
                onSwipeEndRef.current(element);
            }
            else {
                updateElement(0);
            }
            disposeUnderlay();
        };
        element.addEventListener('touchstart', touchStartListener, supportsPassive ? { passive: true } : false);
        element.addEventListener('touchmove', touchMoveListener, supportsPassive ? { passive: true } : false);
        element.addEventListener('touchend', touchEndListener, supportsPassive ? { passive: true } : false);
        return () => {
            element.removeEventListener('touchstart', touchStartListener);
            element.removeEventListener('touchmove', touchMoveListener);
            element.removeEventListener('touchend', touchEndListener);
            disposeUnderlay();
        };
    }, [direction, element, isMobileScreen, onSwipeEndRef, isEnabled, adjustedGesture, requiresStartFromEdge]);
    return [setElement];
};
exports.usePaneSwipeGesture = usePaneSwipeGesture;
