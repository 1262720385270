"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemMutator = void 0;
const MutationType_1 = require("../Types/MutationType");
const DirtyCounter_1 = require("../../../Runtime/DirtyCounter/DirtyCounter");
/**
 * An item mutator takes in an item, and an operation, and returns the resulting payload.
 * Subclasses of mutators can modify the content field directly, but cannot modify the payload directly.
 * All changes to the payload must occur by copying the payload and reassigning its value.
 */
class ItemMutator {
    constructor(item, type) {
        this.immutableItem = item;
        this.type = type;
        this.immutablePayload = item.payload;
    }
    getUuid() {
        return this.immutablePayload.uuid;
    }
    getItem() {
        return this.immutableItem;
    }
    getResult() {
        if (this.type === MutationType_1.MutationType.NonDirtying) {
            return this.immutablePayload.copy();
        }
        const result = this.immutablePayload.copy({
            dirty: true,
            dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
        });
        return result;
    }
    setBeginSync(began, globalDirtyIndex) {
        this.immutablePayload = this.immutablePayload.copy({
            lastSyncBegan: began,
            globalDirtyIndexAtLastSync: globalDirtyIndex,
        });
    }
    set errorDecrypting(_) {
        throw Error('This method is no longer implemented');
    }
    set updated_at(_) {
        throw Error('This method is no longer implemented');
    }
    set updated_at_timestamp(_) {
        throw Error('This method is no longer implemented');
    }
}
exports.ItemMutator = ItemMutator;
