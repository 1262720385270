"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCustomViewportHeight = void 0;
const Logging_1 = require("./Logging");
/**
 * @param forceTriggerResizeEvent On iPad at least, setProperty(ViewportHeightKey) does not trigger a resize event
 */
const setCustomViewportHeight = (height, suffix, forceTriggerResizeEvent = false) => {
    const value = `${height}${suffix}`;
    (0, Logging_1.log)(Logging_1.LoggingDomain.Viewport, `setCustomViewportHeight: ${value}`);
    document.body.style.height = value;
    document.documentElement.style.setProperty('--ios-viewport-height', value);
    if (forceTriggerResizeEvent) {
        window.dispatchEvent(new Event('resize'));
    }
};
exports.setCustomViewportHeight = setCustomViewportHeight;
