"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCorruptTransferPayload = exports.isDeletedTransferPayload = exports.isErrorDecryptingTransferPayload = exports.isEncryptedTransferPayload = exports.isDecryptedTransferPayload = void 0;
const common_1 = require("@standardnotes/common");
const utils_1 = require("@standardnotes/utils");
function isDecryptedTransferPayload(payload) {
    return (0, utils_1.isObject)(payload.content);
}
exports.isDecryptedTransferPayload = isDecryptedTransferPayload;
function isEncryptedTransferPayload(payload) {
    return 'content' in payload && (0, utils_1.isString)(payload.content);
}
exports.isEncryptedTransferPayload = isEncryptedTransferPayload;
function isErrorDecryptingTransferPayload(payload) {
    return isEncryptedTransferPayload(payload) && payload.errorDecrypting === true;
}
exports.isErrorDecryptingTransferPayload = isErrorDecryptingTransferPayload;
function isDeletedTransferPayload(payload) {
    return 'deleted' in payload && payload.deleted === true;
}
exports.isDeletedTransferPayload = isDeletedTransferPayload;
function isCorruptTransferPayload(payload) {
    const invalidDeletedState = payload.deleted === true && payload.content != undefined;
    return payload.uuid == undefined || invalidDeletedState || payload.content_type === common_1.ContentType.Unknown;
}
exports.isCorruptTransferPayload = isCorruptTransferPayload;
