"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MomentsService = void 0;
const toast_1 = require("@standardnotes/toast");
const services_1 = require("@standardnotes/services");
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("@/Controllers/Abstract/AbstractViewController");
const DateUtils_1 = require("@/Utils/DateUtils");
const PhotoRecorder_1 = require("./PhotoRecorder");
const EVERY_HOUR = 1000 * 60 * 60;
const EVERY_TEN_SECONDS = 1000 * 10;
const DEBUG_MODE = Utils_1.isDev && false;
const DELAY_AFTER_STARTING_CAMERA_TO_ALLOW_MOBILE_AUTOFOCUS = 2000;
class MomentsService extends AbstractViewController_1.AbstractViewController {
    constructor(application, filesController, eventBus) {
        super(application, eventBus);
        this.filesController = filesController;
        this.isEnabled = false;
        this.enableMoments = () => {
            this.application.setValue(services_1.StorageKey.MomentsEnabled, true);
            this.isEnabled = true;
            void this.beginTakingPhotos();
        };
        this.disableMoments = () => {
            this.application.setValue(services_1.StorageKey.MomentsEnabled, false);
            this.isEnabled = false;
            clearInterval(this.intervalReference);
        };
        this.takePhoto = async () => {
            const isAppLocked = await this.application.isLocked();
            if (isAppLocked) {
                return;
            }
            const isAppInForeground = document.visibilityState === 'visible';
            let toastId;
            if (isAppInForeground) {
                toastId = (0, toast_1.addToast)({
                    type: toast_1.ToastType.Regular,
                    message: 'Capturing Moment...',
                    pauseOnWindowBlur: false,
                });
            }
            if (this.application.desktopDevice) {
                const granted = await this.application.desktopDevice.askForMediaAccess('camera');
                if (!granted) {
                    if (toastId) {
                        (0, toast_1.dismissToast)(toastId);
                    }
                    (0, toast_1.addToast)({
                        type: toast_1.ToastType.Error,
                        message: 'Please enable Camera permissions for Standard Notes to enable Moments.',
                        duration: 3000,
                    });
                    return;
                }
            }
            const filename = `Moment ${(0, DateUtils_1.dateToStringStyle1)(new Date())}.png`;
            const camera = new PhotoRecorder_1.PhotoRecorder();
            await camera.initialize();
            if (this.application.isMobileDevice) {
                await (0, snjs_1.sleep)(DELAY_AFTER_STARTING_CAMERA_TO_ALLOW_MOBILE_AUTOFOCUS);
            }
            let file = await camera.takePhoto(filename);
            if (!file) {
                await (0, snjs_1.sleep)(1000);
                file = await camera.takePhoto(filename);
                if (!file) {
                    return undefined;
                }
            }
            if (toastId) {
                (0, toast_1.dismissToast)(toastId);
            }
            const uploadedFile = await this.filesController.uploadNewFile(file);
            if (uploadedFile) {
                if (isAppInForeground) {
                    void this.application.linkingController.linkItemToSelectedItem(uploadedFile);
                }
                const defaultTag = this.getDefaultTag();
                if (defaultTag) {
                    void this.application.linkingController.linkItems(uploadedFile, defaultTag);
                }
            }
            camera.finish();
            return uploadedFile;
        };
        this.disposers.push(application.addEventObserver(async () => {
            var _a;
            this.isEnabled = (_a = this.application.getValue(services_1.StorageKey.MomentsEnabled)) !== null && _a !== void 0 ? _a : false;
            if (this.isEnabled) {
                void this.beginTakingPhotos();
            }
        }, services_1.ApplicationEvent.LocalDataLoaded), application.addEventObserver(async () => {
            this.pauseMoments();
        }, services_1.ApplicationEvent.BiometricsSoftLockEngaged), application.addEventObserver(async () => {
            this.resumeMoments();
        }, services_1.ApplicationEvent.BiometricsSoftLockDisengaged));
        (0, mobx_1.makeObservable)(this, {
            isEnabled: mobx_1.observable,
            enableMoments: mobx_1.action,
            disableMoments: mobx_1.action,
        });
    }
    deinit() {
        super.deinit();
        this.application = undefined;
        this.filesController = undefined;
    }
    pauseMoments() {
        clearInterval(this.intervalReference);
    }
    resumeMoments() {
        if (!this.isEnabled) {
            return;
        }
        void this.beginTakingPhotos();
    }
    beginTakingPhotos() {
        if (this.intervalReference) {
            clearInterval(this.intervalReference);
        }
        void this.takePhoto();
        this.intervalReference = setInterval(() => {
            void this.takePhoto();
        }, DEBUG_MODE ? EVERY_TEN_SECONDS : EVERY_HOUR);
    }
    getDefaultTag() {
        const defaultTagId = this.application.getPreference(snjs_1.PrefKey.MomentsDefaultTagUuid);
        if (defaultTagId) {
            return this.application.items.findItem(defaultTagId);
        }
    }
}
exports.MomentsService = MomentsService;
