"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorTag = void 0;
var ErrorTag;
(function (ErrorTag) {
    ErrorTag["MfaInvalid"] = "mfa-invalid";
    ErrorTag["MfaRequired"] = "mfa-required";
    ErrorTag["U2FRequired"] = "u2f-required";
    ErrorTag["RefreshTokenInvalid"] = "invalid-refresh-token";
    ErrorTag["RefreshTokenExpired"] = "expired-refresh-token";
    ErrorTag["AccessTokenExpired"] = "expired-access-token";
    ErrorTag["ParametersInvalid"] = "invalid-parameters";
    ErrorTag["RevokedSession"] = "revoked-session";
    ErrorTag["AuthInvalid"] = "invalid-auth";
    ErrorTag["ReadOnlyAccess"] = "read-only-access";
    ErrorTag["ClientValidationError"] = "client-validation-error";
    ErrorTag["ClientCanceledMfa"] = "client-canceled-mfa";
})(ErrorTag = exports.ErrorTag || (exports.ErrorTag = {}));
