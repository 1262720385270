"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const icons_1 = require("@standardnotes/icons");
const Content_1 = require("@/Components/Preferences/PreferencesComponents/Content");
const snjs_1 = require("@standardnotes/snjs");
const AccordionItem = ({ title, className = '', children }) => {
    const elementRef = (0, react_1.useRef)(null);
    const [isExpanded, setIsExpanded] = (0, react_1.useState)(false);
    return ((0, jsx_runtime_1.jsxs)("div", { className: className, children: [(0, jsx_runtime_1.jsxs)("div", { className: "relative flex cursor-pointer items-center justify-between hover:underline", onClick: () => {
                    setIsExpanded(!isExpanded);
                }, children: [(0, jsx_runtime_1.jsx)(Content_1.Title, { children: title }), (0, jsx_runtime_1.jsx)(icons_1.ArrowDownCheckmarkIcon, { className: (0, snjs_1.classNames)('h-5 w-5 text-info', isExpanded && 'rotate-180') })] }), (0, jsx_runtime_1.jsx)("div", { className: 'accordion-contents-container cursor-auto', "data-is-expanded": isExpanded, ref: elementRef, children: children })] }));
};
exports.default = AccordionItem;
