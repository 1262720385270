"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateDeletedServerSyncPushPayload = exports.CreateEncryptedServerSyncPushPayload = void 0;
function CreateEncryptedServerSyncPushPayload(fromPayload) {
    return {
        content_type: fromPayload.content_type,
        created_at_timestamp: fromPayload.created_at_timestamp,
        created_at: fromPayload.created_at,
        deleted: false,
        duplicate_of: fromPayload.duplicate_of,
        updated_at_timestamp: fromPayload.updated_at_timestamp,
        updated_at: fromPayload.updated_at,
        uuid: fromPayload.uuid,
        content: fromPayload.content,
        enc_item_key: fromPayload.enc_item_key,
        items_key_id: fromPayload.items_key_id,
        auth_hash: fromPayload.auth_hash,
    };
}
exports.CreateEncryptedServerSyncPushPayload = CreateEncryptedServerSyncPushPayload;
function CreateDeletedServerSyncPushPayload(fromPayload) {
    return {
        content_type: fromPayload.content_type,
        created_at_timestamp: fromPayload.created_at_timestamp,
        created_at: fromPayload.created_at,
        deleted: true,
        duplicate_of: fromPayload.duplicate_of,
        updated_at_timestamp: fromPayload.updated_at_timestamp,
        updated_at: fromPayload.updated_at,
        uuid: fromPayload.uuid,
        content: undefined,
    };
}
exports.CreateDeletedServerSyncPushPayload = CreateDeletedServerSyncPushPayload;
