"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("@ariakit/react");
const react_slot_1 = require("@radix-ui/react-slot");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const GetPositionedPopoverStyles_1 = require("../Popover/GetPositionedPopoverStyles");
const StyledTooltip = ({ children, className, label, ...props }) => {
    const tooltip = (0, react_1.useTooltipStore)({
        timeout: 350,
    });
    const isMobile = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm);
    if (isMobile) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_1.TooltipAnchor, { store: tooltip, as: react_slot_1.Slot, children: children }), (0, jsx_runtime_1.jsx)(react_1.Tooltip, { store: tooltip, className: (0, snjs_1.classNames)('z-tooltip max-w-max rounded border border-border bg-contrast py-1.5 px-3 text-sm text-foreground shadow', className), updatePosition: () => {
                    const { popoverElement, anchorElement } = tooltip.getState();
                    const documentElement = document.querySelector('.main-ui-view');
                    if (!popoverElement || !anchorElement || !documentElement) {
                        return;
                    }
                    const anchorRect = anchorElement.getBoundingClientRect();
                    const popoverRect = popoverElement.getBoundingClientRect();
                    const documentRect = documentElement.getBoundingClientRect();
                    const styles = (0, GetPositionedPopoverStyles_1.getPositionedPopoverStyles)({
                        align: 'center',
                        side: 'bottom',
                        anchorRect,
                        popoverRect,
                        documentRect,
                        disableMobileFullscreenTakeover: true,
                        offset: 6,
                    });
                    Object.assign(popoverElement.style, styles);
                }, ...props, children: label })] }));
};
exports.default = StyledTooltip;
