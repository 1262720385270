"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TwoFactorActivation = void 0;
const mobx_1 = require("mobx");
class TwoFactorActivation {
    constructor(mfaProvider, email, _secretKey, _cancelActivation, _enabled2FA) {
        this.mfaProvider = mfaProvider;
        this.email = email;
        this._secretKey = _secretKey;
        this._cancelActivation = _cancelActivation;
        this._enabled2FA = _enabled2FA;
        this.type = 'two-factor-activation';
        this._2FAVerification = 'none';
        this.inputSecretKey = '';
        this.inputOtpToken = '';
        this._activationStep = 'scan-qr-code';
        (0, mobx_1.makeAutoObservable)(this, {
            _secretKey: mobx_1.observable,
            _authCode: mobx_1.observable,
            _step: mobx_1.observable,
            _enable2FAVerification: mobx_1.observable,
            inputOtpToken: mobx_1.observable,
            inputSecretKey: mobx_1.observable,
        }, { autoBind: true });
    }
    get secretKey() {
        return this._secretKey;
    }
    get activationStep() {
        return this._activationStep;
    }
    get verificationStatus() {
        return this._2FAVerification;
    }
    get qrCode() {
        return `otpauth://totp/2FA?secret=${this._secretKey}&issuer=Standard%20Notes&label=${this.email}`;
    }
    cancelActivation() {
        this._cancelActivation();
    }
    openScanQRCode() {
        if (this._activationStep === 'save-secret-key') {
            this._activationStep = 'scan-qr-code';
        }
    }
    openSaveSecretKey() {
        const preconditions = ['scan-qr-code', 'verification'];
        if (preconditions.includes(this._activationStep)) {
            this._activationStep = 'save-secret-key';
        }
    }
    openVerification() {
        this.inputOtpToken = '';
        this.inputSecretKey = '';
        if (this._activationStep === 'save-secret-key') {
            this._activationStep = 'verification';
            this._2FAVerification = 'none';
        }
    }
    openSuccess() {
        if (this._activationStep === 'verification') {
            this._activationStep = 'success';
        }
    }
    setInputSecretKey(secretKey) {
        this.inputSecretKey = secretKey;
    }
    setInputOtpToken(otpToken) {
        this.inputOtpToken = otpToken;
    }
    enable2FA() {
        if (this.inputSecretKey !== this._secretKey) {
            this._2FAVerification = 'invalid-secret';
            return;
        }
        this.mfaProvider
            .enableMfa(this.inputSecretKey, this.inputOtpToken)
            .then((0, mobx_1.action)(() => {
            this._2FAVerification = 'valid';
            this.openSuccess();
        }))
            .catch((0, mobx_1.action)(() => {
            this._2FAVerification = 'invalid-auth-code';
        }));
    }
    finishActivation() {
        if (this._activationStep === 'success') {
            this._enabled2FA();
        }
    }
}
exports.TwoFactorActivation = TwoFactorActivation;
