"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileItem = exports.isFile = void 0;
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const Item_1 = require("../../Abstract/Item");
const common_1 = require("@standardnotes/common");
const isFile = (x) => x.content_type === common_1.ContentType.File;
exports.isFile = isFile;
class FileItem extends DecryptedItem_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.remoteIdentifier = this.content.remoteIdentifier;
        this.name = this.content.name;
        this.key = this.content.key;
        if (this.content.size && this.content.chunkSizes) {
            this.decryptedSize = this.content.size;
            this.encryptedChunkSizes = this.content.chunkSizes;
        }
        else {
            this.decryptedSize = this.content.decryptedSize;
            this.encryptedChunkSizes = this.content.encryptedChunkSizes;
        }
        this.encryptionHeader = this.content.encryptionHeader;
        this.mimeType = this.content.mimeType;
    }
    strategyWhenConflictingWithItem(item) {
        if (item.key !== this.key ||
            item.encryptionHeader !== this.encryptionHeader ||
            item.remoteIdentifier !== this.remoteIdentifier ||
            JSON.stringify(item.encryptedChunkSizes) !== JSON.stringify(this.encryptedChunkSizes)) {
            return Item_1.ConflictStrategy.KeepBaseDuplicateApply;
        }
        return Item_1.ConflictStrategy.KeepBase;
    }
    get encryptedSize() {
        return this.encryptedChunkSizes.reduce((total, chunk) => total + chunk, 0);
    }
    get title() {
        return this.name;
    }
}
exports.FileItem = FileItem;
