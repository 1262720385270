"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.predicateFromDSLString = exports.predicateFromJson = exports.includesPredicateFromArguments = exports.notPredicateFromArguments = exports.compoundPredicateFromArguments = exports.predicateFromArguments = void 0;
const CompoundPredicate_1 = require("./CompoundPredicate");
const IncludesPredicate_1 = require("./IncludesPredicate");
const Interface_1 = require("./Interface");
const NotPredicate_1 = require("./NotPredicate");
const Predicate_1 = require("./Predicate");
function predicateFromArguments(keypath, operator, value) {
    if (Interface_1.AllPredicateCompoundOperators.includes(operator)) {
        return compoundPredicateFromArguments(operator, value);
    }
    else if (operator === 'not') {
        return new NotPredicate_1.NotPredicate(predicateFromJson(value));
    }
    else if (operator === 'includes' && keypath) {
        if (isSureValue(value)) {
            return new Predicate_1.Predicate(keypath, operator, value);
        }
        else {
            return new IncludesPredicate_1.IncludesPredicate(keypath, predicateFromJson(value));
        }
    }
    else if (keypath) {
        return new Predicate_1.Predicate(keypath, operator, value);
    }
    throw Error('Invalid predicate arguments');
}
exports.predicateFromArguments = predicateFromArguments;
function compoundPredicateFromArguments(operator, value) {
    const subPredicates = value.map((jsonPredicate) => {
        return predicateFromJson(jsonPredicate);
    });
    return new CompoundPredicate_1.CompoundPredicate(operator, subPredicates);
}
exports.compoundPredicateFromArguments = compoundPredicateFromArguments;
function notPredicateFromArguments(value) {
    const subPredicate = predicateFromJson(value);
    return new NotPredicate_1.NotPredicate(subPredicate);
}
exports.notPredicateFromArguments = notPredicateFromArguments;
function includesPredicateFromArguments(keypath, value) {
    const subPredicate = predicateFromJson(value);
    return new IncludesPredicate_1.IncludesPredicate(keypath, subPredicate);
}
exports.includesPredicateFromArguments = includesPredicateFromArguments;
function predicateFromJson(values) {
    if (Array.isArray(values)) {
        throw Error('Invalid predicateFromJson value');
    }
    return predicateFromArguments(values.keypath, values.operator, isValuePredicateInArrayForm(values.value)
        ? predicateDSLArrayToJsonPredicate(values.value)
        : values.value);
}
exports.predicateFromJson = predicateFromJson;
function predicateFromDSLString(dsl) {
    try {
        const components = JSON.parse(dsl.substring(1, dsl.length));
        components.shift();
        const predicateJson = predicateDSLArrayToJsonPredicate(components);
        return predicateFromJson(predicateJson);
    }
    catch (e) {
        throw Error(`Invalid smart view syntax ${e}`);
    }
}
exports.predicateFromDSLString = predicateFromDSLString;
function isValuePredicateInArrayForm(value) {
    return Array.isArray(value) && Interface_1.AllPredicateOperators.includes(value[1]);
}
function isSureValue(value) {
    if (Interface_1.SureValueNonObjectTypesAsStrings.includes(typeof value)) {
        return true;
    }
    if (Array.isArray(value)) {
        return !isValuePredicateInArrayForm(value);
    }
    return false;
}
function predicateDSLArrayToJsonPredicate(predicateArray) {
    const predicateValue = predicateArray[2];
    let resolvedPredicateValue;
    if (Array.isArray(predicateValue)) {
        const level1CondensedValue = predicateValue;
        if (Array.isArray(level1CondensedValue[0])) {
            const level2CondensedValue = level1CondensedValue;
            resolvedPredicateValue = level2CondensedValue.map((subPredicate) => predicateDSLArrayToJsonPredicate(subPredicate));
        }
        else if (isValuePredicateInArrayForm(predicateValue[1])) {
            const level2CondensedValue = level1CondensedValue;
            resolvedPredicateValue = predicateDSLArrayToJsonPredicate(level2CondensedValue);
        }
        else {
            const level2CondensedValue = predicateValue;
            resolvedPredicateValue = level2CondensedValue;
        }
    }
    else {
        const level1CondensedValue = predicateValue;
        resolvedPredicateValue = level1CondensedValue;
    }
    return {
        keypath: predicateArray[0],
        operator: predicateArray[1],
        value: resolvedPredicateValue,
    };
}
