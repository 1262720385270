"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebAppEvent = void 0;
var WebAppEvent;
(function (WebAppEvent) {
    WebAppEvent["NewUpdateAvailable"] = "NewUpdateAvailable";
    WebAppEvent["EditorFocused"] = "EditorFocused";
    WebAppEvent["BeganBackupDownload"] = "BeganBackupDownload";
    WebAppEvent["EndedBackupDownload"] = "EndedBackupDownload";
    WebAppEvent["PanelResized"] = "PanelResized";
    WebAppEvent["WindowDidFocus"] = "WindowDidFocus";
    WebAppEvent["WindowDidBlur"] = "WindowDidBlur";
    WebAppEvent["MobileKeyboardDidChangeFrame"] = "MobileKeyboardDidChangeFrame";
    WebAppEvent["MobileKeyboardWillChangeFrame"] = "MobileKeyboardWillChangeFrame";
})(WebAppEvent = exports.WebAppEvent || (exports.WebAppEvent = {}));
