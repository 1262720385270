"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurchaseFlowController = void 0;
const Logging_1 = require("@/Logging");
const PurchaseFlowFunctions_1 = require("@/Components/PurchaseFlow/PurchaseFlowFunctions");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
const PurchaseFlowPane_1 = require("./PurchaseFlowPane");
class PurchaseFlowController extends AbstractViewController_1.AbstractViewController {
    constructor(application, eventBus) {
        super(application, eventBus);
        this.isOpen = false;
        this.currentPane = PurchaseFlowPane_1.PurchaseFlowPane.CreateAccount;
        this.setCurrentPane = (currentPane) => {
            this.currentPane = currentPane;
        };
        this.openPurchaseFlow = async (plan = snjs_1.AppleIAPProductId.ProPlanYearly) => {
            const user = this.application.getUser();
            if (!user) {
                this.isOpen = true;
                return;
            }
            if (this.application.isNativeIOS()) {
                await this.beginIosIapPurchaseFlow(plan);
            }
            else {
                await (0, PurchaseFlowFunctions_1.loadPurchaseFlowUrl)(this.application);
            }
        };
        this.openPurchaseWebpage = () => {
            (0, PurchaseFlowFunctions_1.loadPurchaseFlowUrl)(this.application).catch((err) => {
                console.error(err);
                this.application.alertService.alert(err).catch(console.error);
            });
        };
        this.beginIosIapPurchaseFlow = async (plan) => {
            const result = await this.application.mobileDevice().purchaseSubscriptionIAP(plan);
            (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'BeginIosIapPurchaseFlow result', result);
            if (!result) {
                void this.application.alertService.alert('Your purchase was canceled or failed. Please try again.');
                return;
            }
            const showGenericError = () => {
                void this.application.alertService.alert('There was an error confirming your purchase. Please contact support at help@standardnotes.com.');
            };
            (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'Confirming result with our server');
            const token = await this.application.getNewSubscriptionToken();
            if (!token) {
                (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'Unable to generate subscription token');
                showGenericError();
                return;
            }
            const confirmResult = await this.application.subscriptions.confirmAppleIAP(result, token);
            (0, Logging_1.log)(Logging_1.LoggingDomain.Purchasing, 'Server confirm result', confirmResult);
            if (confirmResult) {
                void this.application.alerts.alert('Please allow a few minutes for your subscription benefits to activate. You will see a confirmation alert in the app when your subscription is ready.', 'Your purchase was successful!');
            }
            else {
                showGenericError();
            }
        };
        this.closePurchaseFlow = () => {
            this.isOpen = false;
        };
        (0, mobx_1.makeObservable)(this, {
            isOpen: mobx_1.observable,
            currentPane: mobx_1.observable,
            setCurrentPane: mobx_1.action,
            openPurchaseFlow: mobx_1.action,
            closePurchaseFlow: mobx_1.action,
        });
    }
}
exports.PurchaseFlowController = PurchaseFlowController;
