"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMutator = void 0;
const common_1 = require("@standardnotes/common");
const ContenteReferenceType_1 = require("../../Abstract/Reference/ContenteReferenceType");
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
class FileMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    set name(newName) {
        this.mutableContent.name = newName;
    }
    set encryptionHeader(encryptionHeader) {
        this.mutableContent.encryptionHeader = encryptionHeader;
    }
    addNote(note) {
        const reference = {
            reference_type: ContenteReferenceType_1.ContentReferenceType.FileToNote,
            content_type: common_1.ContentType.Note,
            uuid: note.uuid,
        };
        const references = this.mutableContent.references || [];
        references.push(reference);
        this.mutableContent.references = references;
    }
    removeNote(note) {
        const references = this.immutableItem.references.filter((ref) => ref.uuid !== note.uuid);
        this.mutableContent.references = references;
    }
    addFile(file) {
        if (this.immutableItem.isReferencingItem(file)) {
            return;
        }
        const reference = {
            uuid: file.uuid,
            content_type: common_1.ContentType.File,
            reference_type: ContenteReferenceType_1.ContentReferenceType.FileToFile,
        };
        this.mutableContent.references.push(reference);
    }
    removeFile(file) {
        this.mutableContent.references = this.mutableContent.references.filter((r) => r.uuid !== file.uuid);
    }
}
exports.FileMutator = FileMutator;
