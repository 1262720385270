"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shareBlobOnMobile = void 0;
const Utils_1 = require("@/Utils");
const shareBlobOnMobile = async (application, blob, filename) => {
    if (!application.isNativeMobileWeb()) {
        throw new Error('Share function being used outside mobile webview');
    }
    const base64 = await (0, Utils_1.getBase64FromBlob)(blob);
    void application.mobileDevice().shareBase64AsFile(base64, filename);
};
exports.shareBlobOnMobile = shareBlobOnMobile;
