"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNProtocolOperator002 = void 0;
const Common = require("@standardnotes/common");
const Models = require("@standardnotes/models");
const models_1 = require("@standardnotes/models");
const Utils = require("@standardnotes/utils");
const utils_1 = require("@standardnotes/utils");
const Algorithm_1 = require("../../Algorithm");
const ItemsKey_1 = require("../../Keys/ItemsKey/ItemsKey");
const Functions_1 = require("../../Keys/RootKey/Functions");
const KeyParamsFunctions_1 = require("../../Keys/RootKey/KeyParamsFunctions");
const RootKey_1 = require("../../Keys/RootKey/RootKey");
const Operator001_1 = require("../001/Operator001");
/**
 * @deprecated
 * A legacy operator no longer used to generate new accounts.
 */
class SNProtocolOperator002 extends Operator001_1.SNProtocolOperator001 {
    get version() {
        return Common.ProtocolVersion.V002;
    }
    generateNewItemsKeyContent() {
        const keyLength = Algorithm_1.V002Algorithm.EncryptionKeyLength;
        const itemsKey = this.crypto.generateRandomKey(keyLength);
        const authKey = this.crypto.generateRandomKey(keyLength);
        const response = Models.FillItemContent({
            itemsKey: itemsKey,
            dataAuthenticationKey: authKey,
            version: Common.ProtocolVersion.V002,
        });
        return response;
    }
    /**
     * Creates a new random items key to use for item encryption.
     * The consumer must save/sync this item.
     */
    createItemsKey() {
        const payload = new Models.DecryptedPayload(Object.assign({ uuid: utils_1.UuidGenerator.GenerateUuid(), content_type: Common.ContentType.ItemsKey, content: this.generateNewItemsKeyContent() }, (0, models_1.PayloadTimestampDefaults)()));
        return Models.CreateDecryptedItemFromPayload(payload);
    }
    createRootKey(identifier, password, origination) {
        return __awaiter(this, void 0, void 0, function* () {
            const pwCost = Utils.lastElement(Algorithm_1.V002Algorithm.PbkdfCostsUsed);
            const pwNonce = this.crypto.generateRandomKey(Algorithm_1.V002Algorithm.SaltSeedLength);
            const pwSalt = yield this.crypto.unsafeSha1(identifier + ':' + pwNonce);
            const keyParams = (0, KeyParamsFunctions_1.Create002KeyParams)({
                email: identifier,
                pw_nonce: pwNonce,
                pw_cost: pwCost,
                pw_salt: pwSalt,
                version: Common.ProtocolVersion.V002,
                origination,
                created: `${Date.now()}`,
            });
            return this.deriveKey(password, keyParams);
        });
    }
    /**
     * Note that version 002 supported "dynamic" iteration counts. Some accounts
     * may have had costs of 5000, and others of 101000. Therefore, when computing
     * the root key, we must use the value returned by the server.
     */
    computeRootKey(password, keyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.deriveKey(password, keyParams);
        });
    }
    decryptString002(text, key, iv) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.crypto.aes256CbcDecrypt(text, iv, key);
        });
    }
    encryptString002(text, key, iv) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.crypto.aes256CbcEncrypt(text, iv, key);
        });
    }
    /**
     * @param keyParams Supplied only when encrypting an items key
     */
    encryptTextParams(string, encryptionKey, authKey, uuid, version, keyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            const iv = this.crypto.generateRandomKey(Algorithm_1.V002Algorithm.EncryptionIvLength);
            const contentCiphertext = yield this.encryptString002(string, encryptionKey, iv);
            const ciphertextToAuth = [version, uuid, iv, contentCiphertext].join(':');
            const authHash = yield this.crypto.hmac256(ciphertextToAuth, authKey);
            if (!authHash) {
                throw Error('Error generating hmac256 authHash');
            }
            const components = [version, authHash, uuid, iv, contentCiphertext];
            if (keyParams) {
                const keyParamsString = this.crypto.base64Encode(JSON.stringify(keyParams.content));
                components.push(keyParamsString);
            }
            const fullCiphertext = components.join(':');
            return fullCiphertext;
        });
    }
    decryptTextParams(ciphertextToAuth, contentCiphertext, encryptionKey, iv, authHash, authKey) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!encryptionKey) {
                throw 'Attempting to decryptTextParams with null encryptionKey';
            }
            const localAuthHash = yield this.crypto.hmac256(ciphertextToAuth, authKey);
            if (!localAuthHash) {
                throw Error('Error generating hmac256 localAuthHash');
            }
            if (this.crypto.timingSafeEqual(authHash, localAuthHash) === false) {
                console.error(Error('Auth hash does not match.'));
                return null;
            }
            return this.decryptString002(contentCiphertext, encryptionKey, iv);
        });
    }
    getPayloadAuthenticatedData(encrypted) {
        const itemKeyComponents = this.encryptionComponentsFromString002(encrypted.enc_item_key);
        const authenticatedData = itemKeyComponents.keyParams;
        if (!authenticatedData) {
            return undefined;
        }
        const decoded = JSON.parse(this.crypto.base64Decode(authenticatedData));
        const data = Object.assign({}, decoded);
        return data;
    }
    generateEncryptedParametersAsync(payload, key) {
        return __awaiter(this, void 0, void 0, function* () {
            /**
             * Generate new item key that is double the key size.
             * Will be split to create encryption key and authentication key.
             */
            const itemKey = this.crypto.generateRandomKey(Algorithm_1.V002Algorithm.EncryptionKeyLength * 2);
            const encItemKey = yield this.encryptTextParams(itemKey, key.itemsKey, key.dataAuthenticationKey, payload.uuid, key.keyVersion, key instanceof RootKey_1.SNRootKey ? key.keyParams : undefined);
            const ek = Utils.firstHalfOfString(itemKey);
            const ak = Utils.secondHalfOfString(itemKey);
            const ciphertext = yield this.encryptTextParams(JSON.stringify(payload.content), ek, ak, payload.uuid, key.keyVersion, key instanceof RootKey_1.SNRootKey ? key.keyParams : undefined);
            return {
                uuid: payload.uuid,
                items_key_id: (0, ItemsKey_1.isItemsKey)(key) ? key.uuid : undefined,
                content: ciphertext,
                enc_item_key: encItemKey,
                version: this.version,
            };
        });
    }
    generateDecryptedParametersAsync(encrypted, key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!encrypted.enc_item_key) {
                console.error(Error('Missing item encryption key, skipping decryption.'));
                return {
                    uuid: encrypted.uuid,
                    errorDecrypting: true,
                };
            }
            const encryptedItemKey = encrypted.enc_item_key;
            const itemKeyComponents = this.encryptionComponentsFromString002(encryptedItemKey, key.itemsKey, key.dataAuthenticationKey);
            const itemKey = yield this.decryptTextParams(itemKeyComponents.ciphertextToAuth, itemKeyComponents.contentCiphertext, itemKeyComponents.encryptionKey, itemKeyComponents.iv, itemKeyComponents.authHash, itemKeyComponents.authKey);
            if (!itemKey) {
                console.error('Error decrypting item_key parameters', encrypted);
                return {
                    uuid: encrypted.uuid,
                    errorDecrypting: true,
                };
            }
            const ek = Utils.firstHalfOfString(itemKey);
            const ak = Utils.secondHalfOfString(itemKey);
            const itemParams = this.encryptionComponentsFromString002(encrypted.content, ek, ak);
            const content = yield this.decryptTextParams(itemParams.ciphertextToAuth, itemParams.contentCiphertext, itemParams.encryptionKey, itemParams.iv, itemParams.authHash, itemParams.authKey);
            if (!content) {
                return {
                    uuid: encrypted.uuid,
                    errorDecrypting: true,
                };
            }
            else {
                return {
                    uuid: encrypted.uuid,
                    content: JSON.parse(content),
                };
            }
        });
    }
    deriveKey(password, keyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            const derivedKey = yield this.crypto.pbkdf2(password, keyParams.content002.pw_salt, keyParams.content002.pw_cost, Algorithm_1.V002Algorithm.PbkdfOutputLength);
            if (!derivedKey) {
                throw Error('Error deriving PBKDF2 key');
            }
            const partitions = Utils.splitString(derivedKey, 3);
            return (0, Functions_1.CreateNewRootKey)({
                serverPassword: partitions[0],
                masterKey: partitions[1],
                dataAuthenticationKey: partitions[2],
                version: Common.ProtocolVersion.V002,
                keyParams: keyParams.getPortableValue(),
            });
        });
    }
    encryptionComponentsFromString002(string, encryptionKey, authKey) {
        const components = string.split(':');
        return {
            encryptionVersion: components[0],
            authHash: components[1],
            uuid: components[2],
            iv: components[3],
            contentCiphertext: components[4],
            keyParams: components[5],
            ciphertextToAuth: [components[0], components[2], components[3], components[4]].join(':'),
            encryptionKey: encryptionKey,
            authKey: authKey,
        };
    }
}
exports.SNProtocolOperator002 = SNProtocolOperator002;
