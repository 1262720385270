"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptedItemMutator = void 0;
const utils_1 = require("@standardnotes/utils");
const MutationType_1 = require("../Types/MutationType");
const AppDataField_1 = require("../Types/AppDataField");
const DefaultAppDomain_1 = require("../Types/DefaultAppDomain");
const ItemMutator_1 = require("./ItemMutator");
const DirtyCounter_1 = require("../../../Runtime/DirtyCounter/DirtyCounter");
class DecryptedItemMutator extends ItemMutator_1.ItemMutator {
    constructor(item, type) {
        super(item, type);
        const mutableCopy = (0, utils_1.Copy)(this.immutablePayload.content);
        this.mutableContent = mutableCopy;
    }
    getResult() {
        if (this.type === MutationType_1.MutationType.NonDirtying) {
            return this.immutablePayload.copy({
                content: this.mutableContent,
            });
        }
        if (this.type === MutationType_1.MutationType.UpdateUserTimestamps) {
            this.userModifiedDate = new Date();
        }
        else {
            const currentValue = this.immutableItem.userModifiedDate;
            if (!currentValue) {
                this.userModifiedDate = new Date(this.immutableItem.serverUpdatedAt);
            }
        }
        const result = this.immutablePayload.copy({
            content: this.mutableContent,
            dirty: true,
            dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
        });
        return result;
    }
    setBeginSync(began, globalDirtyIndex) {
        this.immutablePayload = this.immutablePayload.copy({
            content: this.mutableContent,
            lastSyncBegan: began,
            globalDirtyIndexAtLastSync: globalDirtyIndex,
        });
    }
    /** Not recommended to use as this might break item schema if used incorrectly */
    setCustomContent(content) {
        this.mutableContent = (0, utils_1.Copy)(content);
    }
    set userModifiedDate(date) {
        this.setAppDataItem(AppDataField_1.AppDataField.UserModifiedDate, date);
    }
    set conflictOf(conflictOf) {
        this.mutableContent.conflict_of = conflictOf;
    }
    set protected(isProtected) {
        this.mutableContent.protected = isProtected;
    }
    set trashed(trashed) {
        this.mutableContent.trashed = trashed;
    }
    set starred(starred) {
        this.mutableContent.starred = starred;
    }
    set pinned(pinned) {
        this.setAppDataItem(AppDataField_1.AppDataField.Pinned, pinned);
    }
    set archived(archived) {
        this.setAppDataItem(AppDataField_1.AppDataField.Archived, archived);
    }
    set locked(locked) {
        this.setAppDataItem(AppDataField_1.AppDataField.Locked, locked);
    }
    /**
     * Overwrites the entirety of this domain's data with the data arg.
     */
    setDomainData(data, domain) {
        if (!this.mutableContent.appData) {
            this.mutableContent.appData = {
                [DefaultAppDomain_1.DefaultAppDomain]: {},
            };
        }
        this.mutableContent.appData[domain] = data;
    }
    /**
     * First gets the domain data for the input domain.
     * Then sets data[key] = value
     */
    setDomainDataKey(key, value, domain) {
        if (!this.mutableContent.appData) {
            this.mutableContent.appData = {
                [DefaultAppDomain_1.DefaultAppDomain]: {},
            };
        }
        if (!this.mutableContent.appData[domain]) {
            this.mutableContent.appData[domain] = {};
        }
        const domainData = this.mutableContent.appData[domain];
        domainData[key] = value;
    }
    setAppDataItem(key, value) {
        this.setDomainDataKey(key, value, DefaultAppDomain_1.DefaultAppDomain);
    }
    e2ePendingRefactor_addItemAsRelationship(item) {
        const references = this.mutableContent.references || [];
        if (!references.find((r) => r.uuid === item.uuid)) {
            references.push({
                uuid: item.uuid,
                content_type: item.content_type,
            });
        }
        this.mutableContent.references = references;
    }
    removeItemAsRelationship(item) {
        let references = this.mutableContent.references || [];
        references = references.filter((r) => r.uuid !== item.uuid);
        this.mutableContent.references = references;
    }
}
exports.DecryptedItemMutator = DecryptedItemMutator;
