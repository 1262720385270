"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientFeatures = void 0;
const PermissionName_1 = require("../Permission/PermissionName");
const FeatureIdentifier_1 = require("../Feature/FeatureIdentifier");
const domain_core_1 = require("@standardnotes/domain-core");
const Themes_1 = require("./Themes");
const Editors_1 = require("./Editors");
function clientFeatures() {
    return [
        ...(0, Themes_1.themes)(),
        ...(0, Editors_1.editors)(),
        {
            name: 'Tag Nesting',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.TagNesting,
            permission_name: PermissionName_1.PermissionName.TagNesting,
            description: 'Organize your tags into folders.',
        },
        {
            name: 'Super Notes',
            identifier: FeatureIdentifier_1.FeatureIdentifier.SuperEditor,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            permission_name: PermissionName_1.PermissionName.SuperEditor,
            description: 'A new way to edit notes. Type / to bring up the block selection menu, or @ to embed images or link other tags and notes. Type - then space to start a list, or [] then space to start a checklist. Drag and drop an image or file to embed it in your note. Cmd/Ctrl + F to bring up search and replace.',
        },
        {
            name: 'Smart Filters',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.SmartFilters,
            permission_name: PermissionName_1.PermissionName.SmartFilters,
            description: 'Create smart filters for viewing notes matching specific criteria.',
        },
        {
            name: 'Encrypted files',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.Files,
            permission_name: PermissionName_1.PermissionName.Files,
            description: '',
        },
        {
            name: 'Extension',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.Extension,
            permission_name: PermissionName_1.PermissionName.Extension,
            description: '',
        },
    ];
}
exports.clientFeatures = clientFeatures;
