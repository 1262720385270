"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanelResizeType = exports.PanelSide = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const Utils_1 = require("@/Utils");
const utils_1 = require("@standardnotes/utils");
var PanelSide;
(function (PanelSide) {
    PanelSide["Right"] = "right";
    PanelSide["Left"] = "left";
})(PanelSide = exports.PanelSide || (exports.PanelSide = {}));
var PanelResizeType;
(function (PanelResizeType) {
    PanelResizeType["WidthOnly"] = "WidthOnly";
    PanelResizeType["OffsetAndWidth"] = "OffsetAndWidth";
})(PanelResizeType = exports.PanelResizeType || (exports.PanelResizeType = {}));
class PanelResizer extends react_1.Component {
    constructor(props) {
        super(props);
        this.resizerElementRef = (0, react_1.createRef)();
        this.isAtMaxWidth = () => {
            const marginOfError = 5;
            const difference = Math.abs(Math.round(this.lastWidth + this.lastLeft) - Math.round(this.getParentRect().width));
            return difference < marginOfError;
        };
        this.finishSettingWidth = () => {
            if (!this.props.collapsable) {
                return;
            }
            this.setState({
                collapsed: this.isCollapsed(),
            });
        };
        this.setWidth = (width, finish = false) => {
            if (width === 0) {
                width = this.computeMaxWidth();
            }
            if (width < this.minWidth) {
                width = this.minWidth;
            }
            const parentRect = this.getParentRect();
            if (width > parentRect.width) {
                width = parentRect.width;
            }
            const maxWidth = this.appFrame.width - this.props.panel.getBoundingClientRect().x;
            if (width > maxWidth) {
                width = maxWidth;
            }
            const isFullWidth = Math.round(width + this.lastLeft) === Math.round(parentRect.width);
            if (this.props.modifyElementWidth) {
                if (isFullWidth) {
                    if (this.props.type === PanelResizeType.WidthOnly) {
                        this.props.panel.style.removeProperty('width');
                    }
                    else {
                        this.props.panel.style.width = `calc(100% - ${this.lastLeft}px)`;
                    }
                }
                else {
                    this.props.panel.style.width = width + 'px';
                }
            }
            this.lastWidth = width;
            if (finish) {
                this.finishSettingWidth();
                if (this.props.resizeFinishCallback) {
                    this.props.resizeFinishCallback(this.lastWidth, this.lastLeft, this.isAtMaxWidth(), this.isCollapsed());
                }
            }
            return width;
        };
        this.setLeft = (left) => {
            this.props.panel.style.left = left + 'px';
            this.lastLeft = left;
        };
        this.onDblClick = () => {
            var _a, _b;
            const collapsed = this.isCollapsed();
            if (collapsed) {
                this.setWidth(this.widthBeforeLastDblClick || this.props.defaultWidth || 0);
            }
            else {
                this.widthBeforeLastDblClick = this.lastWidth;
                this.setWidth(this.minWidth);
            }
            this.finishSettingWidth();
            (_b = (_a = this.props).resizeFinishCallback) === null || _b === void 0 ? void 0 : _b.call(_a, this.lastWidth, this.lastLeft, this.isAtMaxWidth(), this.isCollapsed());
        };
        this.handleResize = () => {
            const startWidth = this.isAtMaxWidth() ? this.computeMaxWidth() : this.props.panel.scrollWidth;
            this.startWidth = startWidth;
            this.lastWidth = startWidth;
            this.handleWidthEvent();
            this.finishSettingWidth();
        };
        this.onMouseDown = (event) => {
            this.addInvisibleOverlay();
            this.lastDownX = event.clientX;
            this.startWidth = this.props.panel.scrollWidth;
            this.startLeft = this.props.panel.offsetLeft;
            this.setState({
                pressed: true,
            });
        };
        this.onMouseUp = () => {
            this.removeInvisibleOverlay();
            if (!this.state.pressed) {
                return;
            }
            this.setState({ pressed: false });
            const isMaxWidth = this.isAtMaxWidth();
            if (this.props.resizeFinishCallback) {
                this.props.resizeFinishCallback(this.lastWidth, this.lastLeft, isMaxWidth, this.isCollapsed());
            }
            this.finishSettingWidth();
        };
        this.onMouseMove = (event) => {
            if (!this.state.pressed) {
                return;
            }
            event.preventDefault();
            if (this.props.side === PanelSide.Left) {
                this.handleLeftEvent(event);
            }
            else {
                this.handleWidthEvent(event);
            }
        };
        /**
         * If an iframe is displayed adjacent to our panel, and the mouse exits over the iframe,
         * document[onmouseup] is not triggered because the document is no longer the same over
         * the iframe. We add an invisible overlay while resizing so that the mouse context
         * remains in our main document.
         */
        this.addInvisibleOverlay = () => {
            if (this.overlay) {
                return;
            }
            const overlayElement = document.createElement('div');
            overlayElement.id = 'resizer-overlay';
            this.overlay = overlayElement;
            document.body.prepend(this.overlay);
        };
        this.removeInvisibleOverlay = () => {
            if (this.overlay) {
                this.overlay.remove();
                this.overlay = undefined;
            }
        };
        this.state = {
            collapsed: false,
            pressed: false,
        };
        this.minWidth = props.minWidth || 5;
        this.startLeft = props.panel.offsetLeft;
        this.startWidth = props.panel.scrollWidth;
        this.lastDownX = 0;
        this.lastLeft = props.panel.offsetLeft;
        this.lastWidth = props.panel.scrollWidth;
        this.widthBeforeLastDblClick = 0;
        this.setWidth(this.props.width);
        this.setLeft(this.props.left);
        document.addEventListener('mouseup', this.onMouseUp);
        document.addEventListener('mousemove', this.onMouseMove);
        this.debouncedResizeHandler = (0, Utils_1.debounce)(this.handleResize, 250);
        if (this.props.type === PanelResizeType.OffsetAndWidth) {
            window.addEventListener('resize', this.debouncedResizeHandler);
        }
    }
    componentDidMount() {
        var _a;
        (_a = this.resizerElementRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('dblclick', this.onDblClick);
    }
    componentDidUpdate(prevProps) {
        this.lastWidth = this.props.panel.scrollWidth;
        if (this.props.width != prevProps.width) {
            this.setWidth(this.props.width);
        }
        if (this.props.left !== prevProps.left) {
            this.setLeft(this.props.left);
            this.setWidth(this.props.width);
        }
        const isCollapsed = this.isCollapsed();
        if (isCollapsed !== this.state.collapsed) {
            this.setState({ collapsed: isCollapsed });
        }
    }
    componentWillUnmount() {
        var _a;
        (_a = this.resizerElementRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('dblclick', this.onDblClick);
        document.removeEventListener('mouseup', this.onMouseUp);
        document.removeEventListener('mousemove', this.onMouseMove);
        window.removeEventListener('resize', this.debouncedResizeHandler);
    }
    get appFrame() {
        var _a;
        return (_a = document.getElementById('app')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
    }
    getParentRect() {
        if (!this.props.panel.parentNode) {
            return new DOMRect();
        }
        return this.props.panel.parentNode.getBoundingClientRect();
    }
    isCollapsed() {
        return this.lastWidth <= this.minWidth;
    }
    handleWidthEvent(event) {
        let x;
        if (event) {
            x = event.clientX;
        }
        else {
            /** Coming from resize event */
            x = 0;
            this.lastDownX = 0;
        }
        const deltaX = x - this.lastDownX;
        const newWidth = this.startWidth + deltaX;
        const adjustedWidth = this.setWidth(newWidth, false);
        if (this.props.widthEventCallback) {
            this.props.widthEventCallback(adjustedWidth);
        }
    }
    handleLeftEvent(event) {
        const panelRect = this.props.panel.getBoundingClientRect();
        const x = event.clientX || panelRect.x;
        let deltaX = x - this.lastDownX;
        let newLeft = this.startLeft + deltaX;
        if (newLeft < 0) {
            newLeft = 0;
            deltaX = -this.startLeft;
        }
        const parentRect = this.getParentRect();
        let newWidth = this.startWidth - deltaX;
        if (newWidth < this.minWidth) {
            newWidth = this.minWidth;
        }
        if (newWidth > parentRect.width) {
            newWidth = parentRect.width;
        }
        if (newLeft + newWidth > parentRect.width) {
            newLeft = parentRect.width - newWidth;
        }
        this.setLeft(newLeft);
        this.setWidth(newWidth, false);
    }
    computeMaxWidth() {
        const parentRect = this.getParentRect();
        let width = parentRect.width - this.props.left;
        if (width < this.minWidth) {
            width = this.minWidth;
        }
        return width;
    }
    render() {
        return ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.classNames)('panel-resizer', 'absolute right-0 top-0 z-panel-resizer', 'hidden h-full w-[4px] cursor-col-resize border-y-0 bg-[color:var(--panel-resizer-background-color)] md:block', this.props.alwaysVisible || this.state.collapsed || this.state.pressed ? ' opacity-100' : 'opacity-0', this.props.hoverable && 'hover:opacity-100', this.props.side === PanelSide.Left && 'left-0 right-auto'), onMouseDown: this.onMouseDown, ref: this.resizerElementRef }));
    }
}
exports.default = PanelResizer;
