"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptBackupFile = void 0;
const common_1 = require("@standardnotes/common");
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
const responses_1 = require("@standardnotes/responses");
const utils_1 = require("@standardnotes/utils");
async function DecryptBackupFile(file, protocolService, password) {
    const payloads = file.items.map((item) => {
        if ((0, models_1.isEncryptedTransferPayload)(item)) {
            return new models_1.EncryptedPayload(item);
        }
        else if ((0, models_1.isDecryptedTransferPayload)(item)) {
            return new models_1.DecryptedPayload(item);
        }
        else {
            throw Error('Unhandled case in decryptBackupFile');
        }
    });
    const { encrypted, decrypted } = (0, models_1.CreatePayloadSplit)(payloads);
    const type = getBackupFileType(file, payloads);
    switch (type) {
        case encryption_1.BackupFileType.Corrupt:
            return new responses_1.ClientDisplayableError('Invalid backup file.');
        case encryption_1.BackupFileType.Encrypted: {
            if (!password) {
                throw Error('Attempting to decrypt encrypted file with no password');
            }
            const keyParamsData = (file.keyParams || file.auth_params);
            return [
                ...decrypted,
                ...(await decryptEncrypted(password, (0, encryption_1.CreateAnyKeyParams)(keyParamsData), encrypted, protocolService)),
            ];
        }
        case encryption_1.BackupFileType.EncryptedWithNonEncryptedItemsKey:
            return [...decrypted, ...(await decryptEncryptedWithNonEncryptedItemsKey(payloads, protocolService))];
        case encryption_1.BackupFileType.FullyDecrypted:
            return [...decrypted, ...encrypted];
    }
}
exports.DecryptBackupFile = DecryptBackupFile;
function getBackupFileType(file, payloads) {
    if (file.keyParams || file.auth_params) {
        return encryption_1.BackupFileType.Encrypted;
    }
    else {
        const hasEncryptedItem = payloads.find(models_1.isEncryptedPayload);
        const hasDecryptedItemsKey = payloads.find((payload) => payload.content_type === common_1.ContentType.ItemsKey && (0, models_1.isDecryptedPayload)(payload));
        if (hasEncryptedItem && hasDecryptedItemsKey) {
            return encryption_1.BackupFileType.EncryptedWithNonEncryptedItemsKey;
        }
        else if (!hasEncryptedItem) {
            return encryption_1.BackupFileType.FullyDecrypted;
        }
        else {
            return encryption_1.BackupFileType.Corrupt;
        }
    }
}
async function decryptEncryptedWithNonEncryptedItemsKey(allPayloads, protocolService) {
    const decryptedItemsKeys = [];
    const encryptedPayloads = [];
    allPayloads.forEach((payload) => {
        if (payload.content_type === common_1.ContentType.ItemsKey && (0, models_1.isDecryptedPayload)(payload)) {
            decryptedItemsKeys.push(payload);
        }
        else if ((0, models_1.isEncryptedPayload)(payload)) {
            encryptedPayloads.push(payload);
        }
    });
    const itemsKeys = decryptedItemsKeys.map((p) => (0, models_1.CreateDecryptedItemFromPayload)(p));
    return decryptWithItemsKeys(encryptedPayloads, itemsKeys, protocolService);
}
function findKeyToUseForPayload(payload, availableKeys, protocolService, keyParams, fallbackRootKey) {
    let itemsKey;
    if (payload.items_key_id) {
        itemsKey = protocolService.itemsKeyForPayload(payload);
        if (itemsKey) {
            return itemsKey;
        }
    }
    itemsKey = availableKeys.find((itemsKeyPayload) => {
        return payload.items_key_id === itemsKeyPayload.uuid;
    });
    if (itemsKey) {
        return itemsKey;
    }
    if (!keyParams) {
        return undefined;
    }
    const payloadVersion = payload.version;
    /**
     * Payloads with versions <= 003 use root key directly for encryption.
     * However, if the incoming key params are >= 004, this means we should
     * have an items key based off the 003 root key. We can't use the 004
     * root key directly because it's missing dataAuthenticationKey.
     */
    if ((0, common_1.leftVersionGreaterThanOrEqualToRight)(keyParams.version, common_1.ProtocolVersion.V004)) {
        itemsKey = protocolService.defaultItemsKeyForItemVersion(payloadVersion, availableKeys);
    }
    else if ((0, common_1.compareVersions)(payloadVersion, common_1.ProtocolVersion.V003) <= 0) {
        itemsKey = fallbackRootKey;
    }
    return itemsKey;
}
async function decryptWithItemsKeys(payloads, itemsKeys, protocolService, keyParams, fallbackRootKey) {
    const results = [];
    for (const encryptedPayload of payloads) {
        if ((0, encryption_1.ContentTypeUsesRootKeyEncryption)(encryptedPayload.content_type)) {
            continue;
        }
        try {
            const key = findKeyToUseForPayload(encryptedPayload, itemsKeys, protocolService, keyParams, fallbackRootKey);
            if (!key) {
                results.push(encryptedPayload.copy({
                    errorDecrypting: true,
                }));
                continue;
            }
            if ((0, encryption_1.isItemsKey)(key)) {
                const decryptedPayload = await protocolService.decryptSplitSingle({
                    usesItemsKey: {
                        items: [encryptedPayload],
                        key: key,
                    },
                });
                results.push(decryptedPayload);
            }
            else {
                const decryptedPayload = await protocolService.decryptSplitSingle({
                    usesRootKey: {
                        items: [encryptedPayload],
                        key: key,
                    },
                });
                results.push(decryptedPayload);
            }
        }
        catch (e) {
            results.push(encryptedPayload.copy({
                errorDecrypting: true,
            }));
            console.error('Error decrypting payload', encryptedPayload, e);
        }
    }
    return results;
}
async function decryptEncrypted(password, keyParams, payloads, protocolService) {
    const results = [];
    const rootKey = await protocolService.computeRootKey(password, keyParams);
    const itemsKeysPayloads = payloads.filter((payload) => {
        return payload.content_type === common_1.ContentType.ItemsKey;
    });
    const itemsKeysDecryptionResults = await protocolService.decryptSplit({
        usesRootKey: {
            items: itemsKeysPayloads,
            key: rootKey,
        },
    });
    (0, utils_1.extendArray)(results, itemsKeysDecryptionResults);
    const decryptedPayloads = await decryptWithItemsKeys(payloads, itemsKeysDecryptionResults.filter(models_1.isDecryptedPayload).map((p) => (0, models_1.CreateDecryptedItemFromPayload)(p)), protocolService, keyParams, rootKey);
    (0, utils_1.extendArray)(results, decryptedPayloads);
    return results;
}
