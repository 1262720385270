"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const utils_1 = require("@standardnotes/utils");
const useTabState_1 = require("./useTabState");
const Tab = ({ id, className, children, ...props }) => {
    const { state } = (0, useTabState_1.useTabStateContext)();
    const { activeTab, setActiveTab } = state;
    const isActive = activeTab === id;
    return ((0, jsx_runtime_1.jsx)("button", { role: "tab", id: `tab-control-${id}`, onClick: () => {
            setActiveTab(id);
        }, "aria-selected": isActive, "aria-controls": `tab-panel-${id}`, className: (0, utils_1.classNames)('relative cursor-pointer border-0 bg-default px-3 py-2.5 text-sm focus:shadow-inner', isActive ? 'font-medium text-info' : 'text-text', isActive && 'after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:bg-info', className), ...props, children: children }));
};
exports.default = Tab;
