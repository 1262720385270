"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeltaRemoteRejected = void 0;
const PayloadSource_1 = require("../../Abstract/Payload/Types/PayloadSource");
const Payload_1 = require("../../Abstract/Payload");
class DeltaRemoteRejected {
    constructor(baseCollection, applyCollection) {
        this.baseCollection = baseCollection;
        this.applyCollection = applyCollection;
    }
    result() {
        const results = [];
        for (const apply of this.applyCollection.all()) {
            const base = this.baseCollection.find(apply.uuid);
            if (!base) {
                continue;
            }
            const result = base.copyAsSyncResolved({
                dirty: false,
                lastSyncEnd: new Date(),
            }, PayloadSource_1.PayloadSource.RemoteSaved);
            results.push(result);
        }
        return {
            emits: results,
            source: Payload_1.PayloadEmitSource.RemoteSaved,
        };
    }
}
exports.DeltaRemoteRejected = DeltaRemoteRejected;
