"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mergeRefs_1 = require("@/Hooks/mergeRefs");
const react_1 = require("@ariakit/react");
const react_2 = require("react");
const useModalAnimation_1 = require("../Modal/useModalAnimation");
const ModalOverlay = (0, react_2.forwardRef)(({ isOpen, children, animationVariant, close, ...props }, ref) => {
    const [isMounted, setElement] = (0, useModalAnimation_1.useModalAnimation)(isOpen, animationVariant);
    const dialog = (0, react_1.useDialogStore)({
        open: isMounted,
        setOpen: (open) => {
            if (!open) {
                close();
            }
        },
    });
    if (!isMounted) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(react_1.Dialog, { tabIndex: 0, className: "fixed top-0 left-0 z-modal h-full w-full", ref: (0, mergeRefs_1.mergeRefs)([setElement, ref]), store: dialog, getPersistentElements: () => Array.from(document.querySelectorAll('[role="dialog"], [role="alertdialog"]')).map((el) => {
            return el.parentElement ? el.parentElement : el;
        }), ...props, children: children }));
});
exports.default = ModalOverlay;
