"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchPlugin = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const lexical_1 = require("lexical");
const react_1 = require("react");
const createSearchHighlightElement_1 = require("./createSearchHighlightElement");
const Context_1 = require("./Context");
const SearchDialog_1 = require("./SearchDialog");
const getAllTextNodesInElement_1 = require("./getAllTextNodesInElement");
const utils_1 = require("@standardnotes/utils");
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const ui_services_1 = require("@standardnotes/ui-services");
const useStateRef_1 = require("@/Hooks/useStateRef");
const SearchPlugin = () => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    const { query, currentResultIndex, results, isCaseSensitive, isSearchActive, dispatch, addReplaceEventListener } = (0, Context_1.useSuperSearchContext)();
    const queryRef = (0, useStateRef_1.useStateRef)(query);
    const currentResultIndexRef = (0, useStateRef_1.useStateRef)(currentResultIndex);
    const isCaseSensitiveRef = (0, useStateRef_1.useStateRef)(isCaseSensitive);
    const resultsRef = (0, useStateRef_1.useStateRef)(results);
    (0, react_1.useEffect)(() => {
        const isFocusInEditor = () => {
            if (!document.activeElement || !document.activeElement.closest('.blocks-editor')) {
                return false;
            }
            return true;
        };
        return application.keyboardService.addCommandHandlers([
            {
                command: ui_services_1.SUPER_TOGGLE_SEARCH,
                onKeyDown: (event) => {
                    if (!isFocusInEditor()) {
                        return;
                    }
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch({ type: 'toggle-search' });
                    editor.focus();
                },
            },
            {
                command: ui_services_1.SUPER_SEARCH_TOGGLE_REPLACE_MODE,
                onKeyDown: (event) => {
                    if (!isFocusInEditor()) {
                        return;
                    }
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch({ type: 'toggle-replace-mode' });
                },
            },
            {
                command: ui_services_1.SUPER_SEARCH_TOGGLE_CASE_SENSITIVE,
                onKeyDown() {
                    if (!isFocusInEditor()) {
                        return;
                    }
                    dispatch({
                        type: 'toggle-case-sensitive',
                    });
                },
            },
            {
                command: ui_services_1.SUPER_SEARCH_NEXT_RESULT,
                onKeyDown(event) {
                    if (!isFocusInEditor()) {
                        return;
                    }
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch({
                        type: 'go-to-next-result',
                    });
                },
            },
            {
                command: ui_services_1.SUPER_SEARCH_PREVIOUS_RESULT,
                onKeyDown(event) {
                    if (!isFocusInEditor()) {
                        return;
                    }
                    event.preventDefault();
                    event.stopPropagation();
                    dispatch({
                        type: 'go-to-previous-result',
                    });
                },
            },
        ]);
    }, [application.keyboardService, dispatch, editor]);
    const handleSearch = (0, react_1.useCallback)((query, isCaseSensitive) => {
        document.querySelectorAll('.search-highlight').forEach((element) => {
            element.remove();
        });
        if (!query) {
            dispatch({ type: 'clear-results' });
            return;
        }
        editor.getEditorState().read(() => {
            const rootElement = editor.getRootElement();
            if (!rootElement) {
                return;
            }
            const textNodes = (0, getAllTextNodesInElement_1.getAllTextNodesInElement)(rootElement);
            const results = [];
            textNodes.forEach((node) => {
                const text = node.textContent || '';
                const indices = [];
                let index = -1;
                const textWithCase = isCaseSensitive ? text : text.toLowerCase();
                const queryWithCase = isCaseSensitive ? query : query.toLowerCase();
                while ((index = textWithCase.indexOf(queryWithCase, index + 1)) !== -1) {
                    indices.push(index);
                }
                indices.forEach((index) => {
                    const startIndex = index;
                    const endIndex = startIndex + query.length;
                    results.push({
                        node,
                        startIndex,
                        endIndex,
                    });
                });
            });
            dispatch({
                type: 'set-results',
                results,
            });
        });
    }, [dispatch, editor]);
    const handleQueryChange = (0, react_1.useMemo)(() => (0, utils_1.debounce)(handleSearch, 250), [handleSearch]);
    const handleEditorChange = (0, react_1.useMemo)(() => (0, utils_1.debounce)(handleSearch, 500), [handleSearch]);
    (0, react_1.useEffect)(() => {
        if (!query) {
            dispatch({ type: 'clear-results' });
            dispatch({ type: 'set-current-result-index', index: -1 });
            return;
        }
        void handleQueryChange(query, isCaseSensitiveRef.current);
    }, [dispatch, handleQueryChange, isCaseSensitiveRef, query]);
    (0, react_1.useEffect)(() => {
        const handleCaseSensitiveChange = () => {
            void handleSearch(queryRef.current, isCaseSensitive);
        };
        handleCaseSensitiveChange();
    }, [handleSearch, isCaseSensitive, queryRef]);
    (0, react_1.useLayoutEffect)(() => {
        return editor.registerUpdateListener(({ dirtyElements, dirtyLeaves, prevEditorState, tags }) => {
            if ((dirtyElements.size === 0 && dirtyLeaves.size === 0) ||
                tags.has('history-merge') ||
                prevEditorState.isEmpty()) {
                return;
            }
            void handleEditorChange(queryRef.current, isCaseSensitiveRef.current);
        });
    }, [editor, handleEditorChange, isCaseSensitiveRef, queryRef]);
    (0, react_1.useEffect)(() => {
        return addReplaceEventListener((event) => {
            const { replace, type } = event;
            const replaceResult = (result, scrollIntoView = false) => {
                const { node, startIndex, endIndex } = result;
                const lexicalNode = (0, lexical_1.$getNearestNodeFromDOMNode)(node);
                if (!lexicalNode) {
                    return;
                }
                if (lexicalNode instanceof lexical_1.TextNode) {
                    lexicalNode.spliceText(startIndex, endIndex - startIndex, replace, true);
                }
                if (scrollIntoView && node.parentElement) {
                    node.parentElement.scrollIntoView({
                        block: 'center',
                    });
                }
            };
            editor.update(() => {
                if (type === 'next') {
                    const result = resultsRef.current[currentResultIndexRef.current];
                    if (!result) {
                        return;
                    }
                    replaceResult(result, true);
                }
                else if (type === 'all') {
                    resultsRef.current.forEach((result) => replaceResult(result));
                }
                void handleSearch(queryRef.current, isCaseSensitiveRef.current);
            });
        });
    }, [addReplaceEventListener, currentResultIndexRef, editor, handleSearch, isCaseSensitiveRef, queryRef, resultsRef]);
    (0, react_1.useEffect)(() => {
        document.querySelectorAll('.search-highlight').forEach((element) => {
            element.remove();
        });
        if (currentResultIndex === -1) {
            return;
        }
        const result = results[currentResultIndex];
        editor.getEditorState().read(() => {
            var _a, _b;
            const rootElement = editor.getRootElement();
            const containerElement = (_a = rootElement === null || rootElement === void 0 ? void 0 : rootElement.parentElement) === null || _a === void 0 ? void 0 : _a.getElementsByClassName('search-highlight-container')[0];
            (_b = result.node.parentElement) === null || _b === void 0 ? void 0 : _b.scrollIntoView({
                block: 'center',
            });
            if (!rootElement || !containerElement) {
                return;
            }
            (0, createSearchHighlightElement_1.createSearchHighlightElement)(result, rootElement, containerElement);
        });
    }, [currentResultIndex, editor, results]);
    (0, react_1.useEffect)(() => {
        let containerElement;
        let rootElement;
        editor.getEditorState().read(() => {
            var _a;
            rootElement = editor.getRootElement();
            containerElement = (_a = rootElement === null || rootElement === void 0 ? void 0 : rootElement.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('.search-highlight-container');
        });
        if (!rootElement || !containerElement) {
            return;
        }
        const resizeObserver = new ResizeObserver(() => {
            if (!rootElement || !containerElement) {
                return;
            }
            containerElement.style.height = `${rootElement.scrollHeight}px`;
            containerElement.style.overflow = 'visible';
        });
        resizeObserver.observe(rootElement);
        const handleScroll = () => {
            if (!rootElement || !containerElement) {
                return;
            }
            containerElement.style.top = `-${rootElement.scrollTop}px`;
        };
        rootElement.addEventListener('scroll', handleScroll);
        return () => {
            resizeObserver.disconnect();
            rootElement === null || rootElement === void 0 ? void 0 : rootElement.removeEventListener('scroll', handleScroll);
        };
    }, [editor]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(SearchDialog_1.SearchDialog, { open: isSearchActive, closeDialog: () => {
                dispatch({ type: 'toggle-search' });
                dispatch({ type: 'reset-search' });
                editor.focus();
            } }) }));
};
exports.SearchPlugin = SearchPlugin;
