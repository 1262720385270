"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleEditorChange = exports.truncateString = void 0;
const lexical_1 = require("lexical");
function truncateString(string, limit) {
    if (string.length <= limit) {
        return string;
    }
    else {
        return string.substring(0, limit) + '...';
    }
}
exports.truncateString = truncateString;
function handleEditorChange(editorState, previewLength, onChange) {
    const childrenNodes = (0, lexical_1.$getRoot)().getAllTextNodes().slice(0, 2);
    let previewText = '';
    childrenNodes.forEach((node, index) => {
        previewText += node.getTextContent();
        if (index !== childrenNodes.length - 1) {
            previewText += '\n';
        }
    });
    if (previewLength) {
        previewText = truncateString(previewText, previewLength);
    }
    try {
        const stringifiedEditorState = JSON.stringify(editorState.toJSON());
        onChange === null || onChange === void 0 ? void 0 : onChange(stringifiedEditorState, previewText);
    }
    catch (error) {
        window.alert(`An invalid change was made inside the Super editor. Your change was not saved. Please report this error to the team: ${error}`);
    }
}
exports.handleEditorChange = handleEditorChange;
