"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNRootKeyParams = void 0;
const common_1 = require("@standardnotes/common");
const utils_1 = require("@standardnotes/utils");
const ProtocolVersionForKeyParams_1 = require("./ProtocolVersionForKeyParams");
const ValidKeyParamsKeys_1 = require("./ValidKeyParamsKeys");
class SNRootKeyParams {
    constructor(content) {
        this.content = Object.assign(Object.assign({}, content), { origination: content.origination || common_1.KeyParamsOrigination.Registration, version: content.version || (0, ProtocolVersionForKeyParams_1.ProtocolVersionForKeyParams)(content) });
    }
    get isKeyParamsObject() {
        return true;
    }
    get identifier() {
        return this.content004.identifier || this.content002.email;
    }
    get version() {
        return this.content.version;
    }
    get origination() {
        return this.content.origination;
    }
    get content001() {
        return this.content;
    }
    get content002() {
        return this.content;
    }
    get content003() {
        return this.content;
    }
    get content004() {
        return this.content;
    }
    get createdDate() {
        if (!this.content004.created) {
            return undefined;
        }
        return new Date(Number(this.content004.created));
    }
    compare(other) {
        if (this.version !== other.version) {
            return false;
        }
        if ([common_1.ProtocolVersion.V004, common_1.ProtocolVersion.V003].includes(this.version)) {
            return this.identifier === other.identifier && this.content004.pw_nonce === other.content003.pw_nonce;
        }
        else if ([common_1.ProtocolVersion.V002, common_1.ProtocolVersion.V001].includes(this.version)) {
            return this.identifier === other.identifier && this.content002.pw_salt === other.content001.pw_salt;
        }
        else {
            throw Error('Unhandled version in KeyParams.compare');
        }
    }
    getPortableValue() {
        return (0, utils_1.pickByCopy)(this.content, ValidKeyParamsKeys_1.ValidKeyParamsKeys);
    }
}
exports.SNRootKeyParams = SNRootKeyParams;
