"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditSmartViewModalController = void 0;
const Strings_1 = require("@/Constants/Strings");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const mobx_1 = require("mobx");
class EditSmartViewModalController {
    constructor(application) {
        this.application = application;
        this.title = '';
        this.icon = snjs_1.SmartViewDefaultIconName;
        this.predicateJson = '';
        this.isPredicateJsonValid = false;
        this.isSaving = false;
        this.view = undefined;
        this.setTitle = (title) => {
            this.title = title;
        };
        this.setIcon = (icon) => {
            this.icon = icon;
        };
        this.setPredicateJson = (json) => {
            this.predicateJson = json;
        };
        this.setIsPredicateJsonValid = (isValid) => {
            this.isPredicateJsonValid = isValid;
        };
        this.setView = (view) => {
            this.view = view;
            if (view) {
                this.setTitle(view.title);
                this.setIcon(view.iconString);
                this.setPredicateJson(JSON.stringify(view.predicate.toJson(), null, 2));
                this.setIsPredicateJsonValid(true);
            }
        };
        this.setIsSaving = (isSaving) => {
            this.isSaving = isSaving;
        };
        this.closeDialog = () => {
            this.setView(undefined);
            this.setTitle('');
            this.setIcon(snjs_1.SmartViewDefaultIconName);
            this.setPredicateJson('');
        };
        this.save = async () => {
            if (!this.view) {
                return;
            }
            this.validateAndPrettifyCustomPredicate();
            if (!this.isPredicateJsonValid) {
                return;
            }
            this.setIsSaving(true);
            await this.application.mutator.changeAndSaveItem(this.view, (mutator) => {
                mutator.title = this.title;
                mutator.iconString = this.icon || snjs_1.SmartViewDefaultIconName;
                mutator.predicate = JSON.parse(this.predicateJson);
            });
            this.setIsSaving(false);
            this.closeDialog();
        };
        this.deleteView = async () => {
            if (!this.view) {
                return;
            }
            const view = this.view;
            this.closeDialog();
            const shouldDelete = await (0, ui_services_1.confirmDialog)({
                text: Strings_1.STRING_DELETE_TAG,
                confirmButtonStyle: 'danger',
            });
            if (shouldDelete) {
                this.application.mutator.deleteItem(view).catch(console.error);
            }
        };
        this.validateAndPrettifyCustomPredicate = () => {
            try {
                const parsedPredicate = JSON.parse(this.predicateJson);
                const predicate = (0, snjs_1.predicateFromJson)(parsedPredicate);
                if (predicate) {
                    this.setPredicateJson(JSON.stringify(parsedPredicate, null, 2));
                    this.setIsPredicateJsonValid(true);
                }
                else {
                    this.setIsPredicateJsonValid(false);
                }
            }
            catch (error) {
                this.setIsPredicateJsonValid(false);
                return;
            }
        };
        (0, mobx_1.makeObservable)(this, {
            title: mobx_1.observable,
            icon: mobx_1.observable,
            predicateJson: mobx_1.observable,
            isPredicateJsonValid: mobx_1.observable,
            isSaving: mobx_1.observable,
            view: mobx_1.observable,
            setTitle: mobx_1.action,
            setIcon: mobx_1.action,
            setPredicateJson: mobx_1.action,
            setIsPredicateJsonValid: mobx_1.action,
            setIsSaving: mobx_1.action,
            setView: mobx_1.action,
        });
    }
}
exports.EditSmartViewModalController = EditSmartViewModalController;
