"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileService = void 0;
const responses_1 = require("@standardnotes/responses");
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const encryption_1 = require("@standardnotes/encryption");
const files_1 = require("@standardnotes/files");
const AlertService_1 = require("../Alert/AlertService");
const AbstractService_1 = require("../Service/AbstractService");
const Functions_1 = require("../Encryption/Functions");
const Logging_1 = require("../Logging");
const OneHundredMb = 100 * 1000000;
class FileService extends AbstractService_1.AbstractService {
    constructor(api, itemManager, syncService, encryptor, challengor, alertService, crypto, internalEventBus, backupsService) {
        super(internalEventBus);
        this.api = api;
        this.itemManager = itemManager;
        this.syncService = syncService;
        this.encryptor = encryptor;
        this.challengor = challengor;
        this.alertService = alertService;
        this.crypto = crypto;
        this.internalEventBus = internalEventBus;
        this.backupsService = backupsService;
        this.encryptedCache = new files_1.FileMemoryCache(OneHundredMb);
    }
    deinit() {
        super.deinit();
        this.encryptedCache.clear();
        this.encryptedCache = undefined;
        this.api = undefined;
        this.itemManager = undefined;
        this.encryptor = undefined;
        this.syncService = undefined;
        this.alertService = undefined;
        this.challengor = undefined;
        this.crypto = undefined;
    }
    minimumChunkSize() {
        return 5000000;
    }
    async beginNewFileUpload(sizeInBytes) {
        const remoteIdentifier = utils_1.UuidGenerator.GenerateUuid();
        const tokenResult = await this.api.createFileValetToken(remoteIdentifier, 'write', sizeInBytes);
        if (tokenResult instanceof responses_1.ClientDisplayableError) {
            return tokenResult;
        }
        const key = this.crypto.generateRandomKey(models_1.FileProtocolV1Constants.KeySize);
        const fileParams = {
            key,
            remoteIdentifier,
            decryptedSize: sizeInBytes,
        };
        const uploadOperation = new files_1.EncryptAndUploadFileOperation(fileParams, tokenResult, this.crypto, this.api);
        const uploadSessionStarted = await this.api.startUploadSession(tokenResult);
        if ((0, responses_1.isErrorResponse)(uploadSessionStarted) || !uploadSessionStarted.data.uploadId) {
            return new responses_1.ClientDisplayableError('Could not start upload session');
        }
        return uploadOperation;
    }
    async pushBytesForUpload(operation, bytes, chunkId, isFinalChunk) {
        const success = await operation.pushBytes(bytes, chunkId, isFinalChunk);
        if (!success) {
            return new responses_1.ClientDisplayableError('Failed to push file bytes to server');
        }
        return undefined;
    }
    async finishUpload(operation, fileMetadata) {
        const uploadSessionClosed = await this.api.closeUploadSession(operation.getApiToken());
        if (!uploadSessionClosed) {
            return new responses_1.ClientDisplayableError('Could not close upload session');
        }
        const result = operation.getResult();
        const fileContent = {
            decryptedSize: result.finalDecryptedSize,
            encryptedChunkSizes: operation.encryptedChunkSizes,
            encryptionHeader: result.encryptionHeader,
            key: result.key,
            mimeType: fileMetadata.mimeType,
            name: fileMetadata.name,
            remoteIdentifier: result.remoteIdentifier,
        };
        const file = await this.itemManager.createItem(common_1.ContentType.File, (0, models_1.FillItemContentSpecialized)(fileContent), true);
        await this.syncService.sync();
        return file;
    }
    async decryptCachedEntry(file, entry) {
        const decryptOperation = new files_1.FileDecryptor(file, this.crypto);
        let decryptedAggregate = new Uint8Array();
        const orderedChunker = new files_1.OrderedByteChunker(file.encryptedChunkSizes, 'memcache', async (chunk) => {
            const decryptedBytes = decryptOperation.decryptBytes(chunk.data);
            if (decryptedBytes) {
                decryptedAggregate = new Uint8Array([...decryptedAggregate, ...decryptedBytes.decryptedBytes]);
            }
        });
        await orderedChunker.addBytes(entry.encryptedBytes);
        return { decryptedBytes: decryptedAggregate };
    }
    async downloadFile(file, onDecryptedBytes) {
        var _a;
        const cachedBytes = this.encryptedCache.get(file.uuid);
        if (cachedBytes) {
            const decryptedBytes = await this.decryptCachedEntry(file, cachedBytes);
            await onDecryptedBytes(decryptedBytes.decryptedBytes, {
                encryptedFileSize: cachedBytes.encryptedBytes.length,
                encryptedBytesDownloaded: cachedBytes.encryptedBytes.length,
                encryptedBytesRemaining: 0,
                percentComplete: 100,
                source: 'memcache',
            });
            return undefined;
        }
        const fileBackup = await ((_a = this.backupsService) === null || _a === void 0 ? void 0 : _a.getFileBackupInfo(file));
        if (this.backupsService && fileBackup) {
            (0, Logging_1.log)(Logging_1.LoggingDomain.FilesService, 'Downloading file from backup', fileBackup);
            await (0, files_1.readAndDecryptBackupFileUsingBackupService)(file, this.backupsService, this.crypto, async (chunk) => {
                (0, Logging_1.log)(Logging_1.LoggingDomain.FilesService, 'Got local file chunk', chunk.progress);
                return onDecryptedBytes(chunk.data, chunk.progress);
            });
            (0, Logging_1.log)(Logging_1.LoggingDomain.FilesService, 'Finished downloading file from backup');
            return undefined;
        }
        else {
            (0, Logging_1.log)(Logging_1.LoggingDomain.FilesService, 'Downloading file from network');
            const addToCache = file.encryptedSize < this.encryptedCache.maxSize;
            let cacheEntryAggregate = new Uint8Array();
            const operation = new files_1.DownloadAndDecryptFileOperation(file, this.crypto, this.api);
            const result = await operation.run(async ({ decrypted, encrypted, progress }) => {
                if (addToCache) {
                    cacheEntryAggregate = new Uint8Array([...cacheEntryAggregate, ...encrypted.encryptedBytes]);
                }
                return onDecryptedBytes(decrypted.decryptedBytes, progress);
            });
            if (addToCache && cacheEntryAggregate.byteLength > 0) {
                this.encryptedCache.add(file.uuid, { encryptedBytes: cacheEntryAggregate });
            }
            return result.error;
        }
    }
    async deleteFile(file) {
        var _a, _b;
        this.encryptedCache.remove(file.uuid);
        const tokenResult = await this.api.createFileValetToken(file.remoteIdentifier, 'delete');
        if (tokenResult instanceof responses_1.ClientDisplayableError) {
            return tokenResult;
        }
        const result = await this.api.deleteFile(tokenResult);
        if ((_a = result.data) === null || _a === void 0 ? void 0 : _a.error) {
            const deleteAnyway = await this.alertService.confirm((0, utils_1.spaceSeparatedStrings)('This file could not be deleted from the server, possibly because you are attempting to delete a file item', 'that was imported from another account. Would you like to remove this file item from your account anyway?', "If you're sure the file is yours and still exists on the server, do not choose this option,", 'and instead try to delete it again.'), 'Unable to Delete', 'Delete Anyway', AlertService_1.ButtonType.Danger);
            if (!deleteAnyway) {
                return responses_1.ClientDisplayableError.FromError((_b = result.data) === null || _b === void 0 ? void 0 : _b.error);
            }
        }
        await this.itemManager.setItemToBeDeleted(file);
        await this.syncService.sync();
        return undefined;
    }
    isFileNameFileBackupRelated(name) {
        if (name === files_1.FileBackupsConstantsV1.MetadataFileName) {
            return 'metadata';
        }
        else if (name === files_1.FileBackupsConstantsV1.BinaryFileName) {
            return 'binary';
        }
        return false;
    }
    async decryptBackupMetadataFile(metdataFile) {
        const encryptedItemsKey = new models_1.EncryptedPayload({
            ...metdataFile.itemsKey,
            waitingForKey: false,
            errorDecrypting: false,
        });
        const decryptedItemsKeyResult = await (0, Functions_1.DecryptItemsKeyWithUserFallback)(encryptedItemsKey, this.encryptor, this.challengor);
        if (decryptedItemsKeyResult === 'failed' || decryptedItemsKeyResult === 'aborted') {
            return undefined;
        }
        const encryptedFile = new models_1.EncryptedPayload({ ...metdataFile.file, waitingForKey: false, errorDecrypting: false });
        const itemsKey = new encryption_1.SNItemsKey(decryptedItemsKeyResult);
        const decryptedFile = await this.encryptor.decryptSplitSingle({
            usesItemsKey: {
                items: [encryptedFile],
                key: itemsKey,
            },
        });
        if ((0, models_1.isEncryptedPayload)(decryptedFile)) {
            return undefined;
        }
        return new models_1.FileItem(decryptedFile);
    }
    async selectFile(fileSystem) {
        const result = await fileSystem.selectFile();
        return result;
    }
    async readBackupFileAndSaveDecrypted(fileHandle, file, fileSystem) {
        const destinationDirectoryHandle = await fileSystem.selectDirectory();
        if (destinationDirectoryHandle === 'aborted' || destinationDirectoryHandle === 'failed') {
            return destinationDirectoryHandle;
        }
        const destinationFileHandle = await fileSystem.createFile(destinationDirectoryHandle, file.name);
        if (destinationFileHandle === 'aborted' || destinationFileHandle === 'failed') {
            return destinationFileHandle;
        }
        const result = await (0, files_1.readAndDecryptBackupFileUsingFileSystemAPI)(fileHandle, file, fileSystem, this.crypto, async (decryptedBytes) => {
            await fileSystem.saveBytes(destinationFileHandle, decryptedBytes);
        });
        await fileSystem.closeFileWriteStream(destinationFileHandle);
        return result;
    }
    async readBackupFileBytesDecrypted(fileHandle, file, fileSystem) {
        let bytes = new Uint8Array();
        await (0, files_1.readAndDecryptBackupFileUsingFileSystemAPI)(fileHandle, file, fileSystem, this.crypto, async (decryptedBytes) => {
            bytes = new Uint8Array([...bytes, ...decryptedBytes]);
        });
        return bytes;
    }
}
exports.FileService = FileService;
