"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SmartViewDefaultIconName = exports.systemViewIcon = exports.SmartViewIcons = void 0;
const SystemViewId_1 = require("./SystemViewId");
exports.SmartViewIcons = {
    [SystemViewId_1.SystemViewId.AllNotes]: 'notes',
    [SystemViewId_1.SystemViewId.Files]: 'folder',
    [SystemViewId_1.SystemViewId.ArchivedNotes]: 'archive',
    [SystemViewId_1.SystemViewId.TrashedNotes]: 'trash',
    [SystemViewId_1.SystemViewId.UntaggedNotes]: 'hashtag-off',
    [SystemViewId_1.SystemViewId.StarredNotes]: 'star-filled',
};
function systemViewIcon(id) {
    return exports.SmartViewIcons[id];
}
exports.systemViewIcon = systemViewIcon;
exports.SmartViewDefaultIconName = 'restore';
