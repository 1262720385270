"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemOption = void 0;
const LexicalTypeaheadMenuPlugin_1 = require("@lexical/react/LexicalTypeaheadMenuPlugin");
class ItemOption extends LexicalTypeaheadMenuPlugin_1.TypeaheadOption {
    constructor(item, label, options) {
        super(label || '');
        this.item = item;
        this.label = label;
        this.options = options;
        this.key = (item === null || item === void 0 ? void 0 : item.uuid) || label;
    }
}
exports.ItemOption = ItemOption;
