"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopManager = void 0;
const HeadlessSuperConverter_1 = require("@/Components/SuperEditor/Tools/HeadlessSuperConverter");
const snjs_1 = require("@standardnotes/snjs");
class DesktopManager extends snjs_1.ApplicationService {
    constructor(application, device, backups) {
        super(application, new snjs_1.InternalEventBus());
        this.device = device;
        this.backups = backups;
        this.updateObservers = [];
        this.dataLoaded = false;
        this.needsInitialTextBackup = false;
        const markdownConverter = new HeadlessSuperConverter_1.HeadlessSuperConverter();
        backups.setSuperConverter(markdownConverter);
    }
    async handleWatchedDirectoriesChanges(changes) {
        void this.backups.importWatchedDirectoryChanges(changes);
    }
    beginTextBackupsTimer() {
        if (this.textBackupsInterval) {
            clearInterval(this.textBackupsInterval);
        }
        this.needsInitialTextBackup = true;
        const hoursInterval = 12;
        const seconds = hoursInterval * 60 * 60;
        const milliseconds = seconds * 1000;
        this.textBackupsInterval = setInterval(this.saveDesktopBackup, milliseconds);
    }
    get webApplication() {
        return this.application;
    }
    deinit() {
        this.updateObservers.length = 0;
        super.deinit();
    }
    async onAppEvent(eventName) {
        super.onAppEvent(eventName).catch(console.error);
        if (eventName === snjs_1.ApplicationEvent.LocalDataLoaded) {
            this.dataLoaded = true;
            if (this.backups.isTextBackupsEnabled()) {
                this.beginTextBackupsTimer();
            }
        }
        else if (eventName === snjs_1.ApplicationEvent.MajorDataChange) {
            void this.saveDesktopBackup();
        }
    }
    async saveDesktopBackup() {
        var _a;
        this.webApplication.notifyWebEvent(snjs_1.WebAppEvent.BeganBackupDownload);
        const data = await this.getBackupFile();
        if (data) {
            await ((_a = this.webApplication.fileBackups) === null || _a === void 0 ? void 0 : _a.saveTextBackupData(data));
            this.webApplication.notifyWebEvent(snjs_1.WebAppEvent.EndedBackupDownload, { success: true });
        }
    }
    async getBackupFile() {
        const encrypted = this.application.hasProtectionSources();
        const data = encrypted
            ? await this.application.createEncryptedBackupFileForAutomatedDesktopBackups()
            : await this.application.createDecryptedBackupFile();
        if (data) {
            return JSON.stringify(data, null, 2);
        }
        return undefined;
    }
    getExtServerHost() {
        (0, snjs_1.assert)(this.device.extensionsServerHost);
        return this.device.extensionsServerHost;
    }
    /**
     * Sending a component in its raw state is really slow for the desktop app
     * Keys are not passed into ItemParams, so the result is not encrypted
     */
    convertComponentForTransmission(component) {
        return component.payloadRepresentation().ejected();
    }
    syncComponentsInstallation(components) {
        Promise.all(components.map((component) => {
            return this.convertComponentForTransmission(component);
        }))
            .then((payloads) => {
            this.device.syncComponents(payloads);
        })
            .catch(console.error);
    }
    registerUpdateObserver(callback) {
        const observer = {
            callback: callback,
        };
        this.updateObservers.push(observer);
        return () => {
            (0, snjs_1.removeFromArray)(this.updateObservers, observer);
        };
    }
    searchText(text) {
        this.lastSearchedText = text;
        this.device.onSearch(text);
    }
    redoSearch() {
        if (this.lastSearchedText) {
            this.searchText(this.lastSearchedText);
        }
    }
    updateAvailable() {
        this.webApplication.notifyWebEvent(snjs_1.WebAppEvent.NewUpdateAvailable);
    }
    windowGainedFocus() {
        this.webApplication.notifyWebEvent(snjs_1.WebAppEvent.WindowDidFocus);
    }
    windowLostFocus() {
        this.webApplication.notifyWebEvent(snjs_1.WebAppEvent.WindowDidBlur);
        if (this.needsInitialTextBackup) {
            this.needsInitialTextBackup = false;
            void this.saveDesktopBackup();
        }
    }
    async onComponentInstallationComplete(componentData) {
        const component = this.application.items.findItem(componentData.uuid);
        if (!component) {
            return;
        }
        const updatedComponent = await this.application.mutator.changeAndSaveItem(component, (m) => {
            const mutator = m;
            // eslint-disable-next-line camelcase
            mutator.local_url = componentData.content.local_url;
            // eslint-disable-next-line camelcase
            mutator.package_info = componentData.content.package_info;
            mutator.setAppDataItem(snjs_1.AppDataField.ComponentInstallError, undefined);
        }, undefined);
        for (const observer of this.updateObservers) {
            observer.callback(updatedComponent);
        }
    }
}
exports.DesktopManager = DesktopManager;
