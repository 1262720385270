"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PayloadsByAlternatingUuid = void 0;
const DeletedPayload_1 = require("../../Abstract/Payload/Implementations/DeletedPayload");
const common_1 = require("@standardnotes/common");
const utils_1 = require("@standardnotes/utils");
const TypeCheck_1 = require("../../Abstract/Payload/Interfaces/TypeCheck");
const PayloadsByUpdatingReferencingPayloadReferences_1 = require("./PayloadsByUpdatingReferencingPayloadReferences");
const DirtyCounter_1 = require("../../Runtime/DirtyCounter/DirtyCounter");
/**
 * Return the payloads that result if you alternated the uuid for the payload.
 * Alternating a UUID involves instructing related items to drop old references of a uuid
 * for the new one.
 * @returns An array of payloads that have changed as a result of copying.
 */
function PayloadsByAlternatingUuid(payload, baseCollection) {
    const results = [];
    /**
     * We need to clone payload and give it a new uuid,
     * then delete item with old uuid from db (cannot modify uuids in our IndexedDB setup)
     */
    const copy = payload.copyAsSyncResolved({
        uuid: utils_1.UuidGenerator.GenerateUuid(),
        dirty: true,
        dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
        lastSyncBegan: undefined,
        lastSyncEnd: new Date(),
        duplicate_of: payload.uuid,
    });
    results.push(copy);
    /**
     * Get the payloads that make reference to payload and remove
     * payload as a relationship, instead adding the new copy.
     */
    const updatedReferencing = (0, PayloadsByUpdatingReferencingPayloadReferences_1.PayloadsByUpdatingReferencingPayloadReferences)(payload, baseCollection, [copy], [payload.uuid]);
    (0, utils_1.extendArray)(results, updatedReferencing);
    if (payload.content_type === common_1.ContentType.ItemsKey) {
        /**
         * Update any payloads who are still encrypted and whose items_key_id point to this uuid
         */
        const matchingPayloads = baseCollection
            .all()
            .filter((p) => (0, TypeCheck_1.isEncryptedPayload)(p) && p.items_key_id === payload.uuid);
        const adjustedPayloads = matchingPayloads.map((a) => a.copyAsSyncResolved({
            items_key_id: copy.uuid,
            dirty: true,
            dirtyIndex: (0, DirtyCounter_1.getIncrementedDirtyIndex)(),
            lastSyncEnd: new Date(),
        }));
        if (adjustedPayloads.length > 0) {
            (0, utils_1.extendArray)(results, adjustedPayloads);
        }
    }
    const deletedSelf = new DeletedPayload_1.DeletedPayload({
        created_at: payload.created_at,
        updated_at: payload.updated_at,
        created_at_timestamp: payload.created_at_timestamp,
        updated_at_timestamp: payload.updated_at_timestamp,
        /**
         * Do not set as dirty; this item is non-syncable
         * and should be immediately discarded
         */
        dirty: false,
        content: undefined,
        uuid: payload.uuid,
        content_type: payload.content_type,
        deleted: true,
    }, payload.source);
    results.push(deletedSelf);
    return results;
}
exports.PayloadsByAlternatingUuid = PayloadsByAlternatingUuid;
