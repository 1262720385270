"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemCounter = void 0;
const common_1 = require("@standardnotes/common");
class ItemCounter {
    countNotesAndTags(items) {
        const counts = {
            notes: 0,
            archived: 0,
            deleted: 0,
            tags: 0,
        };
        for (const item of items) {
            if (item.trashed) {
                counts.deleted++;
                continue;
            }
            if (item.archived) {
                counts.archived++;
                continue;
            }
            if (item.content_type === common_1.ContentType.Note) {
                counts.notes++;
                continue;
            }
            if (item.content_type === common_1.ContentType.Tag) {
                counts.tags++;
                continue;
            }
        }
        return counts;
    }
}
exports.ItemCounter = ItemCounter;
