"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PreferencesController = void 0;
const mobx_1 = require("mobx");
const ui_services_1 = require("@standardnotes/ui-services");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
const DEFAULT_PANE = 'account';
class PreferencesController extends AbstractViewController_1.AbstractViewController {
    constructor(application, eventBus) {
        super(application, eventBus);
        this._open = false;
        this.currentPane = DEFAULT_PANE;
        this.setCurrentPane = (prefId) => {
            this.currentPane = prefId;
        };
        this.openPreferences = () => {
            this._open = true;
        };
        this.closePreferences = () => {
            this._open = false;
            this.currentPane = DEFAULT_PANE;
            this.application.routeService.removeQueryParameterFromURL(ui_services_1.RootQueryParam.Settings);
        };
        (0, mobx_1.makeObservable)(this, {
            _open: mobx_1.observable,
            currentPane: mobx_1.observable,
            openPreferences: mobx_1.action,
            closePreferences: mobx_1.action,
            setCurrentPane: mobx_1.action,
            isOpen: mobx_1.computed,
        });
    }
    get isOpen() {
        return this._open;
    }
}
exports.PreferencesController = PreferencesController;
