"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Importer = void 0;
const filepicker_1 = require("@standardnotes/filepicker");
const services_1 = require("@standardnotes/services");
const features_1 = require("@standardnotes/features");
const AegisToAuthenticatorConverter_1 = require("./AegisConverter/AegisToAuthenticatorConverter");
const EvernoteConverter_1 = require("./EvernoteConverter/EvernoteConverter");
const GoogleKeepConverter_1 = require("./GoogleKeepConverter/GoogleKeepConverter");
const PlaintextConverter_1 = require("./PlaintextConverter/PlaintextConverter");
const SimplenoteConverter_1 = require("./SimplenoteConverter/SimplenoteConverter");
const Utils_1 = require("./Utils");
class Importer {
    constructor(application) {
        this.application = application;
        this.aegisConverter = new AegisToAuthenticatorConverter_1.AegisToAuthenticatorConverter(application);
        this.googleKeepConverter = new GoogleKeepConverter_1.GoogleKeepConverter(application);
        this.simplenoteConverter = new SimplenoteConverter_1.SimplenoteConverter(application);
        this.plaintextConverter = new PlaintextConverter_1.PlaintextConverter(application);
        this.evernoteConverter = new EvernoteConverter_1.EvernoteConverter(application);
    }
    async getPayloadsFromFile(file, type) {
        if (type === 'aegis') {
            const isEntitledToAuthenticator = this.application.features.getFeatureStatus(features_1.FeatureIdentifier.TokenVaultEditor) === services_1.FeatureStatus.Entitled;
            return [await this.aegisConverter.convertAegisBackupFileToNote(file, isEntitledToAuthenticator)];
        }
        else if (type === 'google-keep') {
            return [await this.googleKeepConverter.convertGoogleKeepBackupFileToNote(file, true)];
        }
        else if (type === 'simplenote') {
            return await this.simplenoteConverter.convertSimplenoteBackupFileToNotes(file);
        }
        else if (type === 'evernote') {
            return await this.evernoteConverter.convertENEXFileToNotesAndTags(file, false);
        }
        else if (type === 'plaintext') {
            return [await this.plaintextConverter.convertPlaintextFileToNote(file)];
        }
        return [];
    }
    async importFromTransferPayloads(payloads) {
        const insertedItems = await Promise.all(payloads.map(async (payload) => {
            const content = payload.content;
            const note = this.application.mutator.createTemplateItem(payload.content_type, {
                text: content.text,
                title: content.title,
                noteType: content.noteType,
                editorIdentifier: content.editorIdentifier,
                references: content.references,
            }, {
                created_at: payload.created_at,
                updated_at: payload.updated_at,
                uuid: payload.uuid,
            });
            return this.application.mutator.insertItem(note);
        }));
        return insertedItems;
    }
}
_a = Importer;
Importer.detectService = async (file) => {
    const content = await (0, Utils_1.readFileAsText)(file);
    const { ext } = (0, filepicker_1.parseFileName)(file.name);
    if (ext === 'enex') {
        return 'evernote';
    }
    try {
        const json = JSON.parse(content);
        if (AegisToAuthenticatorConverter_1.AegisToAuthenticatorConverter.isValidAegisJson(json)) {
            return 'aegis';
        }
        if (GoogleKeepConverter_1.GoogleKeepConverter.isValidGoogleKeepJson(json)) {
            return 'google-keep';
        }
        if (SimplenoteConverter_1.SimplenoteConverter.isValidSimplenoteJson(json)) {
            return 'simplenote';
        }
    }
    catch {
        /* empty */
    }
    if (PlaintextConverter_1.PlaintextConverter.isValidPlaintextFile(file)) {
        return 'plaintext';
    }
    return null;
};
exports.Importer = Importer;
