"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureStatus = void 0;
var FeatureStatus;
(function (FeatureStatus) {
    FeatureStatus["NoUserSubscription"] = "NoUserSubscription";
    FeatureStatus["NotInCurrentPlan"] = "NotInCurrentPlan";
    FeatureStatus["InCurrentPlanButExpired"] = "InCurrentPlanButExpired";
    FeatureStatus["Entitled"] = "Entitled";
})(FeatureStatus = exports.FeatureStatus || (exports.FeatureStatus = {}));
