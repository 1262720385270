"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebApplication = void 0;
const Crypto_1 = require("@/Application/Crypto");
const ViewControllerManager_1 = require("@/Controllers/ViewControllerManager");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const browser_1 = require("@simplewebauthn/browser");
const Utils_1 = require("@/Utils");
const DesktopManager_1 = require("./Device/DesktopManager");
const ui_services_1 = require("@standardnotes/ui-services");
const MobileWebReceiver_1 = require("../NativeMobileWeb/MobileWebReceiver");
const AndroidBackHandler_1 = require("@/NativeMobileWeb/AndroidBackHandler");
const PrefDefaults_1 = require("@/Constants/PrefDefaults");
const setViewportHeightWithFallback_1 = require("@/setViewportHeightWithFallback");
const ItemGroupController_1 = require("@/Components/NoteView/Controller/ItemGroupController");
const VisibilityObserver_1 = require("./VisibilityObserver");
const MomentsService_1 = require("@/Controllers/Moments/MomentsService");
class WebApplication extends snjs_1.SNApplication {
    constructor(deviceInterface, platform, identifier, defaultSyncServerHost, webSocketUrl) {
        super({
            environment: deviceInterface.environment,
            platform: platform,
            deviceInterface: deviceInterface,
            crypto: Crypto_1.WebCrypto,
            alertService: new ui_services_1.WebAlertService(),
            identifier,
            defaultHost: defaultSyncServerHost,
            appVersion: deviceInterface.appVersion,
            webSocketUrl: webSocketUrl,
            loadBatchSize: deviceInterface.environment === snjs_1.Environment.Mobile ? 250 : snjs_1.ApplicationOptionsDefaults.loadBatchSize,
            sleepBetweenBatches: deviceInterface.environment === snjs_1.Environment.Mobile ? 250 : snjs_1.ApplicationOptionsDefaults.sleepBetweenBatches,
            allowMultipleSelection: deviceInterface.environment !== snjs_1.Environment.Mobile,
            allowNoteSelectionStatePersistence: deviceInterface.environment !== snjs_1.Environment.Mobile,
            u2fAuthenticatorRegistrationPromptFunction: browser_1.startRegistration,
            u2fAuthenticatorVerificationPromptFunction: browser_1.startAuthentication,
        });
        this.webEventObservers = [];
        this.addNativeMobileEventListener = (listener) => {
            if (!this.mobileWebReceiver) {
                return;
            }
            return this.mobileWebReceiver.addReactListener(listener);
        };
        (0, mobx_1.makeObservable)(this, {
            dealloced: mobx_1.observable,
        });
        deviceInterface.setApplication(this);
        this.itemControllerGroup = new ItemGroupController_1.ItemGroupController(this);
        this.routeService = new ui_services_1.RouteService(this, this.internalEventBus);
        this.webServices = {};
        this.webServices.keyboardService = new ui_services_1.KeyboardService(platform, this.environment);
        this.webServices.archiveService = new ui_services_1.ArchiveManager(this);
        this.webServices.themeService = new ui_services_1.ThemeManager(this, this.internalEventBus);
        this.webServices.autolockService = this.isNativeMobileWeb()
            ? undefined
            : new ui_services_1.AutolockService(this, this.internalEventBus);
        this.webServices.desktopService = (0, snjs_1.isDesktopDevice)(deviceInterface)
            ? new DesktopManager_1.DesktopManager(this, deviceInterface, this.fileBackups)
            : undefined;
        this.webServices.viewControllerManager = new ViewControllerManager_1.ViewControllerManager(this, deviceInterface);
        this.webServices.changelogService = new ui_services_1.ChangelogService(this.environment, this.storage);
        this.webServices.momentsService = new MomentsService_1.MomentsService(this, this.webServices.viewControllerManager.filesController, this.internalEventBus);
        if (this.isNativeMobileWeb()) {
            this.mobileWebReceiver = new MobileWebReceiver_1.MobileWebReceiver(this);
            this.androidBackHandler = new AndroidBackHandler_1.AndroidBackHandler();
            // eslint-disable-next-line no-console
            console.log = (...args) => {
                this.mobileDevice().consoleLog(...args);
            };
        }
        if (!(0, Utils_1.isDesktopApplication)()) {
            this.visibilityObserver = new VisibilityObserver_1.VisibilityObserver((event) => {
                this.notifyWebEvent(event);
            });
        }
    }
    deinit(mode, source) {
        var _a;
        super.deinit(mode, source);
        try {
            for (const service of Object.values(this.webServices)) {
                if (!service) {
                    continue;
                }
                if ('deinit' in service) {
                    (_a = service.deinit) === null || _a === void 0 ? void 0 : _a.call(service, source);
                }
                ;
                service.application = undefined;
            }
            this.webServices = {};
            this.itemControllerGroup.deinit();
            this.itemControllerGroup = undefined;
            this.mobileWebReceiver = undefined;
            this.routeService.deinit();
            this.routeService = undefined;
            this.webEventObservers.length = 0;
            if (this.visibilityObserver) {
                this.visibilityObserver.deinit();
                this.visibilityObserver = undefined;
            }
        }
        catch (error) {
            console.error('Error while deiniting application', error);
        }
    }
    addWebEventObserver(observer) {
        this.webEventObservers.push(observer);
        return () => {
            (0, snjs_1.removeFromArray)(this.webEventObservers, observer);
        };
    }
    notifyWebEvent(event, data) {
        for (const observer of this.webEventObservers) {
            observer(event, data);
        }
        this.internalEventBus.publish({ type: event, payload: data });
    }
    publishPanelDidResizeEvent(name, width, collapsed) {
        const data = {
            panel: name,
            collapsed,
            width,
        };
        this.notifyWebEvent(snjs_1.WebAppEvent.PanelResized, data);
    }
    getViewControllerManager() {
        return this.webServices.viewControllerManager;
    }
    getDesktopService() {
        return this.webServices.desktopService;
    }
    getAutolockService() {
        return this.webServices.autolockService;
    }
    getArchiveService() {
        return this.webServices.archiveService;
    }
    get paneController() {
        return this.webServices.viewControllerManager.paneController;
    }
    get linkingController() {
        return this.webServices.viewControllerManager.linkingController;
    }
    get changelogService() {
        return this.webServices.changelogService;
    }
    get momentsService() {
        return this.webServices.momentsService;
    }
    get featuresController() {
        return this.getViewControllerManager().featuresController;
    }
    get desktopDevice() {
        if ((0, snjs_1.isDesktopDevice)(this.deviceInterface)) {
            return this.deviceInterface;
        }
        return undefined;
    }
    isNativeIOS() {
        return this.isNativeMobileWeb() && this.platform === snjs_1.Platform.Ios;
    }
    get isMobileDevice() {
        return this.isNativeMobileWeb() || (0, Utils_1.isIOS)() || (0, Utils_1.isAndroid)();
    }
    get hideOutboundSubscriptionLinks() {
        return this.isNativeIOS();
    }
    mobileDevice() {
        if (!this.isNativeMobileWeb()) {
            throw Error('Attempting to access device as mobile device on non mobile platform');
        }
        return this.deviceInterface;
    }
    getThemeService() {
        return this.webServices.themeService;
    }
    get keyboardService() {
        return this.webServices.keyboardService;
    }
    async checkForSecurityUpdate() {
        return this.protocolUpgradeAvailable();
    }
    performDesktopTextBackup() {
        var _a;
        return (_a = this.getDesktopService()) === null || _a === void 0 ? void 0 : _a.saveDesktopBackup();
    }
    isGlobalSpellcheckEnabled() {
        return this.getPreference(snjs_1.PrefKey.EditorSpellcheck, PrefDefaults_1.PrefDefaults[snjs_1.PrefKey.EditorSpellcheck]);
    }
    getItemTags(item) {
        return this.items.itemsReferencingItem(item).filter((ref) => {
            return ref.content_type === snjs_1.ContentType.Tag;
        });
    }
    get version() {
        return this.deviceInterface.appVersion;
    }
    async toggleGlobalSpellcheck() {
        const currentValue = this.isGlobalSpellcheckEnabled();
        return this.setPreference(snjs_1.PrefKey.EditorSpellcheck, !currentValue);
    }
    async handleMobileEnteringBackgroundEvent() {
        await this.lockApplicationAfterMobileEventIfApplicable();
    }
    async handleMobileGainingFocusEvent() {
        /** Optional override */
    }
    handleInitialMobileScreenshotPrivacy() {
        if (this.platform !== snjs_1.Platform.Android) {
            return;
        }
        if (this.protections.getMobileScreenshotPrivacyEnabled()) {
            this.mobileDevice().setAndroidScreenshotPrivacy(true);
        }
        else {
            this.mobileDevice().setAndroidScreenshotPrivacy(false);
        }
    }
    async handleMobileLosingFocusEvent() {
        if (this.protections.getMobileScreenshotPrivacyEnabled()) {
            this.mobileDevice().stopHidingMobileInterfaceFromScreenshots();
        }
        await this.lockApplicationAfterMobileEventIfApplicable();
    }
    async handleMobileResumingFromBackgroundEvent() {
        if (this.protections.getMobileScreenshotPrivacyEnabled()) {
            this.mobileDevice().hideMobileInterfaceFromScreenshots();
        }
    }
    handleMobileColorSchemeChangeEvent() {
        void this.getThemeService().handleMobileColorSchemeChangeEvent();
    }
    handleMobileKeyboardWillChangeFrameEvent(frame) {
        (0, setViewportHeightWithFallback_1.setCustomViewportHeight)(frame.contentHeight, 'px', true);
        this.notifyWebEvent(snjs_1.WebAppEvent.MobileKeyboardWillChangeFrame, frame);
    }
    handleMobileKeyboardDidChangeFrameEvent(frame) {
        this.notifyWebEvent(snjs_1.WebAppEvent.MobileKeyboardDidChangeFrame, frame);
    }
    async lockApplicationAfterMobileEventIfApplicable() {
        const isLocked = await this.isLocked();
        if (isLocked) {
            return;
        }
        const hasBiometrics = this.protections.hasBiometricsEnabled();
        const hasPasscode = this.hasPasscode();
        const passcodeTiming = this.protections.getMobilePasscodeTiming();
        const biometricsTiming = this.protections.getMobileBiometricsTiming();
        const passcodeLockImmediately = hasPasscode && passcodeTiming === snjs_1.MobileUnlockTiming.Immediately;
        const biometricsLockImmediately = hasBiometrics && biometricsTiming === snjs_1.MobileUnlockTiming.Immediately;
        if (passcodeLockImmediately) {
            await this.lock();
        }
        else if (biometricsLockImmediately) {
            this.softLockBiometrics();
        }
    }
    handleAndroidBackButtonPressed() {
        if (typeof this.androidBackHandler !== 'undefined') {
            this.androidBackHandler.notifyEvent();
        }
    }
    addAndroidBackHandlerEventListener(listener) {
        if (typeof this.androidBackHandler !== 'undefined') {
            return this.androidBackHandler.addEventListener(listener);
        }
        return;
    }
    setAndroidBackHandlerFallbackListener(listener) {
        if (typeof this.androidBackHandler !== 'undefined') {
            this.androidBackHandler.setFallbackListener(listener);
        }
    }
    isAuthorizedToRenderItem(item) {
        if (item.protected && this.hasProtectionSources()) {
            return this.hasUnprotectedAccessSession();
        }
        return true;
    }
    entitledToPerTagPreferences() {
        return this.hasValidSubscription();
    }
    get entitledToFiles() {
        return this.getViewControllerManager().featuresController.entitledToFiles;
    }
    showPremiumModal(featureName) {
        void this.getViewControllerManager().featuresController.showPremiumAlert(featureName);
    }
    hasValidSubscription() {
        return this.getViewControllerManager().subscriptionController.hasValidSubscription();
    }
    async openPurchaseFlow() {
        await this.getViewControllerManager().purchaseFlowController.openPurchaseFlow();
    }
    showAccountMenu() {
        this.getViewControllerManager().accountMenuController.setShow(true);
    }
    hideAccountMenu() {
        this.getViewControllerManager().accountMenuController.setShow(false);
    }
    /**
     * Full U2F clients are only web browser clients. They support adding and removing keys as well as authentication.
     * The desktop and mobile clients cannot support adding keys.
     */
    get isFullU2FClient() {
        return this.environment === snjs_1.Environment.Web;
    }
    geDefaultEditorIdentifier(currentTag) {
        var _a, _b;
        return (((_a = currentTag === null || currentTag === void 0 ? void 0 : currentTag.preferences) === null || _a === void 0 ? void 0 : _a.editorIdentifier) ||
            this.getPreference(snjs_1.PrefKey.DefaultEditorIdentifier) ||
            ((_b = this.componentManager.legacyGetDefaultEditor()) === null || _b === void 0 ? void 0 : _b.identifier) ||
            snjs_1.FeatureIdentifier.PlainEditor);
    }
    openPreferences(pane) {
        this.getViewControllerManager().preferencesController.openPreferences();
        if (pane) {
            this.getViewControllerManager().preferencesController.setCurrentPane(pane);
        }
    }
    generateUUID() {
        return this.options.crypto.generateUUID();
    }
}
exports.WebApplication = WebApplication;
