"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FillThemeComponentDefaults = void 0;
const common_1 = require("@standardnotes/common");
const ComponentArea_1 = require("../../Component/ComponentArea");
function FillThemeComponentDefaults(theme) {
    if (!theme.index_path) {
        theme.index_path = 'index.css';
    }
    theme.content_type = common_1.ContentType.Theme;
    if (!theme.area) {
        theme.area = ComponentArea_1.ComponentArea.Themes;
    }
    return theme;
}
exports.FillThemeComponentDefaults = FillThemeComponentDefaults;
