"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNNote = exports.isNote = void 0;
const AppDataField_1 = require("./../../Abstract/Item/Types/AppDataField");
const common_1 = require("@standardnotes/common");
const features_1 = require("@standardnotes/features");
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const isNote = (x) => x.content_type === common_1.ContentType.Note;
exports.isNote = isNote;
class SNNote extends DecryptedItem_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.hidePreview = false;
        this.title = String(this.payload.content.title || '');
        this.text = String(this.payload.content.text || '');
        this.hidePreview = Boolean(this.payload.content.hidePreview);
        this.preview_plain = String(this.payload.content.preview_plain || '');
        this.preview_html = String(this.payload.content.preview_html || '');
        this.spellcheck = this.payload.content.spellcheck;
        this.editorWidth = this.payload.content.editorWidth;
        this.noteType = this.payload.content.noteType;
        this.editorIdentifier = this.payload.content.editorIdentifier;
        this.authorizedForListed = this.payload.content.authorizedForListed || false;
        if (!this.noteType) {
            const prefersPlain = this.getAppDomainValueWithDefault(AppDataField_1.AppDataField.LegacyPrefersPlainEditor, false);
            if (prefersPlain) {
                this.noteType = features_1.NoteType.Plain;
            }
        }
    }
}
exports.SNNote = SNNote;
