"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPositionedPopoverStyles = void 0;
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const Utils_1 = require("@/Utils");
const Collisions_1 = require("./Utils/Collisions");
const Rect_1 = require("./Utils/Rect");
const percentOf = (percent, value) => (percent / 100) * value;
const getStylesFromRect = (rect, options) => {
    const { disableMobileFullscreenTakeover = false, maxHeight = 'none' } = options;
    const canApplyMaxHeight = maxHeight !== 'none' && (!(0, Utils_1.isMobileScreen)() || disableMobileFullscreenTakeover);
    const shouldApplyMobileWidth = (0, Utils_1.isMobileScreen)() && disableMobileFullscreenTakeover;
    const marginForMobile = percentOf(10, window.innerWidth);
    return {
        willChange: 'transform',
        transform: `translate(${shouldApplyMobileWidth ? marginForMobile / 2 : rect.x}px, ${rect.y}px)`,
        visibility: 'visible',
        ...(canApplyMaxHeight && {
            maxHeight: `${maxHeight}px`,
        }),
        ...(shouldApplyMobileWidth && {
            width: `${window.innerWidth - marginForMobile}px`,
        }),
    };
};
const getPositionedPopoverStyles = ({ align, anchorRect, documentRect, popoverRect, side, disableMobileFullscreenTakeover, maxHeightFunction, offset, }) => {
    if (!popoverRect || !anchorRect) {
        return null;
    }
    const matchesMediumBreakpoint = matchMedia(useMediaQuery_1.MediaQueryBreakpoints.md).matches;
    if (!matchesMediumBreakpoint && !disableMobileFullscreenTakeover) {
        return null;
    }
    const rectForPreferredSide = (0, Rect_1.getPositionedPopoverRect)(popoverRect, anchorRect, side, align);
    const preferredSideRectCollisions = (0, Collisions_1.checkCollisions)(rectForPreferredSide, documentRect);
    const preferredSideOverflows = (0, Collisions_1.getOverflows)(rectForPreferredSide, documentRect);
    const oppositeSide = Collisions_1.OppositeSide[side];
    const rectForOppositeSide = (0, Rect_1.getPositionedPopoverRect)(popoverRect, anchorRect, oppositeSide, align);
    const oppositeSideOverflows = (0, Collisions_1.getOverflows)(rectForOppositeSide, documentRect);
    const sideWithLessOverflows = preferredSideOverflows[side] < oppositeSideOverflows[oppositeSide] ? side : oppositeSide;
    const finalAlignment = (0, Collisions_1.getNonCollidingAlignment)({
        finalSide: sideWithLessOverflows,
        preferredAlignment: align,
        collisions: preferredSideRectCollisions,
        popoverRect,
        buttonRect: anchorRect,
        documentRect,
    });
    const finalPositionedRect = (0, Rect_1.getPositionedPopoverRect)(popoverRect, anchorRect, sideWithLessOverflows, finalAlignment, offset);
    let maxHeight = (0, Rect_1.getPopoverMaxHeight)((0, Rect_1.getAppRect)(), anchorRect, sideWithLessOverflows, finalAlignment, disableMobileFullscreenTakeover);
    if (maxHeightFunction && typeof maxHeight === 'number') {
        maxHeight = maxHeightFunction(maxHeight);
    }
    return getStylesFromRect(finalPositionedRect, { disableMobileFullscreenTakeover, maxHeight });
};
exports.getPositionedPopoverStyles = getPositionedPopoverStyles;
