"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const icons_1 = require("@standardnotes/icons");
const toastStore_1 = require("./toastStore");
const enums_1 = require("./enums");
const react_1 = require("react");
const prefersReducedMotion = () => {
    const mediaQuery = matchMedia('(prefers-reduced-motion: reduce)');
    return mediaQuery.matches;
};
const colorForToastType = (type) => {
    switch (type) {
        case enums_1.ToastType.Success:
            return 'bg-success text-info-contrast md:text-success';
        case enums_1.ToastType.Error:
            return 'bg-danger text-info-contrast md:text-danger';
        default:
            return 'bg-info text-info-contrast md:text-info';
    }
};
const iconForToastType = (type) => {
    switch (type) {
        case enums_1.ToastType.Success:
            return (0, jsx_runtime_1.jsx)(icons_1.CheckCircleFilledIcon, { className: "text-success h-5 w-5" });
        case enums_1.ToastType.Error:
            return (0, jsx_runtime_1.jsx)(icons_1.ClearCircleFilledIcon, { className: "text-danger h-5 w-5" });
        case enums_1.ToastType.Progress:
        case enums_1.ToastType.Loading:
            return (0, jsx_runtime_1.jsx)("div", { className: "border-info h-4 w-4 animate-spin rounded-full border border-solid border-r-transparent" });
        default:
            return null;
    }
};
exports.Toast = (0, react_1.forwardRef)(({ toast, index }, ref) => {
    var _a;
    const icon = iconForToastType(toast.type);
    const hasActions = toast.actions && toast.actions.length > 0;
    const hasProgress = toast.type === enums_1.ToastType.Progress && toast.progress !== undefined && toast.progress > -1;
    const shouldReduceMotion = prefersReducedMotion();
    const enterAnimation = shouldReduceMotion ? 'fade-in-animation' : 'slide-in-right-animation';
    const exitAnimation = shouldReduceMotion ? 'fade-out-animation' : 'slide-out-left-animation';
    const currentAnimation = toast.dismissed ? exitAnimation : enterAnimation;
    (0, react_1.useEffect)(() => {
        if (!ref) {
            return;
        }
        const element = ref.current;
        if (element && toast.dismissed) {
            const { scrollHeight, style } = element;
            requestAnimationFrame(() => {
                style.minHeight = 'initial';
                style.height = scrollHeight + 'px';
                style.transition = 'all 200ms';
                requestAnimationFrame(() => {
                    style.height = '0';
                    style.padding = '0';
                    style.margin = '0';
                });
            });
        }
    }, [ref, toast.dismissed]);
    return ((0, jsx_runtime_1.jsxs)("div", { "data-index": index, role: "status", className: `bg-passive-5 animation-fill-forwards relative mt-3 flex min-w-full select-none flex-col rounded opacity-0 md:min-w-max ${currentAnimation}`, style: {
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.16)',
            transition: shouldReduceMotion ? undefined : 'all 0.2s ease',
            animationDelay: !toast.dismissed ? '50ms' : undefined,
        }, onClick: () => {
            if (!hasActions && toast.type !== enums_1.ToastType.Loading && toast.type !== enums_1.ToastType.Progress) {
                (0, toastStore_1.dismissToast)(toast.id);
            }
        }, ref: ref, children: [(0, jsx_runtime_1.jsxs)("div", { className: `flex w-full flex-wrap items-center gap-2 ${hasActions ? 'p-2 pl-3' : hasProgress ? 'px-3 py-2.5' : 'p-3'}`, children: [icon ? (0, jsx_runtime_1.jsx)("div", { className: "sn-icon flex flex-shrink-0 items-center justify-center", children: icon }) : null, (0, jsx_runtime_1.jsx)("div", { className: "text-text text-sm [word-wrap:anywhere]", children: toast.message }), hasActions && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (_a = toast.actions) === null || _a === void 0 ? void 0 : _a.map((action, index) => ((0, jsx_runtime_1.jsx)("button", { className: `hover:bg-passive-3 cursor-pointer rounded border-0 py-1 px-[0.45rem] text-sm font-semibold md:bg-transparent ${colorForToastType(toast.type)} ${index !== 0 ? 'ml-2' : ''}`, onClick: () => {
                                action.handler(toast.id);
                            }, children: action.label }, index))) }))] }), hasProgress && ((0, jsx_runtime_1.jsx)("div", { className: "bg-default w-full overflow-hidden rounded rounded-tl-none rounded-tr-none", children: (0, jsx_runtime_1.jsx)("div", { className: "bg-info h-2 rounded rounded-tl-none transition-[width] duration-100", role: "progressbar", style: {
                        width: `${toast.progress}%`,
                        ...(toast.progress === 100 ? { borderTopRightRadius: 0 } : {}),
                    }, "aria-valuenow": toast.progress }) }))] }));
});
