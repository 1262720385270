"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeReason = void 0;
var ChallengeReason;
(function (ChallengeReason) {
    ChallengeReason[ChallengeReason["AccessProtectedFile"] = 0] = "AccessProtectedFile";
    ChallengeReason[ChallengeReason["AccessProtectedNote"] = 1] = "AccessProtectedNote";
    ChallengeReason[ChallengeReason["AddPasscode"] = 2] = "AddPasscode";
    ChallengeReason[ChallengeReason["ApplicationUnlock"] = 3] = "ApplicationUnlock";
    ChallengeReason[ChallengeReason["ChangeAutolockInterval"] = 4] = "ChangeAutolockInterval";
    ChallengeReason[ChallengeReason["ChangePasscode"] = 5] = "ChangePasscode";
    ChallengeReason[ChallengeReason["CreateDecryptedBackupWithProtectedItems"] = 6] = "CreateDecryptedBackupWithProtectedItems";
    ChallengeReason[ChallengeReason["Custom"] = 7] = "Custom";
    ChallengeReason[ChallengeReason["DecryptEncryptedFile"] = 8] = "DecryptEncryptedFile";
    ChallengeReason[ChallengeReason["DisableBiometrics"] = 9] = "DisableBiometrics";
    ChallengeReason[ChallengeReason["DisableMfa"] = 10] = "DisableMfa";
    ChallengeReason[ChallengeReason["ExportBackup"] = 11] = "ExportBackup";
    ChallengeReason[ChallengeReason["ImportFile"] = 12] = "ImportFile";
    ChallengeReason[ChallengeReason["Migration"] = 13] = "Migration";
    ChallengeReason[ChallengeReason["ProtocolUpgrade"] = 14] = "ProtocolUpgrade";
    ChallengeReason[ChallengeReason["RemovePasscode"] = 15] = "RemovePasscode";
    ChallengeReason[ChallengeReason["ResaveRootKey"] = 16] = "ResaveRootKey";
    ChallengeReason[ChallengeReason["RevokeSession"] = 17] = "RevokeSession";
    ChallengeReason[ChallengeReason["SearchProtectedNotesText"] = 18] = "SearchProtectedNotesText";
    ChallengeReason[ChallengeReason["SelectProtectedNote"] = 19] = "SelectProtectedNote";
    ChallengeReason[ChallengeReason["UnprotectFile"] = 20] = "UnprotectFile";
    ChallengeReason[ChallengeReason["UnprotectNote"] = 21] = "UnprotectNote";
    ChallengeReason[ChallengeReason["DeleteAccount"] = 22] = "DeleteAccount";
    ChallengeReason[ChallengeReason["AuthorizeNoteForListed"] = 23] = "AuthorizeNoteForListed";
})(ChallengeReason = exports.ChallengeReason || (exports.ChallengeReason = {}));
