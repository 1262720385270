"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAsyncOperator = exports.createOperatorForVersion = void 0;
const common_1 = require("@standardnotes/common");
const Operator001_1 = require("../Operator/001/Operator001");
const Operator002_1 = require("../Operator/002/Operator002");
const Operator003_1 = require("../Operator/003/Operator003");
const Operator004_1 = require("../Operator/004/Operator004");
function createOperatorForVersion(version, crypto) {
    if (version === common_1.ProtocolVersion.V001) {
        return new Operator001_1.SNProtocolOperator001(crypto);
    }
    else if (version === common_1.ProtocolVersion.V002) {
        return new Operator002_1.SNProtocolOperator002(crypto);
    }
    else if (version === common_1.ProtocolVersion.V003) {
        return new Operator003_1.SNProtocolOperator003(crypto);
    }
    else if (version === common_1.ProtocolVersion.V004) {
        return new Operator004_1.SNProtocolOperator004(crypto);
    }
    else {
        throw Error(`Unable to find operator for version ${version}`);
    }
}
exports.createOperatorForVersion = createOperatorForVersion;
function isAsyncOperator(operator) {
    return operator.generateDecryptedParametersAsync !== undefined;
}
exports.isAsyncOperator = isAsyncOperator;
