"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeychainRecoveryStrings = exports.ErrorAlertStrings = exports.ChallengeStrings = exports.SessionStrings = exports.ChallengeModalTitle = exports.ProtocolUpgradeStrings = exports.SignInStrings = exports.RegisterStrings = exports.CredentialsChangeStrings = exports.StrictSignInFailed = exports.InsufficientPasswordMessage = exports.UNKNOWN_ERROR = exports.DO_NOT_CLOSE_APPLICATION = exports.REMOVING_PASSCODE = exports.CHANGING_PASSCODE = exports.SETTING_PASSCODE = exports.UPGRADING_ENCRYPTION = exports.OUTDATED_PROTOCOL_ALERT_IGNORE = exports.INVALID_PASSWORD = exports.INVALID_PASSWORD_COST = exports.UNSUPPORTED_KEY_DERIVATION = exports.EXPIRED_PROTOCOL_VERSION = exports.UNSUPPORTED_PROTOCOL_VERSION = exports.INVALID_EXTENSION_URL = exports.API_MESSAGE_FAILED_OFFLINE_ACTIVATION = exports.API_MESSAGE_FAILED_DOWNLOADING_EXTENSION = exports.API_MESSAGE_UNTRUSTED_EXTENSIONS_WARNING = exports.API_MESSAGE_FAILED_OFFLINE_FEATURES = exports.API_MESSAGE_FAILED_ACCESS_PURCHASE = exports.API_MESSAGE_FAILED_SUBSCRIPTION_INFO = exports.API_MESSAGE_FAILED_CREATE_FILE_TOKEN = exports.API_MESSAGE_FAILED_LISTED_REGISTRATION = exports.API_MESSAGE_FAILED_UPDATE_SETTINGS = exports.API_MESSAGE_FAILED_GET_SETTINGS = exports.API_MESSAGE_INVALID_SESSION = exports.API_MESSAGE_RATE_LIMITED = exports.API_MESSAGE_TOKEN_REFRESH_IN_PROGRESS = exports.API_MESSAGE_GENERIC_TOKEN_REFRESH_FAIL = exports.API_MESSAGE_FALLBACK_LOGIN_FAIL = exports.API_MESSAGE_CHANGE_CREDENTIALS_IN_PROGRESS = exports.API_MESSAGE_LOGIN_IN_PROGRESS = exports.API_MESSAGE_REGISTRATION_IN_PROGRESS = exports.API_MESSAGE_GENERIC_SINGLE_ITEM_SYNC_FAIL = exports.API_MESSAGE_GENERIC_INTEGRITY_CHECK_FAIL = exports.ServerErrorStrings = exports.API_MESSAGE_GENERIC_SYNC_FAIL = exports.API_MESSAGE_GENERIC_CHANGE_CREDENTIALS_FAIL = exports.API_MESSAGE_GENERIC_REGISTRATION_FAIL = exports.API_MESSAGE_GENERIC_INVALID_LOGIN = void 0;
exports.API_MESSAGE_GENERIC_INVALID_LOGIN = 'A server error occurred while trying to sign in. Please try again.';
exports.API_MESSAGE_GENERIC_REGISTRATION_FAIL = 'A server error occurred while trying to register. Please try again.';
exports.API_MESSAGE_GENERIC_CHANGE_CREDENTIALS_FAIL = 'Something went wrong while changing your credentials. Your credentials were not changed. Please try again.';
exports.API_MESSAGE_GENERIC_SYNC_FAIL = 'Could not connect to server.';
exports.ServerErrorStrings = {
    DeleteAccountError: 'Your account was unable to be deleted due to an error. Please try your request again.',
};
exports.API_MESSAGE_GENERIC_INTEGRITY_CHECK_FAIL = 'Could not check your data integrity with the server.';
exports.API_MESSAGE_GENERIC_SINGLE_ITEM_SYNC_FAIL = 'Could not retrieve item.';
exports.API_MESSAGE_REGISTRATION_IN_PROGRESS = 'An existing registration request is already in progress.';
exports.API_MESSAGE_LOGIN_IN_PROGRESS = 'An existing sign in request is already in progress.';
exports.API_MESSAGE_CHANGE_CREDENTIALS_IN_PROGRESS = 'An existing change credentials request is already in progress.';
exports.API_MESSAGE_FALLBACK_LOGIN_FAIL = 'Invalid email or password.';
exports.API_MESSAGE_GENERIC_TOKEN_REFRESH_FAIL = 'A server error occurred while trying to refresh your session. Please try again.';
exports.API_MESSAGE_TOKEN_REFRESH_IN_PROGRESS = 'Your account session is being renewed with the server. Please try your request again.';
exports.API_MESSAGE_RATE_LIMITED = 'Too many successive server requests. Please wait a few minutes and try again.';
exports.API_MESSAGE_INVALID_SESSION = 'Please sign in to an account in order to continue with your request.';
exports.API_MESSAGE_FAILED_GET_SETTINGS = 'Failed to get settings.';
exports.API_MESSAGE_FAILED_UPDATE_SETTINGS = 'Failed to update settings.';
exports.API_MESSAGE_FAILED_LISTED_REGISTRATION = 'Unable to register for Listed. Please try again later.';
exports.API_MESSAGE_FAILED_CREATE_FILE_TOKEN = 'Failed to create file token.';
exports.API_MESSAGE_FAILED_SUBSCRIPTION_INFO = "Failed to get subscription's information.";
exports.API_MESSAGE_FAILED_ACCESS_PURCHASE = 'Failed to access purchase flow.';
exports.API_MESSAGE_FAILED_OFFLINE_FEATURES = 'Failed to get offline features.';
exports.API_MESSAGE_UNTRUSTED_EXTENSIONS_WARNING = `The extension you are attempting to install comes from an
  untrusted source. Untrusted extensions may lower the security of your data. Do you want to continue?`;
exports.API_MESSAGE_FAILED_DOWNLOADING_EXTENSION = `Error downloading package details. Please check the
  extension link and try again.`;
exports.API_MESSAGE_FAILED_OFFLINE_ACTIVATION = 'An unknown issue occurred during offline activation. Please try again.';
exports.INVALID_EXTENSION_URL = 'Invalid extension URL.';
exports.UNSUPPORTED_PROTOCOL_VERSION = 'This version of the application does not support your newer account type. Please upgrade to the latest version of Standard Notes to sign in.';
exports.EXPIRED_PROTOCOL_VERSION = 'The protocol version associated with your account is outdated and no longer supported by this application. Please visit standardnotes.com/help/security for more information.';
exports.UNSUPPORTED_KEY_DERIVATION = 'Your account was created on a platform with higher security capabilities than this browser supports. If we attempted to generate your login keys here, it would take hours. Please use a browser with more up to date security capabilities, like Google Chrome or Firefox, to log in.';
exports.INVALID_PASSWORD_COST = 'Unable to sign in due to insecure password parameters. Please visit standardnotes.com/help/security for more information.';
exports.INVALID_PASSWORD = 'Invalid password.';
exports.OUTDATED_PROTOCOL_ALERT_IGNORE = 'Sign In';
exports.UPGRADING_ENCRYPTION = "Upgrading your account's encryption version…";
exports.SETTING_PASSCODE = 'Setting passcode…';
exports.CHANGING_PASSCODE = 'Changing passcode…';
exports.REMOVING_PASSCODE = 'Removing passcode…';
exports.DO_NOT_CLOSE_APPLICATION = 'Do not close the application until this process completes.';
exports.UNKNOWN_ERROR = 'Unknown error.';
function InsufficientPasswordMessage(minimum) {
    return `Your password must be at least ${minimum} characters in length. For your security, please choose a longer password or, ideally, a passphrase, and try again.`;
}
exports.InsufficientPasswordMessage = InsufficientPasswordMessage;
function StrictSignInFailed(current, latest) {
    return `Strict Sign In has refused the server's sign-in parameters. The latest account version is ${latest}, but the server is reporting a version of ${current} for your account. If you'd like to proceed with sign in anyway, please disable Strict Sign In and try again.`;
}
exports.StrictSignInFailed = StrictSignInFailed;
exports.CredentialsChangeStrings = {
    PasscodeRequired: 'Your passcode is required to process your credentials change.',
    Failed: 'Unable to change your credentials due to a sync error. Please try again.',
};
exports.RegisterStrings = {
    PasscodeRequired: 'Your passcode is required in order to register for an account.',
};
exports.SignInStrings = {
    PasscodeRequired: 'Your passcode is required in order to sign in to your account.',
    IncorrectMfa: 'Incorrect two-factor authentication code. Please try again.',
    SignInCanceledMissingMfa: 'Your sign in request has been canceled.',
};
exports.ProtocolUpgradeStrings = {
    SuccessAccount: "Your encryption version has been successfully upgraded. You may be asked to enter your credentials again on other devices you're signed into.",
    SuccessPasscodeOnly: 'Your encryption version has been successfully upgraded.',
    Fail: 'Unable to upgrade encryption version. Please try again.',
    UpgradingPasscode: 'Upgrading local encryption...',
};
exports.ChallengeModalTitle = {
    Generic: 'Authentication Required',
    Migration: 'Storage Update',
};
exports.SessionStrings = {
    EnterEmailAndPassword: 'Please enter your account email and password.',
    RecoverSession(email) {
        return email
            ? `Your credentials are needed for ${email} to refresh your session with the server.`
            : 'Your credentials are needed to refresh your session with the server.';
    },
    SessionRestored: 'Your session has been successfully restored.',
    EnterMfa: 'Please enter your two-factor authentication code.',
    InputU2FDevice: 'Please authenticate with your hardware security key.',
    MfaInputPlaceholder: 'Two-factor authentication code',
    EmailInputPlaceholder: 'Email',
    PasswordInputPlaceholder: 'Password',
    KeychainRecoveryErrorTitle: 'Invalid Credentials',
    KeychainRecoveryError: 'The email or password you entered is incorrect.\n\nPlease note that this sign-in request is made against the default server. If you are using a custom server, you must uninstall the app then reinstall, and sign back into your account.',
    RevokeTitle: 'Revoke this session?',
    RevokeConfirmButton: 'Revoke',
    RevokeCancelButton: 'Cancel',
    RevokeText: 'The associated app will be signed out and all data removed ' +
        'from the device when it is next launched. You can sign back in on that ' +
        'device at any time.',
    CurrentSessionRevoked: 'Your session has been revoked and all local data has been removed ' + 'from this device.',
};
exports.ChallengeStrings = {
    UnlockApplication: 'Authentication is required to unlock the application',
    NoteAccess: 'Authentication is required to view this note',
    FileAccess: 'Authentication is required to access this file',
    ImportFile: 'Authentication is required to import a backup file',
    AddPasscode: 'Authentication is required to add a passcode',
    RemovePasscode: 'Authentication is required to remove your passcode',
    ChangePasscode: 'Authentication is required to change your passcode',
    ChangeAutolockInterval: 'Authentication is required to change autolock timer duration',
    RevokeSession: 'Authentication is required to revoke a session',
    EnterAccountPassword: 'Enter your account password',
    EnterLocalPasscode: 'Enter your application passcode',
    EnterPasscodeForMigration: 'Your application passcode is required to perform an upgrade of your local data storage structure.',
    EnterPasscodeForRootResave: 'Enter your application passcode to continue',
    EnterCredentialsForProtocolUpgrade: 'Enter your credentials to perform encryption upgrade',
    EnterCredentialsForDecryptedBackupDownload: 'Enter your credentials to download a decrypted backup',
    AccountPasswordPlaceholder: 'Account Password',
    LocalPasscodePlaceholder: 'Application Passcode',
    DecryptEncryptedFile: 'Enter the account password associated with the import file',
    ExportBackup: 'Authentication is required to export a backup',
    DisableBiometrics: 'Authentication is required to disable biometrics',
    UnprotectNote: 'Authentication is required to unprotect a note',
    UnprotectFile: 'Authentication is required to unprotect a file',
    SearchProtectedNotesText: 'Authentication is required to search protected contents',
    SelectProtectedNote: 'Authentication is required to select a protected note',
    DisableMfa: 'Authentication is required to disable two-factor authentication',
    DeleteAccount: 'Authentication is required to delete your account',
    ListedAuthorization: 'Authentication is required to approve this note for Listed',
};
exports.ErrorAlertStrings = {
    MissingSessionTitle: 'Missing Session',
    MissingSessionBody: 'We were unable to load your server session. This represents an inconsistency with your application state. Please take an opportunity to backup your data, then sign out and sign back in to resolve this issue.',
    StorageDecryptErrorTitle: 'Storage Error',
    StorageDecryptErrorBody: "We were unable to decrypt your local storage. Please restart the app and try again. If you're unable to resolve this issue, and you have an account, you may try uninstalling the app then reinstalling, then signing back into your account. Otherwise, please contact help@standardnotes.com for support.",
};
exports.KeychainRecoveryStrings = {
    Title: 'Restore Keychain',
    Text: (email) => `We've detected that your keychain has been wiped. This can happen when restoring your device from a backup. Please enter your account password for "${email}" to restore your account keys.`,
};
