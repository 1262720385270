"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncSource = void 0;
var SyncSource;
(function (SyncSource) {
    SyncSource[SyncSource["External"] = 1] = "External";
    SyncSource[SyncSource["SpawnQueue"] = 2] = "SpawnQueue";
    SyncSource[SyncSource["ResolveQueue"] = 3] = "ResolveQueue";
    SyncSource[SyncSource["MoreDirtyItems"] = 4] = "MoreDirtyItems";
    SyncSource[SyncSource["AfterDownloadFirst"] = 5] = "AfterDownloadFirst";
    SyncSource[SyncSource["IntegrityCheck"] = 6] = "IntegrityCheck";
    SyncSource[SyncSource["ResolveOutOfSync"] = 7] = "ResolveOutOfSync";
})(SyncSource = exports.SyncSource || (exports.SyncSource = {}));
