"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileEncryptor = void 0;
const sncrypto_common_1 = require("@standardnotes/sncrypto-common");
class FileEncryptor {
    constructor(file, crypto) {
        this.file = file;
        this.crypto = crypto;
    }
    initializeHeader() {
        this.stream = this.crypto.xchacha20StreamInitEncryptor(this.file.key);
        return this.stream.header;
    }
    pushBytes(decryptedBytes, isFinalChunk) {
        if (!this.stream) {
            throw new Error('FileEncryptor must call initializeHeader first');
        }
        const tag = isFinalChunk ? sncrypto_common_1.SodiumConstant.CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_FINAL : undefined;
        const encryptedBytes = this.crypto.xchacha20StreamEncryptorPush(this.stream, decryptedBytes, this.file.remoteIdentifier, tag);
        return encryptedBytes;
    }
}
exports.FileEncryptor = FileEncryptor;
