"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationEvent = void 0;
var ApplicationEvent;
(function (ApplicationEvent) {
    ApplicationEvent[ApplicationEvent["SignedIn"] = 2] = "SignedIn";
    ApplicationEvent[ApplicationEvent["SignedOut"] = 3] = "SignedOut";
    /** When a full, potentially multi-page sync completes */
    ApplicationEvent[ApplicationEvent["CompletedFullSync"] = 5] = "CompletedFullSync";
    ApplicationEvent[ApplicationEvent["FailedSync"] = 6] = "FailedSync";
    ApplicationEvent[ApplicationEvent["HighLatencySync"] = 7] = "HighLatencySync";
    ApplicationEvent[ApplicationEvent["EnteredOutOfSync"] = 8] = "EnteredOutOfSync";
    ApplicationEvent[ApplicationEvent["ExitedOutOfSync"] = 9] = "ExitedOutOfSync";
    /**
     * The application has finished it `prepareForLaunch` state and is now ready for unlock
     * Called when the application has initialized and is ready for launch, but before
     * the application has been unlocked, if applicable. Use this to do pre-launch
     * configuration, but do not attempt to access user data like notes or tags.
     */
    ApplicationEvent[ApplicationEvent["Started"] = 10] = "Started";
    /**
     * The applicaiton is fully unlocked and ready for i/o
     * Called when the application has been fully decrypted and unlocked. Use this to
     * to begin streaming data like notes and tags.
     */
    ApplicationEvent[ApplicationEvent["Launched"] = 11] = "Launched";
    ApplicationEvent[ApplicationEvent["LocalDataLoaded"] = 12] = "LocalDataLoaded";
    /**
     * When the root key or root key wrapper changes. Includes events like account state
     * changes (registering, signing in, changing pw, logging out) and passcode state
     * changes (adding, removing, changing).
     */
    ApplicationEvent[ApplicationEvent["KeyStatusChanged"] = 13] = "KeyStatusChanged";
    ApplicationEvent[ApplicationEvent["MajorDataChange"] = 14] = "MajorDataChange";
    ApplicationEvent[ApplicationEvent["CompletedRestart"] = 15] = "CompletedRestart";
    ApplicationEvent[ApplicationEvent["LocalDataIncrementalLoad"] = 16] = "LocalDataIncrementalLoad";
    ApplicationEvent[ApplicationEvent["SyncStatusChanged"] = 17] = "SyncStatusChanged";
    ApplicationEvent[ApplicationEvent["WillSync"] = 18] = "WillSync";
    ApplicationEvent[ApplicationEvent["InvalidSyncSession"] = 19] = "InvalidSyncSession";
    ApplicationEvent[ApplicationEvent["LocalDatabaseReadError"] = 20] = "LocalDatabaseReadError";
    ApplicationEvent[ApplicationEvent["LocalDatabaseWriteError"] = 21] = "LocalDatabaseWriteError";
    /** When a single roundtrip completes with sync, in a potentially multi-page sync request.
     * If just a single roundtrip, this event will be triggered, along with CompletedFullSync */
    ApplicationEvent[ApplicationEvent["CompletedIncrementalSync"] = 22] = "CompletedIncrementalSync";
    /**
     * The application has loaded all pending migrations (but not run any, except for the base one),
     * and consumers may now call `hasPendingMigrations`
     */
    ApplicationEvent[ApplicationEvent["MigrationsLoaded"] = 23] = "MigrationsLoaded";
    /** When StorageService is ready to start servicing read/write requests */
    ApplicationEvent[ApplicationEvent["StorageReady"] = 24] = "StorageReady";
    ApplicationEvent[ApplicationEvent["PreferencesChanged"] = 25] = "PreferencesChanged";
    ApplicationEvent[ApplicationEvent["UnprotectedSessionBegan"] = 26] = "UnprotectedSessionBegan";
    ApplicationEvent[ApplicationEvent["UserRolesChanged"] = 27] = "UserRolesChanged";
    ApplicationEvent[ApplicationEvent["FeaturesUpdated"] = 28] = "FeaturesUpdated";
    ApplicationEvent[ApplicationEvent["UnprotectedSessionExpired"] = 29] = "UnprotectedSessionExpired";
    /** Called when the app first launches and after first sync request made after sign in */
    ApplicationEvent[ApplicationEvent["CompletedInitialSync"] = 30] = "CompletedInitialSync";
    ApplicationEvent[ApplicationEvent["BiometricsSoftLockEngaged"] = 31] = "BiometricsSoftLockEngaged";
    ApplicationEvent[ApplicationEvent["BiometricsSoftLockDisengaged"] = 32] = "BiometricsSoftLockDisengaged";
    ApplicationEvent[ApplicationEvent["DidPurchaseSubscription"] = 33] = "DidPurchaseSubscription";
})(ApplicationEvent = exports.ApplicationEvent || (exports.ApplicationEvent = {}));
