"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNUserPrefs = void 0;
const DecryptedItem_1 = require("../../Abstract/Item/Implementations/DecryptedItem");
const common_1 = require("@standardnotes/common");
const Predicate_1 = require("../../Runtime/Predicate/Predicate");
class SNUserPrefs extends DecryptedItem_1.DecryptedItem {
    get isSingleton() {
        return true;
    }
    singletonPredicate() {
        return SNUserPrefs.singletonPredicate;
    }
    getPref(key) {
        return this.getAppDomainValue(key);
    }
}
SNUserPrefs.singletonPredicate = new Predicate_1.Predicate('content_type', '=', common_1.ContentType.UserPrefs);
exports.SNUserPrefs = SNUserPrefs;
