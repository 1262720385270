"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCloseOnClickOutside = void 0;
const react_1 = require("react");
function useCloseOnClickOutside(container, callback) {
    const closeOnClickOutside = (0, react_1.useCallback)((event) => {
        if (!container.current) {
            return;
        }
        const isDescendantOfContainer = container.current.contains(event.target);
        const isDescendantOfDialog = event.target.closest('[role="dialog"]');
        if (!isDescendantOfContainer && !isDescendantOfDialog) {
            callback();
        }
    }, [container, callback]);
    (0, react_1.useEffect)(() => {
        document.addEventListener('click', closeOnClickOutside, { capture: true });
        return () => {
            document.removeEventListener('click', closeOnClickOutside, {
                capture: true,
            });
        };
    }, [closeOnClickOutside]);
}
exports.useCloseOnClickOutside = useCloseOnClickOutside;
