"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Challenge = void 0;
const utils_1 = require("@standardnotes/utils");
const Messages_1 = require("../Strings/Messages");
const ChallengeReason_1 = require("./Types/ChallengeReason");
/**
 * A challenge is a stateless description of what the client needs to provide
 * in order to proceed.
 */
class Challenge {
    constructor(prompts, reason, cancelable, _heading, _subheading) {
        this.prompts = prompts;
        this.reason = reason;
        this.cancelable = cancelable;
        this._heading = _heading;
        this._subheading = _subheading;
        this.id = Math.random();
        Object.freeze(this);
    }
    /** Outside of the modal, this is the title of the modal itself */
    get modalTitle() {
        switch (this.reason) {
            case ChallengeReason_1.ChallengeReason.Migration:
                return Messages_1.ChallengeModalTitle.Migration;
            default:
                return Messages_1.ChallengeModalTitle.Generic;
        }
    }
    /** Inside of the modal, this is the H1 */
    get heading() {
        if (this._heading) {
            return this._heading;
        }
        else {
            switch (this.reason) {
                case ChallengeReason_1.ChallengeReason.ApplicationUnlock:
                    return Messages_1.ChallengeStrings.UnlockApplication;
                case ChallengeReason_1.ChallengeReason.Migration:
                    return Messages_1.ChallengeStrings.EnterLocalPasscode;
                case ChallengeReason_1.ChallengeReason.ResaveRootKey:
                    return Messages_1.ChallengeStrings.EnterPasscodeForRootResave;
                case ChallengeReason_1.ChallengeReason.ProtocolUpgrade:
                    return Messages_1.ChallengeStrings.EnterCredentialsForProtocolUpgrade;
                case ChallengeReason_1.ChallengeReason.AccessProtectedNote:
                    return Messages_1.ChallengeStrings.NoteAccess;
                case ChallengeReason_1.ChallengeReason.AccessProtectedFile:
                    return Messages_1.ChallengeStrings.FileAccess;
                case ChallengeReason_1.ChallengeReason.ImportFile:
                    return Messages_1.ChallengeStrings.ImportFile;
                case ChallengeReason_1.ChallengeReason.AddPasscode:
                    return Messages_1.ChallengeStrings.AddPasscode;
                case ChallengeReason_1.ChallengeReason.RemovePasscode:
                    return Messages_1.ChallengeStrings.RemovePasscode;
                case ChallengeReason_1.ChallengeReason.ChangePasscode:
                    return Messages_1.ChallengeStrings.ChangePasscode;
                case ChallengeReason_1.ChallengeReason.ChangeAutolockInterval:
                    return Messages_1.ChallengeStrings.ChangeAutolockInterval;
                case ChallengeReason_1.ChallengeReason.CreateDecryptedBackupWithProtectedItems:
                    return Messages_1.ChallengeStrings.EnterCredentialsForDecryptedBackupDownload;
                case ChallengeReason_1.ChallengeReason.RevokeSession:
                    return Messages_1.ChallengeStrings.RevokeSession;
                case ChallengeReason_1.ChallengeReason.DecryptEncryptedFile:
                    return Messages_1.ChallengeStrings.DecryptEncryptedFile;
                case ChallengeReason_1.ChallengeReason.ExportBackup:
                    return Messages_1.ChallengeStrings.ExportBackup;
                case ChallengeReason_1.ChallengeReason.DisableBiometrics:
                    return Messages_1.ChallengeStrings.DisableBiometrics;
                case ChallengeReason_1.ChallengeReason.UnprotectNote:
                    return Messages_1.ChallengeStrings.UnprotectNote;
                case ChallengeReason_1.ChallengeReason.UnprotectFile:
                    return Messages_1.ChallengeStrings.UnprotectFile;
                case ChallengeReason_1.ChallengeReason.SearchProtectedNotesText:
                    return Messages_1.ChallengeStrings.SearchProtectedNotesText;
                case ChallengeReason_1.ChallengeReason.SelectProtectedNote:
                    return Messages_1.ChallengeStrings.SelectProtectedNote;
                case ChallengeReason_1.ChallengeReason.DisableMfa:
                    return Messages_1.ChallengeStrings.DisableMfa;
                case ChallengeReason_1.ChallengeReason.DeleteAccount:
                    return Messages_1.ChallengeStrings.DeleteAccount;
                case ChallengeReason_1.ChallengeReason.AuthorizeNoteForListed:
                    return Messages_1.ChallengeStrings.ListedAuthorization;
                case ChallengeReason_1.ChallengeReason.Custom:
                    return '';
                default:
                    return (0, utils_1.assertUnreachable)(this.reason);
            }
        }
    }
    /** Inside of the modal, this is the H2 */
    get subheading() {
        if (this._subheading) {
            return this._subheading;
        }
        switch (this.reason) {
            case ChallengeReason_1.ChallengeReason.Migration:
                return Messages_1.ChallengeStrings.EnterPasscodeForMigration;
            default:
                return undefined;
        }
    }
    hasPromptForValidationType(type) {
        for (const prompt of this.prompts) {
            if (prompt.validation === type) {
                return true;
            }
        }
        return false;
    }
}
exports.Challenge = Challenge;
