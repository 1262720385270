"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNProtocolOperator004 = void 0;
const common_1 = require("@standardnotes/common");
const Models = require("@standardnotes/models");
const models_1 = require("@standardnotes/models");
const Utils = require("@standardnotes/utils");
const Algorithm_1 = require("../../Algorithm");
const ItemsKey_1 = require("../../Keys/ItemsKey/ItemsKey");
const Functions_1 = require("../../Keys/RootKey/Functions");
const KeyParamsFunctions_1 = require("../../Keys/RootKey/KeyParamsFunctions");
const PARTITION_CHARACTER = ':';
class SNProtocolOperator004 {
    constructor(crypto) {
        this.crypto = crypto;
    }
    getEncryptionDisplayName() {
        return 'XChaCha20-Poly1305';
    }
    get version() {
        return common_1.ProtocolVersion.V004;
    }
    generateNewItemsKeyContent() {
        const itemsKey = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.EncryptionKeyLength);
        const response = (0, models_1.FillItemContent)({
            itemsKey: itemsKey,
            version: common_1.ProtocolVersion.V004,
        });
        return response;
    }
    /**
     * Creates a new random items key to use for item encryption.
     * The consumer must save/sync this item.
     */
    createItemsKey() {
        const payload = new Models.DecryptedPayload(Object.assign({ uuid: Utils.UuidGenerator.GenerateUuid(), content_type: common_1.ContentType.ItemsKey, content: this.generateNewItemsKeyContent() }, (0, models_1.PayloadTimestampDefaults)()));
        return (0, models_1.CreateDecryptedItemFromPayload)(payload);
    }
    /**
     * We require both a client-side component and a server-side component in generating a
     * salt. This way, a comprimised server cannot benefit from sending the same seed value
     * for every user. We mix a client-controlled value that is globally unique
     * (their identifier), with a server controlled value to produce a salt for our KDF.
     * @param identifier
     * @param seed
     */
    generateSalt004(identifier, seed) {
        return __awaiter(this, void 0, void 0, function* () {
            const hash = yield this.crypto.sha256([identifier, seed].join(PARTITION_CHARACTER));
            return Utils.truncateHexString(hash, Algorithm_1.V004Algorithm.ArgonSaltLength);
        });
    }
    /**
     * Computes a root key given a passworf
     * qwd and previous keyParams
     * @param password - Plain string representing raw user password
     * @param keyParams - KeyParams object
     */
    computeRootKey(password, keyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            return this.deriveKey(password, keyParams);
        });
    }
    /**
     * Creates a new root key given an identifier and a user password
     * @param identifier - Plain string representing a unique identifier
     * @param password - Plain string representing raw user password
     */
    createRootKey(identifier, password, origination) {
        return __awaiter(this, void 0, void 0, function* () {
            const version = common_1.ProtocolVersion.V004;
            const seed = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.ArgonSaltSeedLength);
            const keyParams = (0, KeyParamsFunctions_1.Create004KeyParams)({
                identifier: identifier,
                pw_nonce: seed,
                version: version,
                origination: origination,
                created: `${Date.now()}`,
            });
            return this.deriveKey(password, keyParams);
        });
    }
    /**
     * @param plaintext - The plaintext to encrypt.
     * @param rawKey - The key to use to encrypt the plaintext.
     * @param nonce - The nonce for encryption.
     * @param authenticatedData - JavaScript object (will be stringified) representing
                  'Additional authenticated data': data you want to be included in authentication.
     */
    encryptString004(plaintext, rawKey, nonce, authenticatedData) {
        if (!nonce) {
            throw 'encryptString null nonce';
        }
        if (!rawKey) {
            throw 'encryptString null rawKey';
        }
        return this.crypto.xchacha20Encrypt(plaintext, nonce, rawKey, this.authenticatedDataToString(authenticatedData));
    }
    /**
     * @param ciphertext  The encrypted text to decrypt.
     * @param rawKey  The key to use to decrypt the ciphertext.
     * @param nonce  The nonce for decryption.
     * @param rawAuthenticatedData String representing
                  'Additional authenticated data' - data you want to be included in authentication.
     */
    decryptString004(ciphertext, rawKey, nonce, rawAuthenticatedData) {
        return this.crypto.xchacha20Decrypt(ciphertext, nonce, rawKey, rawAuthenticatedData);
    }
    generateEncryptionNonce() {
        return this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.EncryptionNonceLength);
    }
    /**
     * @param plaintext  The plaintext text to decrypt.
     * @param rawKey  The key to use to encrypt the plaintext.
     */
    generateEncryptedProtocolString(plaintext, rawKey, authenticatedData) {
        const nonce = this.generateEncryptionNonce();
        const ciphertext = this.encryptString004(plaintext, rawKey, nonce, authenticatedData);
        const components = [
            common_1.ProtocolVersion.V004,
            nonce,
            ciphertext,
            this.authenticatedDataToString(authenticatedData),
        ];
        return components.join(PARTITION_CHARACTER);
    }
    deconstructEncryptedPayloadString(payloadString) {
        const components = payloadString.split(PARTITION_CHARACTER);
        return {
            version: components[0],
            nonce: components[1],
            ciphertext: components[2],
            authenticatedData: components[3],
        };
    }
    getPayloadAuthenticatedData(encrypted) {
        const itemKeyComponents = this.deconstructEncryptedPayloadString(encrypted.enc_item_key);
        const authenticatedDataString = itemKeyComponents.authenticatedData;
        const result = this.stringToAuthenticatedData(authenticatedDataString);
        return result;
    }
    /**
     * For items that are encrypted with a root key, we append the root key's key params, so
     * that in the event the client/user loses a reference to their root key, they may still
     * decrypt data by regenerating the key based on the attached key params.
     */
    generateAuthenticatedDataForPayload(payload, key) {
        const baseData = {
            u: payload.uuid,
            v: common_1.ProtocolVersion.V004,
        };
        if ((0, Functions_1.ContentTypeUsesRootKeyEncryption)(payload.content_type)) {
            return Object.assign(Object.assign({}, baseData), { kp: key.keyParams.content });
        }
        else {
            if (!(0, ItemsKey_1.isItemsKey)(key)) {
                throw Error('Attempting to use non-items key for regular item.');
            }
            return baseData;
        }
    }
    authenticatedDataToString(attachedData) {
        return this.crypto.base64Encode(JSON.stringify(Utils.sortedCopy(Utils.omitUndefinedCopy(attachedData))));
    }
    stringToAuthenticatedData(rawAuthenticatedData, override) {
        const base = JSON.parse(this.crypto.base64Decode(rawAuthenticatedData));
        return Utils.sortedCopy(Object.assign(Object.assign({}, base), override));
    }
    generateEncryptedParametersSync(payload, key) {
        const itemKey = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.EncryptionKeyLength);
        const contentPlaintext = JSON.stringify(payload.content);
        const authenticatedData = this.generateAuthenticatedDataForPayload(payload, key);
        const encryptedContentString = this.generateEncryptedProtocolString(contentPlaintext, itemKey, authenticatedData);
        const encryptedItemKey = this.generateEncryptedProtocolString(itemKey, key.itemsKey, authenticatedData);
        return {
            uuid: payload.uuid,
            items_key_id: (0, ItemsKey_1.isItemsKey)(key) ? key.uuid : undefined,
            content: encryptedContentString,
            enc_item_key: encryptedItemKey,
            version: this.version,
        };
    }
    generateDecryptedParametersSync(encrypted, key) {
        const contentKeyComponents = this.deconstructEncryptedPayloadString(encrypted.enc_item_key);
        const authenticatedData = this.stringToAuthenticatedData(contentKeyComponents.authenticatedData, {
            u: encrypted.uuid,
            v: encrypted.version,
        });
        const useAuthenticatedString = this.authenticatedDataToString(authenticatedData);
        const contentKey = this.decryptString004(contentKeyComponents.ciphertext, key.itemsKey, contentKeyComponents.nonce, useAuthenticatedString);
        if (!contentKey) {
            console.error('Error decrypting itemKey parameters', encrypted);
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        const contentComponents = this.deconstructEncryptedPayloadString(encrypted.content);
        const content = this.decryptString004(contentComponents.ciphertext, contentKey, contentComponents.nonce, useAuthenticatedString);
        if (!content) {
            return {
                uuid: encrypted.uuid,
                errorDecrypting: true,
            };
        }
        else {
            return {
                uuid: encrypted.uuid,
                content: JSON.parse(content),
            };
        }
    }
    deriveKey(password, keyParams) {
        return __awaiter(this, void 0, void 0, function* () {
            const salt = yield this.generateSalt004(keyParams.content004.identifier, keyParams.content004.pw_nonce);
            const derivedKey = this.crypto.argon2(password, salt, Algorithm_1.V004Algorithm.ArgonIterations, Algorithm_1.V004Algorithm.ArgonMemLimit, Algorithm_1.V004Algorithm.ArgonOutputKeyBytes);
            const partitions = Utils.splitString(derivedKey, 2);
            const masterKey = partitions[0];
            const serverPassword = partitions[1];
            return (0, Functions_1.CreateNewRootKey)({
                masterKey,
                serverPassword,
                version: common_1.ProtocolVersion.V004,
                keyParams: keyParams.getPortableValue(),
            });
        });
    }
}
exports.SNProtocolOperator004 = SNProtocolOperator004;
