"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionManager = void 0;
const AbstractService_1 = require("../Service/AbstractService");
const responses_1 = require("@standardnotes/responses");
class SubscriptionManager extends AbstractService_1.AbstractService {
    constructor(subscriptionApiService, internalEventBus) {
        super(internalEventBus);
        this.subscriptionApiService = subscriptionApiService;
        this.internalEventBus = internalEventBus;
    }
    async acceptInvitation(inviteUuid) {
        try {
            const result = await this.subscriptionApiService.acceptInvite(inviteUuid);
            if ((0, responses_1.isErrorResponse)(result)) {
                return { success: false, message: result.data.error.message };
            }
            return result.data;
        }
        catch (error) {
            return { success: false, message: 'Could not accept invitation.' };
        }
    }
    async listSubscriptionInvitations() {
        var _a;
        try {
            const result = await this.subscriptionApiService.listInvites();
            if ((0, responses_1.isErrorResponse)(result)) {
                return [];
            }
            return (_a = result.data.invitations) !== null && _a !== void 0 ? _a : [];
        }
        catch (error) {
            return [];
        }
    }
    async inviteToSubscription(inviteeEmail) {
        try {
            const result = await this.subscriptionApiService.invite(inviteeEmail);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return result.data.success === true;
        }
        catch (error) {
            return false;
        }
    }
    async cancelInvitation(inviteUuid) {
        try {
            const result = await this.subscriptionApiService.cancelInvite(inviteUuid);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return result.data.success === true;
        }
        catch (error) {
            return false;
        }
    }
    async confirmAppleIAP(params, subscriptionToken) {
        try {
            const result = await this.subscriptionApiService.confirmAppleIAP({
                ...params,
                subscription_token: subscriptionToken,
            });
            if ((0, responses_1.isErrorResponse)(result)) {
                return { success: false, message: result.data.error.message };
            }
            return result.data;
        }
        catch (error) {
            return { success: false, message: 'Could not confirm IAP.' };
        }
    }
}
exports.SubscriptionManager = SubscriptionManager;
