"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientDisplayableError = void 0;
class ClientDisplayableError {
    constructor(text, title, tag) {
        this.text = text;
        this.title = title;
        this.tag = tag;
        console.error('Client Displayable Error:', text, title || '', tag || '');
    }
    static FromError(error) {
        return new ClientDisplayableError(error.message, undefined, error.tag);
    }
}
exports.ClientDisplayableError = ClientDisplayableError;
