"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@ariakit/react");
const utils_1 = require("@standardnotes/utils");
const RadioButtonGroup = ({ value, items, onChange }) => {
    const radio = (0, react_1.useRadioStore)({
        value,
        orientation: 'horizontal',
        setValue(value) {
            onChange(value);
        },
    });
    return ((0, jsx_runtime_1.jsx)(react_1.RadioGroup, { store: radio, className: "flex divide-x divide-border rounded border border-border", children: items.map(({ label, value: itemValue }) => ((0, jsx_runtime_1.jsxs)("label", { className: (0, utils_1.classNames)('flex-grow select-none py-1.5 px-3.5 text-center', 'first:rounded-tl first:rounded-bl last:rounded-tr last:rounded-br', itemValue === value &&
                'bg-info-backdrop font-medium text-info ring-1 ring-inset ring-info focus-within:ring-2'), children: [(0, jsx_runtime_1.jsx)(react_1.VisuallyHidden, { children: (0, jsx_runtime_1.jsx)(react_1.Radio, { value: itemValue }) }), label] }, itemValue))) }));
};
exports.default = RadioButtonGroup;
