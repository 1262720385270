"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("@ariakit/react");
const snjs_1 = require("@standardnotes/snjs");
const AlertDialog = ({ children, closeDialog, className, ...props }) => {
    const dialog = (0, react_1.useDialogStore)({
        open: true,
        ...props,
    });
    return ((0, jsx_runtime_1.jsxs)(react_1.Dialog, { store: dialog, role: "alertdialog", className: "fixed top-0 left-0 z-modal h-full w-full", children: [(0, jsx_runtime_1.jsx)("div", { className: "absolute z-0 h-full w-full bg-passive-5 opacity-25 md:opacity-75", role: "presentation", onClick: closeDialog }), (0, jsx_runtime_1.jsx)("div", { className: (0, snjs_1.classNames)('absolute top-1/2 left-1/2 z-[1] w-[95vw] -translate-x-1/2 -translate-y-1/2 rounded border border-border bg-default px-6 py-5 shadow-xl md:w-auto', !(className === null || className === void 0 ? void 0 : className.includes('max-w-')) && 'max-w-[600px]', className), children: children })] }));
};
exports.default = AlertDialog;
