"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNTheme = exports.isTheme = void 0;
const features_1 = require("@standardnotes/features");
const Component_1 = require("../Component/Component");
const ConflictStrategy_1 = require("../../Abstract/Item/Types/ConflictStrategy");
const AppDataField_1 = require("../../Abstract/Item/Types/AppDataField");
const common_1 = require("@standardnotes/common");
const utils_1 = require("@standardnotes/utils");
const isTheme = (x) => x.content_type === common_1.ContentType.Theme;
exports.isTheme = isTheme;
class SNTheme extends Component_1.SNComponent {
    constructor() {
        super(...arguments);
        this.area = features_1.ComponentArea.Themes;
    }
    isLayerable() {
        return (0, utils_1.useBoolean)(this.package_info && this.package_info.layerable, false);
    }
    /** Do not duplicate under most circumstances. Always keep original */
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return ConflictStrategy_1.ConflictStrategy.KeepBase;
    }
    getMobileRules() {
        return (this.getAppDomainValue(AppDataField_1.AppDataField.MobileRules) || {
            constants: {},
            rules: {},
        });
    }
    /** Same as getMobileRules but without default value. */
    hasMobileRules() {
        return this.getAppDomainValue(AppDataField_1.AppDataField.MobileRules);
    }
    getNotAvailOnMobile() {
        return this.getAppDomainValue(AppDataField_1.AppDataField.NotAvailableOnMobile);
    }
    isMobileActive() {
        return this.getAppDomainValue(AppDataField_1.AppDataField.MobileActive);
    }
}
exports.SNTheme = SNTheme;
