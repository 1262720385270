"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PurePayload = void 0;
const utils_1 = require("@standardnotes/utils");
const PayloadSource_1 = require("../Types/PayloadSource");
class PurePayload {
    constructor(rawPayload, source = PayloadSource_1.PayloadSource.Constructor) {
        this.source = source;
        this.uuid = rawPayload.uuid;
        if (!this.uuid) {
            throw Error(`Attempting to construct payload with null uuid
        Content type: ${rawPayload.content_type}`);
        }
        this.content = rawPayload.content;
        this.content_type = rawPayload.content_type;
        this.deleted = (0, utils_1.useBoolean)(rawPayload.deleted, false);
        this.dirty = rawPayload.dirty;
        this.duplicate_of = rawPayload.duplicate_of;
        this.created_at = new Date(rawPayload.created_at || new Date());
        this.updated_at = new Date(rawPayload.updated_at || 0);
        this.created_at_timestamp = rawPayload.created_at_timestamp || 0;
        this.updated_at_timestamp = rawPayload.updated_at_timestamp || 0;
        this.lastSyncBegan = rawPayload.lastSyncBegan ? new Date(rawPayload.lastSyncBegan) : undefined;
        this.lastSyncEnd = rawPayload.lastSyncEnd ? new Date(rawPayload.lastSyncEnd) : undefined;
        this.dirtyIndex = rawPayload.dirtyIndex;
        this.globalDirtyIndexAtLastSync = rawPayload.globalDirtyIndexAtLastSync;
        const timeToAllowSubclassesToFinishConstruction = 0;
        setTimeout(() => {
            (0, utils_1.deepFreeze)(this);
        }, timeToAllowSubclassesToFinishConstruction);
    }
    ejected() {
        const comprehensive = {
            uuid: this.uuid,
            content: this.content,
            deleted: this.deleted,
            content_type: this.content_type,
            created_at: this.created_at,
            updated_at: this.updated_at,
            created_at_timestamp: this.created_at_timestamp,
            updated_at_timestamp: this.updated_at_timestamp,
            dirty: this.dirty,
            duplicate_of: this.duplicate_of,
            dirtyIndex: this.dirtyIndex,
            globalDirtyIndexAtLastSync: this.globalDirtyIndexAtLastSync,
            lastSyncBegan: this.lastSyncBegan,
            lastSyncEnd: this.lastSyncEnd,
        };
        return comprehensive;
    }
    get serverUpdatedAt() {
        return this.updated_at;
    }
    get serverUpdatedAtTimestamp() {
        return this.updated_at_timestamp;
    }
}
exports.PurePayload = PurePayload;
