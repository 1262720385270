"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericItem = void 0;
const utils_1 = require("@standardnotes/utils");
const ItemContentsDiffer_1 = require("../../../Utilities/Item/ItemContentsDiffer");
const PayloadSource_1 = require("../../Payload/Types/PayloadSource");
const ConflictStrategy_1 = require("../Types/ConflictStrategy");
const SingletonStrategy_1 = require("../Types/SingletonStrategy");
const TypeCheck_1 = require("../Interfaces/TypeCheck");
class GenericItem {
    constructor(payload) {
        this.payload = payload;
        this.duplicateOf = payload.duplicate_of;
        this.createdAtString = this.created_at && (0, utils_1.dateToLocalizedString)(this.created_at);
        this.userModifiedDate = this.serverUpdatedAt || new Date();
        this.updatedAtString = (0, utils_1.dateToLocalizedString)(this.userModifiedDate);
        const timeToAllowSubclassesToFinishConstruction = 0;
        setTimeout(() => {
            (0, utils_1.deepFreeze)(this);
        }, timeToAllowSubclassesToFinishConstruction);
    }
    get uuid() {
        return this.payload.uuid;
    }
    get content_type() {
        return this.payload.content_type;
    }
    get created_at() {
        return this.payload.created_at;
    }
    /**
     * The date timestamp the server set for this item upon it being synced
     * Undefined if never synced to a remote server.
     */
    get serverUpdatedAt() {
        return this.payload.serverUpdatedAt;
    }
    get serverUpdatedAtTimestamp() {
        return this.payload.updated_at_timestamp;
    }
    /** @deprecated Use serverUpdatedAt instead */
    get updated_at() {
        return this.serverUpdatedAt;
    }
    get dirty() {
        return this.payload.dirty;
    }
    get lastSyncBegan() {
        return this.payload.lastSyncBegan;
    }
    get lastSyncEnd() {
        return this.payload.lastSyncEnd;
    }
    get duplicate_of() {
        return this.payload.duplicate_of;
    }
    payloadRepresentation(override) {
        return this.payload.copy(override);
    }
    /** Whether the item has never been synced to a server */
    get neverSynced() {
        return !this.serverUpdatedAt || this.serverUpdatedAt.getTime() === 0;
    }
    /**
     * Subclasses can override this getter to return true if they want only
     * one of this item to exist, depending on custom criteria.
     */
    get isSingleton() {
        return false;
    }
    /** The predicate by which singleton items should be unique */
    singletonPredicate() {
        throw 'Must override SNItem.singletonPredicate';
    }
    get singletonStrategy() {
        return SingletonStrategy_1.SingletonStrategy.KeepEarliest;
    }
    /**
     * Subclasses can override this method and provide their own opinion on whether
     * they want to be duplicated. For example, if this.content.x = 12 and
     * item.content.x = 13, this function can be overriden to always return
     * ConflictStrategy.KeepBase to say 'don't create a duplicate at all, the
     * change is not important.'
     *
     * In the default implementation, we create a duplicate if content differs.
     * However, if they only differ by references, we KEEP_LEFT_MERGE_REFS.
     *
     * Left returns to our current item, and Right refers to the incoming item.
     */
    strategyWhenConflictingWithItem(item, previousRevision) {
        if ((0, TypeCheck_1.isEncryptedErroredItem)(this)) {
            return ConflictStrategy_1.ConflictStrategy.KeepBaseDuplicateApply;
        }
        if (this.isSingleton) {
            return ConflictStrategy_1.ConflictStrategy.KeepBase;
        }
        if ((0, TypeCheck_1.isDeletedItem)(this)) {
            return ConflictStrategy_1.ConflictStrategy.KeepApply;
        }
        if ((0, TypeCheck_1.isDeletedItem)(item)) {
            if (this.payload.source === PayloadSource_1.PayloadSource.FileImport) {
                return ConflictStrategy_1.ConflictStrategy.KeepBase;
            }
            return ConflictStrategy_1.ConflictStrategy.KeepApply;
        }
        if (!(0, TypeCheck_1.isDecryptedItem)(item) || !(0, TypeCheck_1.isDecryptedItem)(this)) {
            return ConflictStrategy_1.ConflictStrategy.KeepBaseDuplicateApply;
        }
        const contentDiffers = (0, ItemContentsDiffer_1.ItemContentsDiffer)(this, item);
        if (!contentDiffers) {
            return ConflictStrategy_1.ConflictStrategy.KeepApply;
        }
        const itemsAreDifferentExcludingRefs = (0, ItemContentsDiffer_1.ItemContentsDiffer)(this, item, ['references']);
        if (itemsAreDifferentExcludingRefs) {
            if (previousRevision) {
                /**
                 * If previousRevision.content === incomingValue.content, this means the
                 * change that was rejected by the server is in fact a legitimate change,
                 * because the value the client had previously matched with the server's,
                 * and this new change is being built on top of that state, and should therefore
                 * be chosen as the winner, with no need for a conflict.
                 */
                if (!(0, ItemContentsDiffer_1.ItemContentsDiffer)(previousRevision.itemFromPayload(), item)) {
                    return ConflictStrategy_1.ConflictStrategy.KeepBase;
                }
            }
            const twentySeconds = 20000;
            if (
            /**
             * If the incoming item comes from an import, treat it as
             * less important than the existing one.
             */
            item.payload.source === PayloadSource_1.PayloadSource.FileImport ||
                /**
                 * If the user is actively editing our item, duplicate the incoming item
                 * to avoid creating surprises in the client's UI.
                 */
                Date.now() - this.userModifiedDate.getTime() < twentySeconds) {
                return ConflictStrategy_1.ConflictStrategy.KeepBaseDuplicateApply;
            }
            else {
                return ConflictStrategy_1.ConflictStrategy.DuplicateBaseKeepApply;
            }
        }
        else {
            /** Only the references have changed; merge them. */
            return ConflictStrategy_1.ConflictStrategy.KeepBaseMergeRefs;
        }
    }
    satisfiesPredicate(predicate) {
        return predicate.matchesItem(this);
    }
}
exports.GenericItem = GenericItem;
