"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ApplicationProvider_1 = require("../ApplicationProvider");
const FileDragNDropProvider_1 = require("../FileDragNDropProvider");
const NoteViewFileDropTarget = ({ note, linkingController, noteViewElement, filesController }) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const { isDraggingFiles, addDragTarget, removeDragTarget } = (0, FileDragNDropProvider_1.useFileDragNDrop)();
    (0, react_1.useEffect)(() => {
        const target = noteViewElement;
        if (target) {
            addDragTarget(target, {
                tooltipText: 'Drop your files to upload and link them to the current note',
                callback: async (uploadedFile) => {
                    await linkingController.linkItems(note, uploadedFile);
                    void application.mutator.changeAndSaveItem(uploadedFile, (mutator) => {
                        mutator.protected = note.protected;
                    });
                    filesController.notifyObserversOfUploadedFileLinkingToCurrentNote(uploadedFile.uuid);
                },
            });
        }
        return () => {
            if (target) {
                removeDragTarget(target);
            }
        };
    }, [addDragTarget, linkingController, note, noteViewElement, removeDragTarget, filesController, application.mutator]);
    return isDraggingFiles ? (
    // Required to block drag events to editor iframe
    (0, jsx_runtime_1.jsx)("div", { id: "file-drag-iframe-overlay", className: "absolute top-0 left-0 z-dropdown-menu h-full w-full" })) : null;
};
exports.default = NoteViewFileDropTarget;
