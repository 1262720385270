"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openSubscriptionDashboard = void 0;
const snjs_1 = require("@standardnotes/snjs");
async function openSubscriptionDashboard(application) {
    const token = await application.getNewSubscriptionToken();
    if (!token) {
        return;
    }
    const url = `${window.dashboardUrl}?subscription_token=${token}`;
    if (application.deviceInterface.environment === snjs_1.Environment.Mobile) {
        application.deviceInterface.openUrl(url);
        return;
    }
    if (application.deviceInterface.environment === snjs_1.Environment.Desktop) {
        window.open(url, '_blank');
        return;
    }
    const windowProxy = window.open('', '_blank');
    windowProxy.location = url;
}
exports.openSubscriptionDashboard = openSubscriptionDashboard;
