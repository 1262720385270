"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLinkingSearchResults = void 0;
const snjs_1 = require("@standardnotes/snjs");
const createLinkFromItem_1 = require("./createLinkFromItem");
const doesItemMatchSearchQuery_1 = require("./doesItemMatchSearchQuery");
const isSearchResultAlreadyLinkedToItem_1 = require("./isSearchResultAlreadyLinkedToItem");
const isSearchResultExistingTag_1 = require("./isSearchResultExistingTag");
const MaxLinkedResults = 50;
function resultLimitForSearchQuery(query) {
    const limitPerContentType = 10;
    return Math.max(limitPerContentType, query.length * 3);
}
function getLinkingSearchResults(searchQuery, application, activeItem, options = { returnEmptyIfQueryEmpty: true }) {
    let unlinkedItems = [];
    const linkedItems = [];
    const linkedResults = [];
    let shouldShowCreateTag = false;
    const defaultReturnValue = {
        linkedResults,
        unlinkedItems,
        linkedItems,
        shouldShowCreateTag,
    };
    if (!activeItem) {
        return defaultReturnValue;
    }
    if (!searchQuery.length && options.returnEmptyIfQueryEmpty) {
        return defaultReturnValue;
    }
    const searchableItems = (0, snjs_1.naturalSort)(application.items.getItems([snjs_1.ContentType.Note, snjs_1.ContentType.File, snjs_1.ContentType.Tag]), 'title');
    const unlinkedTags = [];
    const unlinkedNotes = [];
    const unlinkedFiles = [];
    for (let index = 0; index < searchableItems.length; index++) {
        const item = searchableItems[index];
        if (activeItem.uuid === item.uuid) {
            continue;
        }
        if (options.contentType && item.content_type !== options.contentType) {
            continue;
        }
        if (searchQuery.length && !(0, doesItemMatchSearchQuery_1.doesItemMatchSearchQuery)(item, searchQuery, application)) {
            continue;
        }
        if ((0, isSearchResultAlreadyLinkedToItem_1.isSearchResultAlreadyLinkedToItem)(item, activeItem)) {
            if (linkedResults.length < MaxLinkedResults) {
                linkedResults.push((0, createLinkFromItem_1.createLinkFromItem)(item, 'linked'));
            }
            linkedItems.push(item);
            continue;
        }
        const enforceResultLimit = options.contentType == null;
        const limitPerContentType = resultLimitForSearchQuery(searchQuery);
        if (item.content_type === snjs_1.ContentType.Tag &&
            (!enforceResultLimit || (unlinkedTags.length < limitPerContentType && item.content_type === snjs_1.ContentType.Tag))) {
            unlinkedTags.push(item);
            continue;
        }
        if (item.content_type === snjs_1.ContentType.Note && (!enforceResultLimit || unlinkedNotes.length < limitPerContentType)) {
            unlinkedNotes.push(item);
            continue;
        }
        if (item.content_type === snjs_1.ContentType.File && (!enforceResultLimit || unlinkedFiles.length < limitPerContentType)) {
            unlinkedFiles.push(item);
            continue;
        }
    }
    unlinkedItems = [...unlinkedTags, ...unlinkedNotes, ...unlinkedFiles];
    shouldShowCreateTag =
        !linkedResults.find((link) => (0, isSearchResultExistingTag_1.isSearchResultExistingTag)(link.item, searchQuery)) &&
            !unlinkedItems.find((item) => (0, isSearchResultExistingTag_1.isSearchResultExistingTag)(item, searchQuery));
    return {
        linkedResults,
        linkedItems,
        unlinkedItems,
        shouldShowCreateTag,
    };
}
exports.getLinkingSearchResults = getLinkingSearchResults;
