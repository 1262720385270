"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastType = void 0;
var ToastType;
(function (ToastType) {
    ToastType["Regular"] = "regular";
    ToastType["Success"] = "success";
    ToastType["Error"] = "error";
    ToastType["Loading"] = "loading";
    ToastType["Progress"] = "progress";
})(ToastType = exports.ToastType || (exports.ToastType = {}));
