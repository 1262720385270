"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeinitSource = void 0;
var DeinitSource;
(function (DeinitSource) {
    DeinitSource[DeinitSource["SignOut"] = 1] = "SignOut";
    DeinitSource[DeinitSource["Lock"] = 2] = "Lock";
    DeinitSource[DeinitSource["SwitchWorkspace"] = 3] = "SwitchWorkspace";
    DeinitSource[DeinitSource["SignOutAll"] = 4] = "SignOutAll";
})(DeinitSource = exports.DeinitSource || (exports.DeinitSource = {}));
