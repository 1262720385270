"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.decryptPayload = exports.encryptPayload = void 0;
const EncryptedParameters_1 = require("../Types/EncryptedParameters");
const Functions_1 = require("./Functions");
function encryptPayload(payload, key, operatorManager) {
    return __awaiter(this, void 0, void 0, function* () {
        const operator = operatorManager.operatorForVersion(key.keyVersion);
        let encryptionParameters;
        if ((0, Functions_1.isAsyncOperator)(operator)) {
            encryptionParameters = yield operator.generateEncryptedParametersAsync(payload, key);
        }
        else {
            encryptionParameters = operator.generateEncryptedParametersSync(payload, key);
        }
        if (!encryptionParameters) {
            throw 'Unable to generate encryption parameters';
        }
        return encryptionParameters;
    });
}
exports.encryptPayload = encryptPayload;
function decryptPayload(payload, key, operatorManager) {
    return __awaiter(this, void 0, void 0, function* () {
        const operator = operatorManager.operatorForVersion(payload.version);
        try {
            if ((0, Functions_1.isAsyncOperator)(operator)) {
                return yield operator.generateDecryptedParametersAsync((0, EncryptedParameters_1.encryptedParametersFromPayload)(payload), key);
            }
            else {
                return operator.generateDecryptedParametersSync((0, EncryptedParameters_1.encryptedParametersFromPayload)(payload), key);
            }
        }
        catch (e) {
            console.error('Error decrypting payload', payload, e);
            return {
                uuid: payload.uuid,
                errorDecrypting: true,
            };
        }
    });
}
exports.decryptPayload = decryptPayload;
