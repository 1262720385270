"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noteTypeForEditorIdentifier = exports.NoteType = void 0;
const Features_1 = require("../Feature/Features");
const FeatureIdentifier_1 = require("./../Feature/FeatureIdentifier");
var NoteType;
(function (NoteType) {
    NoteType["Authentication"] = "authentication";
    NoteType["Code"] = "code";
    NoteType["Markdown"] = "markdown";
    NoteType["RichText"] = "rich-text";
    NoteType["Spreadsheet"] = "spreadsheet";
    NoteType["Task"] = "task";
    NoteType["Plain"] = "plain-text";
    NoteType["Super"] = "super";
    NoteType["Unknown"] = "unknown";
})(NoteType = exports.NoteType || (exports.NoteType = {}));
function noteTypeForEditorIdentifier(identifier) {
    if (identifier === FeatureIdentifier_1.FeatureIdentifier.PlainEditor) {
        return NoteType.Plain;
    }
    else if (identifier === FeatureIdentifier_1.FeatureIdentifier.SuperEditor) {
        return NoteType.Super;
    }
    const feature = (0, Features_1.FindNativeFeature)(identifier);
    if (feature && feature.note_type) {
        return feature.note_type;
    }
    return NoteType.Unknown;
}
exports.noteTypeForEditorIdentifier = noteTypeForEditorIdentifier;
