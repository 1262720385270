"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEditorMenuGroups = void 0;
const snjs_1 = require("@standardnotes/snjs");
const Constants_1 = require("@/Constants/Constants");
const getNoteTypeForFeatureDescription = (featureDescription) => {
    if (featureDescription.note_type) {
        return featureDescription.note_type;
    }
    else if (featureDescription.file_type) {
        switch (featureDescription.file_type) {
            case 'html':
                return snjs_1.NoteType.RichText;
            case 'md':
                return snjs_1.NoteType.Markdown;
        }
    }
    return snjs_1.NoteType.Unknown;
};
const insertNonInstalledNativeComponentsInMap = (map, components, application) => {
    (0, snjs_1.GetFeatures)()
        .filter((feature) => feature.content_type === snjs_1.ContentType.Component && feature.area === snjs_1.ComponentArea.Editor)
        .forEach((editorFeature) => {
        const notInstalled = !components.find((editor) => editor.identifier === editorFeature.identifier);
        const isExperimental = application.features.isExperimentalFeature(editorFeature.identifier);
        const isDeprecated = editorFeature.deprecated;
        const isShowable = notInstalled && !isExperimental && !isDeprecated;
        if (isShowable) {
            const noteType = getNoteTypeForFeatureDescription(editorFeature);
            map[noteType].push({
                name: editorFeature.name,
                isEntitled: false,
                noteType,
            });
        }
    });
};
const insertInstalledComponentsInMap = (map, components, application) => {
    components.forEach((editor) => {
        const noteType = getNoteTypeForFeatureDescription(editor.package_info);
        const editorItem = {
            name: editor.displayName,
            component: editor,
            isEntitled: application.features.getFeatureStatus(editor.identifier) === snjs_1.FeatureStatus.Entitled,
            noteType,
        };
        map[noteType].push(editorItem);
    });
};
const createGroupsFromMap = (map, _application) => {
    const groups = [
        {
            icon: 'plain-text',
            iconClassName: 'text-accessory-tint-1',
            title: 'Plain text',
            items: map[snjs_1.NoteType.Plain],
        },
        {
            icon: Constants_1.SuperEditorMetadata.icon,
            iconClassName: Constants_1.SuperEditorMetadata.iconClassName,
            title: Constants_1.SuperEditorMetadata.name,
            items: map[snjs_1.NoteType.Super],
            featured: true,
        },
        {
            icon: 'rich-text',
            iconClassName: 'text-accessory-tint-1',
            title: 'Rich text',
            items: map[snjs_1.NoteType.RichText],
        },
        {
            icon: 'markdown',
            iconClassName: 'text-accessory-tint-2',
            title: 'Markdown text',
            items: map[snjs_1.NoteType.Markdown],
        },
        {
            icon: 'tasks',
            iconClassName: 'text-accessory-tint-3',
            title: 'Todo',
            items: map[snjs_1.NoteType.Task],
        },
        {
            icon: 'code',
            iconClassName: 'text-accessory-tint-4',
            title: 'Code',
            items: map[snjs_1.NoteType.Code],
        },
        {
            icon: 'spreadsheets',
            iconClassName: 'text-accessory-tint-5',
            title: 'Spreadsheet',
            items: map[snjs_1.NoteType.Spreadsheet],
        },
        {
            icon: 'authenticator',
            iconClassName: 'text-accessory-tint-6',
            title: 'Authentication',
            items: map[snjs_1.NoteType.Authentication],
        },
        {
            icon: 'editor',
            iconClassName: 'text-neutral',
            title: 'Others',
            items: map[snjs_1.NoteType.Unknown],
        },
    ];
    return groups;
};
const createBaselineMap = (application) => {
    var _a;
    const map = {
        [snjs_1.NoteType.Plain]: [
            {
                name: Constants_1.PlainEditorMetadata.name,
                isEntitled: true,
                noteType: snjs_1.NoteType.Plain,
            },
        ],
        [snjs_1.NoteType.Super]: [
            {
                name: Constants_1.SuperEditorMetadata.name,
                isEntitled: application.features.getFeatureStatus(snjs_1.FeatureIdentifier.SuperEditor) === snjs_1.FeatureStatus.Entitled,
                noteType: snjs_1.NoteType.Super,
                isLabs: true,
                description: (_a = (0, snjs_1.FindNativeFeature)(snjs_1.FeatureIdentifier.SuperEditor)) === null || _a === void 0 ? void 0 : _a.description,
            },
        ],
        [snjs_1.NoteType.RichText]: [],
        [snjs_1.NoteType.Markdown]: [],
        [snjs_1.NoteType.Task]: [],
        [snjs_1.NoteType.Code]: [],
        [snjs_1.NoteType.Spreadsheet]: [],
        [snjs_1.NoteType.Authentication]: [],
        [snjs_1.NoteType.Unknown]: [],
    };
    return map;
};
const createEditorMenuGroups = (application, components) => {
    const map = createBaselineMap(application);
    insertNonInstalledNativeComponentsInMap(map, components, application);
    insertInstalledComponentsInMap(map, components, application);
    return createGroupsFromMap(map, application);
};
exports.createEditorMenuGroups = createEditorMenuGroups;
