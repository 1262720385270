"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPanesChangePush = exports.isPanesChangeLeafDismiss = exports.panesForLayout = void 0;
const AppPaneMetadata_1 = require("../../Components/Panes/AppPaneMetadata");
const PaneLayout_1 = require("./PaneLayout");
const useIsTabletOrMobileScreen_1 = require("@/Hooks/useIsTabletOrMobileScreen");
function panesForLayout(layout, application) {
    const screen = (0, useIsTabletOrMobileScreen_1.getIsTabletOrMobileScreen)(application);
    if (screen.isTablet) {
        if (layout === PaneLayout_1.PaneLayout.TagSelection || layout === PaneLayout_1.PaneLayout.TableView) {
            return [AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items];
        }
        else if (layout === PaneLayout_1.PaneLayout.ItemSelection || layout === PaneLayout_1.PaneLayout.Editing) {
            return [AppPaneMetadata_1.AppPaneId.Items, AppPaneMetadata_1.AppPaneId.Editor];
        }
    }
    else if (screen.isMobile) {
        if (layout === PaneLayout_1.PaneLayout.TagSelection) {
            return [AppPaneMetadata_1.AppPaneId.Navigation];
        }
        else if (layout === PaneLayout_1.PaneLayout.ItemSelection || layout === PaneLayout_1.PaneLayout.TableView) {
            return [AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items];
        }
        else if (layout === PaneLayout_1.PaneLayout.Editing) {
            return [AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items, AppPaneMetadata_1.AppPaneId.Editor];
        }
    }
    else {
        if (layout === PaneLayout_1.PaneLayout.TableView) {
            return [AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items];
        }
        else {
            return [AppPaneMetadata_1.AppPaneId.Navigation, AppPaneMetadata_1.AppPaneId.Items, AppPaneMetadata_1.AppPaneId.Editor];
        }
    }
    throw Error(`Unhandled pane layout ${layout}`);
}
exports.panesForLayout = panesForLayout;
function isPanesChangeLeafDismiss(from, to) {
    const fromWithoutLast = from.slice(0, from.length - 1);
    return fromWithoutLast.length === to.length && fromWithoutLast.every((pane, index) => pane === to[index]);
}
exports.isPanesChangeLeafDismiss = isPanesChangeLeafDismiss;
function isPanesChangePush(from, to) {
    const toWithoutLast = to.slice(0, to.length - 1);
    return toWithoutLast.length === from.length && toWithoutLast.every((pane, index) => pane === from[index]);
}
exports.isPanesChangePush = isPanesChangePush;
