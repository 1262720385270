"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeKeyboardType = void 0;
/** For mobile */
var ChallengeKeyboardType;
(function (ChallengeKeyboardType) {
    ChallengeKeyboardType["Alphanumeric"] = "default";
    ChallengeKeyboardType["Numeric"] = "numeric";
})(ChallengeKeyboardType = exports.ChallengeKeyboardType || (exports.ChallengeKeyboardType = {}));
