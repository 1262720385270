"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntegrityService = void 0;
const IntegrityEvent_1 = require("./IntegrityEvent");
const AbstractService_1 = require("../Service/AbstractService");
const responses_1 = require("@standardnotes/responses");
const SyncEvent_1 = require("../Event/SyncEvent");
class IntegrityService extends AbstractService_1.AbstractService {
    constructor(integrityApi, itemApi, payloadManager, internalEventBus) {
        super(internalEventBus);
        this.integrityApi = integrityApi;
        this.itemApi = itemApi;
        this.payloadManager = payloadManager;
        this.internalEventBus = internalEventBus;
    }
    async handleEvent(event) {
        if (event.type !== SyncEvent_1.SyncEvent.SyncRequestsIntegrityCheck) {
            return;
        }
        const integrityCheckResponse = await this.integrityApi.checkIntegrity(this.payloadManager.integrityPayloads);
        if ((0, responses_1.isErrorResponse)(integrityCheckResponse)) {
            this.log(`Could not obtain integrity check: ${integrityCheckResponse.data.error}`);
            return;
        }
        const serverItemResponsePromises = [];
        for (const mismatch of integrityCheckResponse.data.mismatches) {
            serverItemResponsePromises.push(this.itemApi.getSingleItem(mismatch.uuid));
        }
        const serverItemResponses = await Promise.all(serverItemResponsePromises);
        const rawPayloads = [];
        for (const serverItemResponse of serverItemResponses) {
            if (serverItemResponse.data == undefined ||
                (0, responses_1.isErrorResponse)(serverItemResponse) ||
                !('item' in serverItemResponse.data)) {
                this.log(`Could not obtain item for integrity adjustments: ${(0, responses_1.isErrorResponse)(serverItemResponse) ? serverItemResponse.data.error : ''}`);
                continue;
            }
            rawPayloads.push(serverItemResponse.data.item);
        }
        await this.notifyEventSync(IntegrityEvent_1.IntegrityEvent.IntegrityCheckCompleted, {
            rawPayloads: rawPayloads,
            source: event.payload.source,
        });
    }
}
exports.IntegrityService = IntegrityService;
