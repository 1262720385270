"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeMutator = void 0;
const AppDataField_1 = require("../../Abstract/Item/Types/AppDataField");
const DecryptedItemMutator_1 = require("../../Abstract/Item/Mutator/DecryptedItemMutator");
class ThemeMutator extends DecryptedItemMutator_1.DecryptedItemMutator {
    setMobileRules(rules) {
        this.setAppDataItem(AppDataField_1.AppDataField.MobileRules, rules);
    }
    setNotAvailOnMobile(notAvailable) {
        this.setAppDataItem(AppDataField_1.AppDataField.NotAvailableOnMobile, notAvailable);
    }
    set local_url(local_url) {
        this.mutableContent.local_url = local_url;
    }
    /**
     * We must not use .active because if you set that to true, it will also
     * activate that theme on desktop/web
     */
    setMobileActive(active) {
        this.setAppDataItem(AppDataField_1.AppDataField.MobileActive, active);
    }
}
exports.ThemeMutator = ThemeMutator;
