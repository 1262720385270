"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionController = void 0;
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
class SubscriptionController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.onlineSubscription = undefined;
        this.availableSubscriptions = undefined;
        this.subscriptionInvitations = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    constructor(application, eventBus, subscriptionManager) {
        super(application, eventBus);
        this.subscriptionManager = subscriptionManager;
        this.ALLOWED_SUBSCRIPTION_INVITATIONS = 5;
        this.onlineSubscription = undefined;
        this.availableSubscriptions = undefined;
        this.subscriptionInvitations = undefined;
        this.hasAccount = application.hasAccount();
        this.hasFirstPartySubscription = application.features.hasFirstPartySubscription();
        (0, mobx_1.makeObservable)(this, {
            onlineSubscription: mobx_1.observable,
            availableSubscriptions: mobx_1.observable,
            subscriptionInvitations: mobx_1.observable,
            hasAccount: mobx_1.observable,
            hasFirstPartySubscription: mobx_1.observable,
            userSubscriptionName: mobx_1.computed,
            userSubscriptionExpirationDate: mobx_1.computed,
            isUserSubscriptionExpired: mobx_1.computed,
            isUserSubscriptionCanceled: mobx_1.computed,
            usedInvitationsCount: mobx_1.computed,
            allowedInvitationsCount: mobx_1.computed,
            allInvitationsUsed: mobx_1.computed,
            setUserSubscription: mobx_1.action,
            setAvailableSubscriptions: mobx_1.action,
        });
        this.disposers.push(application.addEventObserver(async () => {
            if (application.hasAccount()) {
                this.getSubscriptionInfo().catch(console.error);
                this.reloadSubscriptionInvitations().catch(console.error);
            }
            (0, mobx_1.runInAction)(() => {
                this.hasFirstPartySubscription = application.features.hasFirstPartySubscription();
                this.hasAccount = application.hasAccount();
            });
        }, snjs_1.ApplicationEvent.Launched));
        this.disposers.push(application.addEventObserver(async () => {
            (0, mobx_1.runInAction)(() => {
                this.hasFirstPartySubscription = application.features.hasFirstPartySubscription();
            });
        }, snjs_1.ApplicationEvent.LocalDataLoaded));
        this.disposers.push(application.addEventObserver(async () => {
            this.getSubscriptionInfo().catch(console.error);
            this.reloadSubscriptionInvitations().catch(console.error);
            (0, mobx_1.runInAction)(() => {
                this.hasAccount = application.hasAccount();
            });
        }, snjs_1.ApplicationEvent.SignedIn));
        this.disposers.push(application.addEventObserver(async () => {
            this.getSubscriptionInfo().catch(console.error);
            this.reloadSubscriptionInvitations().catch(console.error);
            (0, mobx_1.runInAction)(() => {
                this.hasFirstPartySubscription = application.features.hasFirstPartySubscription();
            });
        }, snjs_1.ApplicationEvent.UserRolesChanged));
    }
    get userSubscriptionName() {
        if (this.availableSubscriptions &&
            this.onlineSubscription &&
            this.availableSubscriptions[this.onlineSubscription.planName]) {
            return this.availableSubscriptions[this.onlineSubscription.planName].name;
        }
        return '';
    }
    get userSubscriptionExpirationDate() {
        if (!this.onlineSubscription) {
            return undefined;
        }
        return new Date((0, snjs_1.convertTimestampToMilliseconds)(this.onlineSubscription.endsAt));
    }
    get isUserSubscriptionExpired() {
        if (!this.userSubscriptionExpirationDate) {
            return false;
        }
        return this.userSubscriptionExpirationDate.getTime() < new Date().getTime();
    }
    get isUserSubscriptionCanceled() {
        var _a;
        return Boolean((_a = this.onlineSubscription) === null || _a === void 0 ? void 0 : _a.cancelled);
    }
    hasValidSubscription() {
        return this.onlineSubscription != undefined && !this.isUserSubscriptionExpired && !this.isUserSubscriptionCanceled;
    }
    get usedInvitationsCount() {
        var _a, _b;
        return ((_b = (_a = this.subscriptionInvitations) === null || _a === void 0 ? void 0 : _a.filter((invitation) => [snjs_1.InvitationStatus.Accepted, snjs_1.InvitationStatus.Sent].includes(invitation.status)).length) !== null && _b !== void 0 ? _b : 0);
    }
    get allowedInvitationsCount() {
        return this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    get allInvitationsUsed() {
        return this.usedInvitationsCount === this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    setUserSubscription(subscription) {
        this.onlineSubscription = subscription;
    }
    setAvailableSubscriptions(subscriptions) {
        this.availableSubscriptions = subscriptions;
    }
    async sendSubscriptionInvitation(inviteeEmail) {
        const success = await this.subscriptionManager.inviteToSubscription(inviteeEmail);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async cancelSubscriptionInvitation(invitationUuid) {
        const success = await this.subscriptionManager.cancelInvitation(invitationUuid);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async getAvailableSubscriptions() {
        try {
            const subscriptions = await this.application.getAvailableSubscriptions();
            if (!(subscriptions instanceof snjs_1.ClientDisplayableError)) {
                this.setAvailableSubscriptions(subscriptions);
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async getSubscription() {
        try {
            const subscription = await this.application.getUserSubscription();
            if (!(subscription instanceof snjs_1.ClientDisplayableError) && subscription) {
                this.setUserSubscription(subscription);
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async getSubscriptionInfo() {
        await this.getSubscription();
        await this.getAvailableSubscriptions();
    }
    async reloadSubscriptionInvitations() {
        this.subscriptionInvitations = await this.subscriptionManager.listSubscriptionInvitations();
    }
}
exports.SubscriptionController = SubscriptionController;
