"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemContentTypeUsesRootKeyEncryption = exports.ContentTypeUsesRootKeyEncryption = exports.FillRootKeyContent = exports.CreateNewRootKey = void 0;
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const RootKey_1 = require("./RootKey");
function CreateNewRootKey(content) {
    const uuid = utils_1.UuidGenerator.GenerateUuid();
    const payload = new models_1.DecryptedPayload(Object.assign({ uuid: uuid, content_type: common_1.ContentType.RootKey, content: FillRootKeyContent(content) }, (0, models_1.PayloadTimestampDefaults)()));
    return new RootKey_1.SNRootKey(payload);
}
exports.CreateNewRootKey = CreateNewRootKey;
function FillRootKeyContent(content) {
    if (!content.version) {
        if (content.dataAuthenticationKey) {
            /**
             * If there's no version stored, it must be either 001 or 002.
             * If there's a dataAuthenticationKey, it has to be 002. Otherwise it's 001.
             */
            content.version = common_1.ProtocolVersion.V002;
        }
        else {
            content.version = common_1.ProtocolVersion.V001;
        }
    }
    return (0, models_1.FillItemContentSpecialized)(content);
}
exports.FillRootKeyContent = FillRootKeyContent;
function ContentTypeUsesRootKeyEncryption(contentType) {
    return (contentType === common_1.ContentType.RootKey ||
        contentType === common_1.ContentType.ItemsKey ||
        contentType === common_1.ContentType.EncryptedStorage);
}
exports.ContentTypeUsesRootKeyEncryption = ContentTypeUsesRootKeyEncryption;
function ItemContentTypeUsesRootKeyEncryption(contentType) {
    return contentType === common_1.ContentType.ItemsKey;
}
exports.ItemContentTypeUsesRootKeyEncryption = ItemContentTypeUsesRootKeyEncryption;
