"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mobx_react_lite_1 = require("mobx-react-lite");
const Content_1 = require("@/Components/Preferences/PreferencesComponents/Content");
const U2FTitle = ({ userProvider }) => {
    if (userProvider.getUser() === undefined) {
        return (0, jsx_runtime_1.jsx)(Content_1.Title, { children: "Hardware security key authentication not available" });
    }
    return (0, jsx_runtime_1.jsx)(Content_1.Title, { children: "Hardware security key authentication" });
};
exports.default = (0, mobx_react_lite_1.observer)(U2FTitle);
