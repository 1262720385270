"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryModalController = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
class HistoryModalController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.note = undefined;
    }
    constructor(application, eventBus, notesController) {
        super(application, eventBus);
        this.note = undefined;
        this.setNote = (note) => {
            this.note = note;
        };
        this.openModal = (note) => {
            this.setNote(note);
        };
        this.dismissModal = () => {
            this.setNote(undefined);
        };
        (0, mobx_1.makeObservable)(this, {
            note: mobx_1.observable,
            setNote: mobx_1.action,
        });
        this.disposers.push(application.keyboardService.addCommandHandler({
            command: ui_services_1.OPEN_NOTE_HISTORY_COMMAND,
            onKeyDown: () => {
                this.openModal(notesController.firstSelectedNote);
                return true;
            },
        }));
    }
}
exports.HistoryModalController = HistoryModalController;
