"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidFutureSiblings = exports.tagSiblings = exports.rootTags = void 0;
const rootTags = (application) => {
    const hasNoParent = (tag) => !application.items.getTagParent(tag);
    const allTags = application.items.getDisplayableTags();
    const rootTags = allTags.filter(hasNoParent);
    return rootTags;
};
exports.rootTags = rootTags;
const tagSiblings = (application, tag) => {
    const withoutCurrentTag = (tags) => tags.filter((other) => other.uuid !== tag.uuid);
    const isTemplateTag = application.items.isTemplateItem(tag);
    const parentTag = !isTemplateTag && application.items.getTagParent(tag);
    if (parentTag) {
        const siblingsAndTag = application.items.getTagChildren(parentTag);
        return withoutCurrentTag(siblingsAndTag);
    }
    return withoutCurrentTag((0, exports.rootTags)(application));
};
exports.tagSiblings = tagSiblings;
const isValidFutureSiblings = (application, futureSiblings, tag) => {
    var _a;
    const siblingWithSameName = futureSiblings.find((otherTag) => otherTag.title === tag.title);
    if (siblingWithSameName) {
        (_a = application.alertService) === null || _a === void 0 ? void 0 : _a.alert(`A tag with the name ${tag.title} already exists at this destination. Please rename this tag before moving and try again.`).catch(console.error);
        return false;
    }
    return true;
};
exports.isValidFutureSiblings = isValidFutureSiblings;
