"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColorsForPrimaryVariant = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const getColorsForNormalVariant = (style) => {
    switch (style) {
        case 'default':
            return 'bg-default text-text';
        case 'contrast':
            return 'bg-default text-contrast';
        case 'neutral':
            return 'bg-default text-neutral';
        case 'info':
            return 'bg-default text-info';
        case 'warning':
            return 'bg-default text-warning';
        case 'danger':
            return 'bg-default text-danger';
        case 'success':
            return 'bg-default text-success';
    }
};
const getColorsForPrimaryVariant = (style) => {
    switch (style) {
        case 'default':
            return 'bg-default text-foreground';
        case 'contrast':
            return 'bg-contrast text-text';
        case 'neutral':
            return 'bg-neutral text-neutral-contrast';
        case 'info':
            return 'bg-info text-info-contrast';
        case 'warning':
            return 'bg-warning text-warning-contrast';
        case 'danger':
            return 'bg-danger text-danger-contrast';
        case 'success':
            return 'bg-success text-success-contrast';
    }
};
exports.getColorsForPrimaryVariant = getColorsForPrimaryVariant;
const getClassName = (primary, style, disabled, fullWidth, small, isRounded) => {
    const borders = primary ? 'no-border' : 'border-solid border-border border';
    const cursor = disabled ? 'cursor-not-allowed' : 'cursor-pointer';
    const width = fullWidth ? 'w-full' : 'w-fit';
    const padding = small ? 'px-3 py-1.5' : 'px-4 py-1.5';
    const textSize = small ? 'text-sm lg:text-xs' : 'text-base lg:text-sm';
    const rounded = isRounded ? 'rounded' : '';
    let colors = primary ? (0, exports.getColorsForPrimaryVariant)(style) : getColorsForNormalVariant(style);
    let focusHoverStates = primary
        ? 'hover:brightness-125 focus:outline-none focus:brightness-125'
        : 'focus:bg-contrast focus:outline-none hover:bg-contrast';
    if (disabled) {
        colors = primary ? 'bg-passive-2 text-info-contrast' : 'bg-default text-passive-2';
        focusHoverStates = primary
            ? 'focus:brightness-100 focus:outline-none hover:brightness-100'
            : 'focus:bg-default focus:outline-none hover:bg-default';
    }
    return `${rounded} font-bold ${width} ${padding} ${textSize} ${colors} ${borders} ${focusHoverStates} ${cursor}`;
};
const Button = (0, react_1.forwardRef)(({ primary = false, label, className = '', colorStyle = primary ? 'info' : 'default', disabled = false, children, fullWidth, small, rounded = true, ...props }, ref) => {
    return ((0, jsx_runtime_1.jsx)("button", { type: "button", className: `${getClassName(primary, colorStyle, disabled, fullWidth, small, rounded)} ${className}`, disabled: disabled, ref: ref, ...props, children: label !== null && label !== void 0 ? label : children }));
});
exports.default = Button;
