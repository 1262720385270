"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.V005Algorithm = exports.V004Algorithm = exports.V003Algorithm = exports.V002Algorithm = exports.V001Algorithm = void 0;
exports.V001Algorithm = Object.freeze({
    SaltSeedLength: 128,
    /**
     * V001 supported a variable PBKDF2 cost
     */
    PbkdfMinCost: 3000,
    PbkdfCostsUsed: [3000, 5000, 10000, 60000],
    PbkdfOutputLength: 512,
    EncryptionKeyLength: 256,
});
exports.V002Algorithm = Object.freeze({
    SaltSeedLength: 128,
    /**
     * V002 supported a variable PBKDF2 cost
     */
    PbkdfMinCost: 3000,
    /**
     * While some 002 accounts also used costs in V001.PbkdfCostsUsed,
     * the vast majority used costs >= 100,000
     */
    PbkdfCostsUsed: exports.V001Algorithm.PbkdfCostsUsed.concat([100000, 101000, 102000, 103000]),
    /** Possible costs used, but statistically more likely these were 001 accounts */
    ImprobablePbkdfCostsUsed: [3000, 5000],
    PbkdfOutputLength: 768,
    EncryptionKeyLength: 256,
    EncryptionIvLength: 128,
});
var V003Algorithm;
(function (V003Algorithm) {
    V003Algorithm[V003Algorithm["SaltSeedLength"] = 256] = "SaltSeedLength";
    V003Algorithm[V003Algorithm["PbkdfCost"] = 110000] = "PbkdfCost";
    V003Algorithm[V003Algorithm["PbkdfOutputLength"] = 768] = "PbkdfOutputLength";
    V003Algorithm[V003Algorithm["EncryptionKeyLength"] = 256] = "EncryptionKeyLength";
    V003Algorithm[V003Algorithm["EncryptionIvLength"] = 128] = "EncryptionIvLength";
})(V003Algorithm = exports.V003Algorithm || (exports.V003Algorithm = {}));
var V004Algorithm;
(function (V004Algorithm) {
    V004Algorithm[V004Algorithm["ArgonSaltSeedLength"] = 256] = "ArgonSaltSeedLength";
    V004Algorithm[V004Algorithm["ArgonSaltLength"] = 128] = "ArgonSaltLength";
    V004Algorithm[V004Algorithm["ArgonIterations"] = 5] = "ArgonIterations";
    V004Algorithm[V004Algorithm["ArgonMemLimit"] = 67108864] = "ArgonMemLimit";
    V004Algorithm[V004Algorithm["ArgonOutputKeyBytes"] = 64] = "ArgonOutputKeyBytes";
    V004Algorithm[V004Algorithm["EncryptionKeyLength"] = 256] = "EncryptionKeyLength";
    V004Algorithm[V004Algorithm["EncryptionNonceLength"] = 192] = "EncryptionNonceLength";
})(V004Algorithm = exports.V004Algorithm || (exports.V004Algorithm = {}));
var V005Algorithm;
(function (V005Algorithm) {
    V005Algorithm[V005Algorithm["AsymmetricEncryptionNonceLength"] = 192] = "AsymmetricEncryptionNonceLength";
    V005Algorithm[V005Algorithm["SymmetricEncryptionNonceLength"] = 192] = "SymmetricEncryptionNonceLength";
})(V005Algorithm = exports.V005Algorithm || (exports.V005Algorithm = {}));
