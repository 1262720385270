"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExperimentalFeatures = exports.LegacyFileSafeIdentifier = exports.FeatureIdentifier = void 0;
var FeatureIdentifier;
(function (FeatureIdentifier) {
    FeatureIdentifier["DailyEmailBackup"] = "org.standardnotes.daily-email-backup";
    FeatureIdentifier["Files"] = "org.standardnotes.files";
    FeatureIdentifier["FilesLowStorageTier"] = "org.standardnotes.files-low-storage-tier";
    FeatureIdentifier["FilesMaximumStorageTier"] = "org.standardnotes.files-max-storage-tier";
    FeatureIdentifier["ListedCustomDomain"] = "org.standardnotes.listed-custom-domain";
    FeatureIdentifier["NoteHistory30Days"] = "org.standardnotes.note-history-30";
    FeatureIdentifier["NoteHistory365Days"] = "org.standardnotes.note-history-365";
    FeatureIdentifier["NoteHistoryUnlimited"] = "org.standardnotes.note-history-unlimited";
    FeatureIdentifier["SignInAlerts"] = "com.standardnotes.sign-in-alerts";
    FeatureIdentifier["SmartFilters"] = "org.standardnotes.smart-filters";
    FeatureIdentifier["TagNesting"] = "org.standardnotes.tag-nesting";
    FeatureIdentifier["TwoFactorAuth"] = "org.standardnotes.two-factor-auth";
    FeatureIdentifier["UniversalSecondFactor"] = "org.standardnotes.universal-second-factor";
    FeatureIdentifier["SubscriptionSharing"] = "org.standardnotes.subscription-sharing";
    FeatureIdentifier["AutobiographyTheme"] = "org.standardnotes.theme-autobiography";
    FeatureIdentifier["DynamicTheme"] = "org.standardnotes.theme-dynamic";
    FeatureIdentifier["DarkTheme"] = "org.standardnotes.theme-focus";
    FeatureIdentifier["FuturaTheme"] = "org.standardnotes.theme-futura";
    FeatureIdentifier["MidnightTheme"] = "org.standardnotes.theme-midnight";
    FeatureIdentifier["SolarizedDarkTheme"] = "org.standardnotes.theme-solarized-dark";
    FeatureIdentifier["TitaniumTheme"] = "org.standardnotes.theme-titanium";
    FeatureIdentifier["PlainEditor"] = "com.standardnotes.plain-text";
    FeatureIdentifier["SuperEditor"] = "com.standardnotes.super-editor";
    FeatureIdentifier["CodeEditor"] = "org.standardnotes.code-editor";
    FeatureIdentifier["MarkdownProEditor"] = "org.standardnotes.advanced-markdown-editor";
    FeatureIdentifier["PlusEditor"] = "org.standardnotes.plus-editor";
    FeatureIdentifier["SheetsEditor"] = "org.standardnotes.standard-sheets";
    FeatureIdentifier["TaskEditor"] = "org.standardnotes.simple-task-editor";
    FeatureIdentifier["TokenVaultEditor"] = "org.standardnotes.token-vault";
    FeatureIdentifier["Extension"] = "org.standardnotes.extension";
    FeatureIdentifier["DeprecatedMarkdownVisualEditor"] = "org.standardnotes.markdown-visual-editor";
    FeatureIdentifier["DeprecatedBoldEditor"] = "org.standardnotes.bold-editor";
    FeatureIdentifier["DeprecatedMarkdownBasicEditor"] = "org.standardnotes.simple-markdown-editor";
    FeatureIdentifier["DeprecatedMarkdownMathEditor"] = "org.standardnotes.fancy-markdown-editor";
    FeatureIdentifier["DeprecatedMarkdownMinimistEditor"] = "org.standardnotes.minimal-markdown-editor";
    FeatureIdentifier["DeprecatedFoldersComponent"] = "org.standardnotes.folders";
    FeatureIdentifier["DeprecatedFileSafe"] = "org.standardnotes.file-safe";
})(FeatureIdentifier = exports.FeatureIdentifier || (exports.FeatureIdentifier = {}));
/**
 * Identifier for standalone filesafe instance offered as legacy installable via extensions-server
 */
exports.LegacyFileSafeIdentifier = 'org.standardnotes.legacy.file-safe';
exports.ExperimentalFeatures = [];
