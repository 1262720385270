"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NavigationController = void 0;
const ui_services_1 = require("@standardnotes/ui-services");
const Strings_1 = require("@/Constants/Strings");
const Constants_1 = require("@/Constants/Constants");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const Utils_1 = require("@/Utils");
const Utils_2 = require("./Utils");
const CrossControllerEvent_1 = require("../CrossControllerEvent");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
const PaneLayout_1 = require("../PaneController/PaneLayout");
class NavigationController extends AbstractViewController_1.AbstractViewController {
    constructor(application, featuresController, eventBus) {
        super(application, eventBus);
        this.featuresController = featuresController;
        this.tags = [];
        this.smartViews = [];
        this.starredTags = [];
        this.allNotesCount_ = 0;
        this.allFilesCount_ = 0;
        this.selectedUuid = undefined;
        this.selected_ = undefined;
        this.selectedLocation = undefined;
        this.previouslySelected_ = undefined;
        this.editing_ = undefined;
        this.addingSubtagTo = undefined;
        this.contextMenuOpen = false;
        this.contextMenuPosition = {
            top: 0,
            left: 0,
        };
        this.contextMenuClickLocation = { x: 0, y: 0 };
        this.contextMenuMaxHeight = 'auto';
        this.findAndSetTag = (uuid) => {
            const tagToSelect = [...this.tags, ...this.smartViews].find((tag) => tag.uuid === uuid);
            if (tagToSelect) {
                void this.setSelectedTag(tagToSelect, (0, snjs_1.isTag)(tagToSelect) ? (tagToSelect.starred ? 'favorites' : 'all') : 'views');
            }
        };
        this.selectHydratedTagOrDefault = () => {
            if (this.selectedUuid && !this.selected_) {
                this.findAndSetTag(this.selectedUuid);
            }
            if (!this.selectedUuid) {
                void this.selectHomeNavigationView();
            }
        };
        this.getPersistableValue = () => {
            return {
                selectedTagUuid: this.selectedUuid ? this.selectedUuid : snjs_1.SystemViewId.AllNotes,
            };
        };
        this.hydrateFromPersistedValue = (state) => {
            const uuidsToPreventHydrationOf = [snjs_1.SystemViewId.Files];
            if (!state || uuidsToPreventHydrationOf.includes(state.selectedTagUuid)) {
                void this.selectHomeNavigationView();
                return;
            }
            if (state.selectedTagUuid) {
                this.selectedUuid = state.selectedTagUuid;
                this.selectHydratedTagOrDefault();
            }
        };
        this.tagsCountsState = new TagsCountsState(this.application);
        this.smartViews = this.application.items.getSmartViews();
        (0, mobx_1.makeObservable)(this, {
            tags: mobx_1.observable,
            starredTags: mobx_1.observable,
            smartViews: mobx_1.observable.ref,
            allNotesCount_: mobx_1.observable,
            allFilesCount_: mobx_1.observable,
            allNotesCount: mobx_1.computed,
            allFilesCount: mobx_1.computed,
            setAllNotesCount: mobx_1.action,
            setAllFilesCount: mobx_1.action,
            selected_: mobx_1.observable,
            selectedLocation: mobx_1.observable,
            previouslySelected_: mobx_1.observable.ref,
            previouslySelected: mobx_1.computed,
            editing_: mobx_1.observable.ref,
            selected: mobx_1.computed,
            selectedUuid: mobx_1.observable,
            editingTag: mobx_1.computed,
            addingSubtagTo: mobx_1.observable,
            setAddingSubtagTo: mobx_1.action,
            assignParent: mobx_1.action,
            rootTags: mobx_1.computed,
            tagsCount: mobx_1.computed,
            createNewTemplate: mobx_1.action,
            undoCreateNewTag: mobx_1.action,
            save: mobx_1.action,
            remove: mobx_1.action,
            contextMenuOpen: mobx_1.observable,
            contextMenuPosition: mobx_1.observable,
            contextMenuMaxHeight: mobx_1.observable,
            contextMenuClickLocation: mobx_1.observable,
            setContextMenuOpen: mobx_1.action,
            setContextMenuClickLocation: mobx_1.action,
            setContextMenuPosition: mobx_1.action,
            setContextMenuMaxHeight: mobx_1.action,
            isInFilesView: mobx_1.computed,
            hydrateFromPersistedValue: mobx_1.action,
        });
        this.disposers.push(this.application.streamItems([snjs_1.ContentType.Tag, snjs_1.ContentType.SmartView], ({ changed, removed }) => {
            (0, mobx_1.runInAction)(() => {
                this.tags = this.application.items.getDisplayableTags();
                this.starredTags = this.tags.filter((tag) => tag.starred);
                this.smartViews = this.application.items.getSmartViews();
                const currentSelectedTag = this.selected_;
                if (!currentSelectedTag) {
                    return;
                }
                const updatedReference = (0, snjs_1.FindItem)(changed, currentSelectedTag.uuid) || (0, snjs_1.FindItem)(this.smartViews, currentSelectedTag.uuid);
                if (updatedReference) {
                    this.setSelectedTagInstance(updatedReference);
                }
                if ((0, snjs_1.isSystemView)(currentSelectedTag)) {
                    return;
                }
                if ((0, snjs_1.FindItem)(removed, currentSelectedTag.uuid)) {
                    this.setSelectedTagInstance(this.smartViews[0]);
                }
            });
        }));
        this.disposers.push(this.application.items.addNoteCountChangeObserver((tagUuid) => {
            if (!tagUuid) {
                this.setAllNotesCount(this.application.items.allCountableNotesCount());
                this.setAllFilesCount(this.application.items.allCountableFilesCount());
            }
            else {
                const tag = this.application.items.findItem(tagUuid);
                if (tag) {
                    this.tagsCountsState.update([tag]);
                }
            }
        }));
        this.disposers.push((0, mobx_1.reaction)(() => this.selectedUuid, () => {
            eventBus.publish({
                type: CrossControllerEvent_1.CrossControllerEvent.RequestValuePersistence,
                payload: undefined,
            });
        }));
        this.disposers.push(this.application.keyboardService.addCommandHandler({
            command: ui_services_1.CREATE_NEW_TAG_COMMAND,
            onKeyDown: () => {
                this.createNewTemplate();
            },
        }));
    }
    deinit() {
        super.deinit();
        this.featuresController = undefined;
        this.tags = undefined;
        this.smartViews = undefined;
        this.selected_ = undefined;
        this.previouslySelected_ = undefined;
        this.editing_ = undefined;
        this.addingSubtagTo = undefined;
        this.featuresController = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    async createSubtagAndAssignParent(parent, title) {
        const hasEmptyTitle = title.length === 0;
        if (hasEmptyTitle) {
            this.setAddingSubtagTo(undefined);
            return;
        }
        const createdTag = (await this.application.mutator.createTagOrSmartView(title));
        const futureSiblings = this.application.items.getTagChildren(parent);
        if (!(0, Utils_2.isValidFutureSiblings)(this.application, futureSiblings, createdTag)) {
            this.setAddingSubtagTo(undefined);
            this.remove(createdTag, false).catch(console.error);
            return;
        }
        this.assignParent(createdTag.uuid, parent.uuid).catch(console.error);
        this.application.sync.sync().catch(console.error);
        (0, mobx_1.runInAction)(() => {
            void this.setSelectedTag(createdTag, 'all');
        });
        this.setAddingSubtagTo(undefined);
    }
    isInSmartView() {
        return this.selected instanceof snjs_1.SmartView;
    }
    isInHomeView() {
        return this.selected instanceof snjs_1.SmartView && this.selected.uuid === snjs_1.SystemViewId.AllNotes;
    }
    get isInFilesView() {
        return this.selectedUuid === snjs_1.SystemViewId.Files;
    }
    isTagFilesView(tag) {
        return tag.uuid === snjs_1.SystemViewId.Files;
    }
    tagUsesTableView(tag) {
        var _a;
        const isSystemView = tag instanceof snjs_1.SmartView && Object.values(snjs_1.SystemViewId).includes(tag.uuid);
        const useTableView = isSystemView
            ? (_a = this.application.getPreference(snjs_1.PrefKey.SystemViewPreferences)) === null || _a === void 0 ? void 0 : _a[tag.uuid]
            : tag === null || tag === void 0 ? void 0 : tag.preferences;
        return Boolean(useTableView);
    }
    isInAnySystemView() {
        return (this.selected instanceof snjs_1.SmartView && Object.values(snjs_1.SystemViewId).includes(this.selected.uuid));
    }
    isInSystemView(id) {
        return this.selected instanceof snjs_1.SmartView && this.selected.uuid === id;
    }
    get selectedAsTag() {
        if (!this.selected || !(0, snjs_1.isTag)(this.selected)) {
            return undefined;
        }
        return this.selected;
    }
    setAddingSubtagTo(tag) {
        this.addingSubtagTo = tag;
    }
    setContextMenuOpen(open) {
        this.contextMenuOpen = open;
    }
    setContextMenuClickLocation(location) {
        this.contextMenuClickLocation = location;
    }
    setContextMenuPosition(position) {
        this.contextMenuPosition = position;
    }
    setContextMenuMaxHeight(maxHeight) {
        this.contextMenuMaxHeight = maxHeight;
    }
    reloadContextMenuLayout() {
        var _a;
        const { clientHeight } = document.documentElement;
        const defaultFontSize = window.getComputedStyle(document.documentElement).fontSize;
        const maxContextMenuHeight = parseFloat(defaultFontSize) * Constants_1.MAX_MENU_SIZE_MULTIPLIER;
        const footerElementRect = (_a = document.getElementById('footer-bar')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const footerHeightInPx = footerElementRect === null || footerElementRect === void 0 ? void 0 : footerElementRect.height;
        let openUpBottom = true;
        if (footerHeightInPx) {
            const bottomSpace = clientHeight - footerHeightInPx - this.contextMenuClickLocation.y;
            const upSpace = this.contextMenuClickLocation.y;
            const notEnoughSpaceToOpenUpBottom = maxContextMenuHeight > bottomSpace;
            if (notEnoughSpaceToOpenUpBottom) {
                const enoughSpaceToOpenBottomUp = upSpace > maxContextMenuHeight;
                if (enoughSpaceToOpenBottomUp) {
                    openUpBottom = false;
                    this.setContextMenuMaxHeight('auto');
                }
                else {
                    const hasMoreUpSpace = upSpace > bottomSpace;
                    if (hasMoreUpSpace) {
                        this.setContextMenuMaxHeight(upSpace - Constants_1.MENU_MARGIN_FROM_APP_BORDER);
                        openUpBottom = false;
                    }
                    else {
                        this.setContextMenuMaxHeight(bottomSpace - Constants_1.MENU_MARGIN_FROM_APP_BORDER);
                    }
                }
            }
            else {
                this.setContextMenuMaxHeight('auto');
            }
        }
        if (openUpBottom) {
            this.setContextMenuPosition({
                top: this.contextMenuClickLocation.y,
                left: this.contextMenuClickLocation.x,
            });
        }
        else {
            this.setContextMenuPosition({
                bottom: clientHeight - this.contextMenuClickLocation.y,
                left: this.contextMenuClickLocation.x,
            });
        }
    }
    get allLocalRootTags() {
        if (this.editing_ instanceof snjs_1.SNTag && this.application.items.isTemplateItem(this.editing_)) {
            return [this.editing_, ...this.rootTags];
        }
        return this.rootTags;
    }
    getNotesCount(tag) {
        return this.tagsCountsState.counts[tag.uuid] || 0;
    }
    getChildren(tag) {
        if (this.application.items.isTemplateItem(tag)) {
            return [];
        }
        const children = this.application.items.getTagChildren(tag);
        const childrenUuids = children.map((childTag) => childTag.uuid);
        const childrenTags = this.tags.filter((tag) => childrenUuids.includes(tag.uuid));
        return childrenTags;
    }
    isValidTagParent(parent, tag) {
        return this.application.items.isValidTagParent(parent, tag);
    }
    hasParent(tagUuid) {
        const item = this.application.items.findItem(tagUuid);
        return !!item && !!item.parentId;
    }
    async assignParent(tagUuid, futureParentUuid) {
        const tag = this.application.items.findItem(tagUuid);
        const currentParent = this.application.items.getTagParent(tag);
        const currentParentUuid = currentParent === null || currentParent === void 0 ? void 0 : currentParent.uuid;
        if (currentParentUuid === futureParentUuid) {
            return;
        }
        const futureParent = futureParentUuid && this.application.items.findItem(futureParentUuid);
        if (!futureParent) {
            const futureSiblings = (0, Utils_2.rootTags)(this.application);
            if (!(0, Utils_2.isValidFutureSiblings)(this.application, futureSiblings, tag)) {
                return;
            }
            await this.application.mutator.unsetTagParent(tag);
        }
        else {
            const futureSiblings = this.application.items.getTagChildren(futureParent);
            if (!(0, Utils_2.isValidFutureSiblings)(this.application, futureSiblings, tag)) {
                return;
            }
            await this.application.mutator.setTagParent(futureParent, tag);
        }
        await this.application.sync.sync();
    }
    get rootTags() {
        return this.tags.filter((tag) => !this.application.items.getTagParent(tag));
    }
    get tagsCount() {
        return this.tags.length;
    }
    setAllNotesCount(allNotesCount) {
        this.allNotesCount_ = allNotesCount;
    }
    setAllFilesCount(allFilesCount) {
        this.allFilesCount_ = allFilesCount;
    }
    get allFilesCount() {
        return this.allFilesCount_;
    }
    get allNotesCount() {
        return this.allNotesCount_;
    }
    get previouslySelected() {
        return this.previouslySelected_;
    }
    get selected() {
        return this.selected_;
    }
    async setPanelWidthForTag(tag, width) {
        await this.application.mutator.changeAndSaveItem(tag, (mutator) => {
            mutator.preferences = {
                ...mutator.preferences,
                panelWidth: width,
            };
        });
    }
    async setSelectedTag(tag, location, { userTriggered } = { userTriggered: false }) {
        if (tag && tag.conflictOf) {
            this.application.mutator
                .changeAndSaveItem(tag, (mutator) => {
                mutator.conflictOf = undefined;
            })
                .catch(console.error);
        }
        if (tag && (this.isTagFilesView(tag) || this.tagUsesTableView(tag))) {
            this.application.paneController.setPaneLayout(PaneLayout_1.PaneLayout.TableView);
        }
        else if (userTriggered) {
            this.application.paneController.setPaneLayout(PaneLayout_1.PaneLayout.ItemSelection);
        }
        this.previouslySelected_ = this.selected_;
        await (0, mobx_1.runInAction)(async () => {
            this.setSelectedTagInstance(tag);
            this.selectedLocation = location;
            if (tag && this.application.items.isTemplateItem(tag)) {
                return;
            }
            await this.eventBus.publishSync({
                type: CrossControllerEvent_1.CrossControllerEvent.TagChanged,
                payload: { tag, previousTag: this.previouslySelected_, userTriggered: userTriggered },
            }, snjs_1.InternalEventPublishStrategy.SEQUENCE);
        });
    }
    async selectHomeNavigationView() {
        await this.setSelectedTag(this.homeNavigationView, 'views');
    }
    async selectFilesView() {
        await this.setSelectedTag(this.filesNavigationView, 'views');
    }
    get homeNavigationView() {
        return this.smartViews[0];
    }
    get filesNavigationView() {
        return this.smartViews.find(this.isTagFilesView);
    }
    setSelectedTagInstance(tag) {
        (0, mobx_1.runInAction)(() => {
            this.selected_ = tag;
            this.selectedUuid = tag ? tag.uuid : undefined;
        });
    }
    setExpanded(tag, expanded) {
        if (tag.expanded === expanded) {
            return;
        }
        this.application.mutator
            .changeAndSaveItem(tag, (mutator) => {
            mutator.expanded = expanded;
        })
            .catch(console.error);
    }
    async setFavorite(tag, favorite) {
        return this.application.mutator
            .changeAndSaveItem(tag, (mutator) => {
            mutator.starred = favorite;
        })
            .catch(console.error);
    }
    setIcon(tag, icon) {
        this.application.mutator
            .changeAndSaveItem(tag, (mutator) => {
            mutator.iconString = icon;
        })
            .catch(console.error);
    }
    get editingTag() {
        return this.editing_;
    }
    setEditingTag(editingTag) {
        (0, mobx_1.runInAction)(() => {
            this.editing_ = editingTag;
            void this.setSelectedTag(editingTag, this.selectedLocation || 'all');
        });
    }
    createNewTemplate() {
        const isAlreadyEditingATemplate = this.editing_ && this.application.items.isTemplateItem(this.editing_);
        if (isAlreadyEditingATemplate) {
            return;
        }
        const newTag = this.application.mutator.createTemplateItem(snjs_1.ContentType.Tag);
        (0, mobx_1.runInAction)(() => {
            this.selectedLocation = 'all';
            this.editing_ = newTag;
        });
    }
    undoCreateNewTag() {
        this.editing_ = undefined;
        const previousTag = this.previouslySelected_ || this.smartViews[0];
        void this.setSelectedTag(previousTag, this.selectedLocation || 'views');
    }
    async remove(tag, userTriggered) {
        let shouldDelete = !userTriggered;
        if (userTriggered) {
            shouldDelete = await (0, ui_services_1.confirmDialog)({
                text: Strings_1.STRING_DELETE_TAG,
                confirmButtonStyle: 'danger',
            });
        }
        if (shouldDelete) {
            this.application.mutator.deleteItem(tag).catch(console.error);
            await this.setSelectedTag(this.smartViews[0], 'views');
        }
    }
    async save(tag, newTitle) {
        var _a;
        const hasEmptyTitle = newTitle.length === 0;
        const hasNotChangedTitle = newTitle === tag.title;
        const isTemplateChange = this.application.items.isTemplateItem(tag);
        const siblings = tag instanceof snjs_1.SNTag ? (0, Utils_2.tagSiblings)(this.application, tag) : [];
        const hasDuplicatedTitle = siblings.some((other) => other.title.toLowerCase() === newTitle.toLowerCase());
        (0, mobx_1.runInAction)(() => {
            this.editing_ = undefined;
        });
        if (hasEmptyTitle || hasNotChangedTitle) {
            if (isTemplateChange) {
                this.undoCreateNewTag();
            }
            return;
        }
        if (hasDuplicatedTitle) {
            if (isTemplateChange) {
                this.undoCreateNewTag();
            }
            (_a = this.application.alertService) === null || _a === void 0 ? void 0 : _a.alert('A tag with this name already exists.').catch(console.error);
            return;
        }
        if (isTemplateChange) {
            const isSmartViewTitle = this.application.items.isSmartViewTitle(newTitle);
            if (isSmartViewTitle) {
                if (!this.featuresController.hasSmartViews) {
                    await this.featuresController.showPremiumAlert(Constants_1.SMART_TAGS_FEATURE_NAME);
                    return;
                }
            }
            const insertedTag = await this.application.mutator.createTagOrSmartView(newTitle);
            this.application.sync.sync().catch(console.error);
            (0, mobx_1.runInAction)(() => {
                void this.setSelectedTag(insertedTag, this.selectedLocation || 'views');
            });
        }
        else {
            await this.application.mutator.changeAndSaveItem(tag, (mutator) => {
                mutator.title = newTitle;
            });
        }
    }
}
exports.NavigationController = NavigationController;
class TagsCountsState {
    constructor(application) {
        this.application = application;
        this.counts = {};
        (0, mobx_1.makeAutoObservable)(this, {
            counts: mobx_1.observable.ref,
            update: mobx_1.action,
        });
    }
    update(tags) {
        const newCounts = Object.assign({}, this.counts);
        tags.forEach((tag) => {
            newCounts[tag.uuid] = this.application.items.countableNotesForTag(tag);
        });
        this.counts = newCounts;
    }
}
