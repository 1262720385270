"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const DropdownItemsForEditors_1 = require("@/Utils/DropdownItemsForEditors");
const snjs_1 = require("@standardnotes/snjs");
const ApplicationProvider_1 = require("../ApplicationProvider");
const PredicateKeypaths_1 = require("./PredicateKeypaths");
const PredicateValue = ({ keypath, value, setValue }) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const type = PredicateKeypaths_1.PredicateKeypathTypes[keypath];
    const editorItems = (0, DropdownItemsForEditors_1.getDropdownItemsForAllEditors)(application);
    return type === 'noteType' ? ((0, jsx_runtime_1.jsx)("select", { className: "flex-grow rounded border border-border bg-default py-1.5 px-2 focus:outline focus:outline-1 focus:outline-info", value: value, onChange: (event) => {
            setValue(event.target.value);
        }, children: Object.entries(snjs_1.NoteType).map(([key, value]) => ((0, jsx_runtime_1.jsx)("option", { value: value, children: key }, key))) })) : type === 'editorIdentifier' ? ((0, jsx_runtime_1.jsx)("select", { className: "flex-grow rounded border border-border bg-default py-1.5 px-2 focus:outline focus:outline-1 focus:outline-info", value: value, onChange: (event) => {
            setValue(event.target.value);
        }, children: editorItems.map((editor) => ((0, jsx_runtime_1.jsx)("option", { value: editor.value, children: editor.label }, editor.value))) })) : type === 'string' || type === 'date' ? ((0, jsx_runtime_1.jsx)("input", { className: "flex-grow rounded border border-border bg-default py-1.5 px-2", value: value, onChange: (event) => {
            setValue(event.target.value);
        } })) : type === 'boolean' ? ((0, jsx_runtime_1.jsxs)("select", { className: "flex-grow rounded border border-border bg-default py-1.5 px-2 focus:outline focus:outline-1 focus:outline-info", value: value, onChange: (event) => {
            setValue(event.target.value);
        }, children: [(0, jsx_runtime_1.jsx)("option", { value: "true", children: "True" }), (0, jsx_runtime_1.jsx)("option", { value: "false", children: "False" })] })) : type === 'number' ? ((0, jsx_runtime_1.jsx)("input", { type: "number", className: "flex-grow rounded border border-border bg-default py-1.5 px-2", value: value, onChange: (event) => {
            setValue(event.target.value);
        } })) : null;
};
exports.default = PredicateValue;
