"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagItemsIndex = void 0;
const utils_1 = require("@standardnotes/utils");
const common_1 = require("@standardnotes/common");
const Tag_1 = require("../../../Syncable/Tag/Tag");
const Item_1 = require("../../../Abstract/Item");
class TagItemsIndex {
    constructor(collection, observers = []) {
        this.collection = collection;
        this.observers = observers;
        this.tagToItemsMap = {};
        this.allCountableItems = new Set();
        this.countableItemsByType = new Map();
        this.isItemCountable = (item) => {
            if ((0, Item_1.isDecryptedItem)(item)) {
                return !item.archived && !item.trashed;
            }
            return false;
        };
    }
    addCountChangeObserver(observer) {
        this.observers.push(observer);
        const thislessEventObservers = this.observers;
        return () => {
            (0, utils_1.removeFromArray)(thislessEventObservers, observer);
        };
    }
    notifyObservers(tagUuid) {
        for (const observer of this.observers) {
            observer(tagUuid);
        }
    }
    allCountableItemsCount() {
        return this.allCountableItems.size;
    }
    allCountableNotesCount() {
        var _a;
        return ((_a = this.countableItemsByType.get(common_1.ContentType.Note)) === null || _a === void 0 ? void 0 : _a.size) || 0;
    }
    allCountableFilesCount() {
        var _a;
        return ((_a = this.countableItemsByType.get(common_1.ContentType.File)) === null || _a === void 0 ? void 0 : _a.size) || 0;
    }
    countableItemsForTag(tag) {
        var _a;
        return ((_a = this.tagToItemsMap[tag.uuid]) === null || _a === void 0 ? void 0 : _a.size) || 0;
    }
    onChange(delta) {
        const items = [...delta.changed, ...delta.inserted, ...delta.discarded].filter((i) => i.content_type === common_1.ContentType.Note || i.content_type === common_1.ContentType.File);
        const tags = [...delta.changed, ...delta.inserted].filter(Item_1.isDecryptedItem).filter(Tag_1.isTag);
        this.receiveItemChanges(items);
        this.receiveTagChanges(tags);
    }
    receiveTagChanges(tags) {
        for (const tag of tags) {
            const uuids = tag.references
                .filter((ref) => ref.content_type === common_1.ContentType.Note || ref.content_type === common_1.ContentType.File)
                .map((ref) => ref.uuid);
            const countableUuids = uuids.filter((uuid) => this.allCountableItems.has(uuid));
            const previousSet = this.tagToItemsMap[tag.uuid];
            this.tagToItemsMap[tag.uuid] = new Set(countableUuids);
            if ((previousSet === null || previousSet === void 0 ? void 0 : previousSet.size) !== countableUuids.length) {
                this.notifyObservers(tag.uuid);
            }
        }
    }
    receiveItemChanges(items) {
        var _a, _b;
        const previousAllCount = this.allCountableItems.size;
        for (const item of items) {
            const isCountable = this.isItemCountable(item);
            if (isCountable) {
                this.allCountableItems.add(item.uuid);
                if (!this.countableItemsByType.has(item.content_type)) {
                    this.countableItemsByType.set(item.content_type, new Set());
                }
                (_a = this.countableItemsByType.get(item.content_type)) === null || _a === void 0 ? void 0 : _a.add(item.uuid);
            }
            else {
                this.allCountableItems.delete(item.uuid);
                (_b = this.countableItemsByType.get(item.content_type)) === null || _b === void 0 ? void 0 : _b.delete(item.uuid);
            }
            const associatedTagUuids = this.collection.uuidsThatReferenceUuid(item.uuid);
            for (const tagUuid of associatedTagUuids) {
                const set = this.setForTag(tagUuid);
                const previousCount = set.size;
                if (isCountable) {
                    set.add(item.uuid);
                }
                else {
                    set.delete(item.uuid);
                }
                if (previousCount !== set.size) {
                    this.notifyObservers(tagUuid);
                }
            }
        }
        if (previousAllCount !== this.allCountableItems.size) {
            this.notifyObservers(undefined);
        }
    }
    setForTag(uuid) {
        let set = this.tagToItemsMap[uuid];
        if (!set) {
            set = new Set();
            this.tagToItemsMap[uuid] = set;
        }
        return set;
    }
}
exports.TagItemsIndex = TagItemsIndex;
