"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeManager = void 0;
const toast_1 = require("@standardnotes/toast");
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const services_1 = require("@standardnotes/services");
const features_1 = require("@standardnotes/features");
const CachedThemesKey = 'cachedThemes';
const TimeBeforeApplyingColorScheme = 5;
const DefaultThemeIdentifier = 'Default';
class ThemeManager extends services_1.AbstractService {
    constructor(application, internalEventBus) {
        super(internalEventBus);
        this.application = application;
        this.internalEventBus = internalEventBus;
        this.activeThemes = [];
        this.lastUseDeviceThemeSettings = false;
        this.addAppEventObserverAfterSubclassesFinishConstructing();
        this.colorSchemeEventHandler = this.colorSchemeEventHandler.bind(this);
    }
    async onAppStart() {
        this.registerObservers();
    }
    async onAppEvent(event) {
        var _a;
        switch (event) {
            case services_1.ApplicationEvent.SignedOut: {
                this.deactivateAllThemes();
                this.activeThemes = [];
                (_a = this.application) === null || _a === void 0 ? void 0 : _a.removeValue(CachedThemesKey, services_1.StorageValueModes.Nonwrapped).catch(console.error);
                break;
            }
            case services_1.ApplicationEvent.StorageReady: {
                await this.activateCachedThemes();
                break;
            }
            case services_1.ApplicationEvent.FeaturesUpdated: {
                this.handleFeaturesUpdated();
                break;
            }
            case services_1.ApplicationEvent.Launched: {
                if (!this.application.isNativeMobileWeb()) {
                    const mq = window.matchMedia('(prefers-color-scheme: dark)');
                    if (mq.addEventListener != undefined) {
                        mq.addEventListener('change', this.colorSchemeEventHandler);
                    }
                    else {
                        mq.addListener(this.colorSchemeEventHandler);
                    }
                }
                break;
            }
            case services_1.ApplicationEvent.PreferencesChanged: {
                void this.handlePreferencesChangeEvent();
                break;
            }
        }
    }
    addAppEventObserverAfterSubclassesFinishConstructing() {
        setTimeout(() => {
            this.addAppEventObserver();
        }, 0);
    }
    addAppEventObserver() {
        if (this.application.isStarted()) {
            void this.onAppStart();
        }
        this.unsubApp = this.application.addEventObserver(async (event) => {
            await this.onAppEvent(event);
            if (event === services_1.ApplicationEvent.Started) {
                void this.onAppStart();
            }
        });
    }
    async handleMobileColorSchemeChangeEvent() {
        const useDeviceThemeSettings = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        if (useDeviceThemeSettings) {
            const prefersDarkColorScheme = (await this.application.mobileDevice().getColorScheme()) === 'dark';
            this.setThemeAsPerColorScheme(prefersDarkColorScheme);
        }
    }
    async handlePreferencesChangeEvent() {
        const useDeviceThemeSettings = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        const hasPreferenceChanged = useDeviceThemeSettings !== this.lastUseDeviceThemeSettings;
        if (hasPreferenceChanged) {
            this.lastUseDeviceThemeSettings = useDeviceThemeSettings;
        }
        if (hasPreferenceChanged && useDeviceThemeSettings) {
            let prefersDarkColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (this.application.isNativeMobileWeb()) {
                prefersDarkColorScheme = (await this.application.mobileDevice().getColorScheme()) === 'dark';
            }
            this.setThemeAsPerColorScheme(prefersDarkColorScheme);
        }
    }
    get webApplication() {
        return this.application;
    }
    deinit() {
        var _a;
        this.activeThemes.length = 0;
        (_a = this.unregisterDesktop) === null || _a === void 0 ? void 0 : _a.call(this);
        this.unregisterStream();
        this.unregisterDesktop = undefined;
        this.unregisterStream = undefined;
        const mq = window.matchMedia('(prefers-color-scheme: dark)');
        if (mq.removeEventListener != undefined) {
            mq.removeEventListener('change', this.colorSchemeEventHandler);
        }
        else {
            mq.removeListener(this.colorSchemeEventHandler);
        }
        ;
        this.application = undefined;
        this.unsubApp();
        this.unsubApp = undefined;
        super.deinit();
    }
    handleFeaturesUpdated() {
        let hasChange = false;
        for (const themeUuid of this.activeThemes) {
            const theme = this.application.items.findItem(themeUuid);
            if (!theme) {
                this.deactivateTheme(themeUuid);
                hasChange = true;
                continue;
            }
            const status = this.application.features.getFeatureStatus(theme.identifier);
            if (status !== services_1.FeatureStatus.Entitled) {
                if (theme.active) {
                    this.application.mutator.toggleTheme(theme).catch(console.error);
                }
                else {
                    this.deactivateTheme(theme.uuid);
                }
                hasChange = true;
            }
        }
        const activeThemes = this.application.items.getItems(common_1.ContentType.Theme).filter((theme) => theme.active);
        for (const theme of activeThemes) {
            if (!this.activeThemes.includes(theme.uuid)) {
                this.activateTheme(theme);
                hasChange = true;
            }
        }
        if (hasChange) {
            this.cacheThemeState().catch(console.error);
        }
    }
    colorSchemeEventHandler(event) {
        const shouldChangeTheme = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        if (shouldChangeTheme) {
            this.setThemeAsPerColorScheme(event.matches);
        }
    }
    showColorSchemeToast(setThemeCallback) {
        const [toastId, intervalId] = (0, toast_1.addTimedToast)({
            type: toast_1.ToastType.Regular,
            message: (timeRemaining) => `Applying system color scheme in ${timeRemaining}s...`,
            actions: [
                {
                    label: 'Keep current theme',
                    handler: () => {
                        (0, toast_1.dismissToast)(toastId);
                        clearInterval(intervalId);
                    },
                },
                {
                    label: 'Apply now',
                    handler: () => {
                        (0, toast_1.dismissToast)(toastId);
                        clearInterval(intervalId);
                        setThemeCallback();
                    },
                },
            ],
        }, setThemeCallback, TimeBeforeApplyingColorScheme);
    }
    setThemeAsPerColorScheme(prefersDarkColorScheme) {
        const preference = prefersDarkColorScheme ? models_1.PrefKey.AutoDarkThemeIdentifier : models_1.PrefKey.AutoLightThemeIdentifier;
        const preferenceDefault = preference === models_1.PrefKey.AutoDarkThemeIdentifier ? features_1.FeatureIdentifier.DarkTheme : DefaultThemeIdentifier;
        const themes = this.application.items
            .getDisplayableComponents()
            .filter((component) => component.isTheme());
        const activeTheme = themes.find((theme) => theme.active && !theme.isLayerable());
        const activeThemeIdentifier = activeTheme ? activeTheme.identifier : DefaultThemeIdentifier;
        const themeIdentifier = this.application.getPreference(preference, preferenceDefault);
        const toggleActiveTheme = () => {
            if (activeTheme) {
                void this.application.mutator.toggleTheme(activeTheme);
            }
        };
        const setTheme = () => {
            if (themeIdentifier === DefaultThemeIdentifier) {
                toggleActiveTheme();
            }
            else {
                const theme = themes.find((theme) => theme.package_info.identifier === themeIdentifier);
                if (theme && !theme.active) {
                    this.application.mutator.toggleTheme(theme).catch(console.error);
                }
            }
        };
        const isPreferredThemeNotActive = activeThemeIdentifier !== themeIdentifier;
        if (isPreferredThemeNotActive) {
            this.showColorSchemeToast(setTheme);
        }
    }
    async activateCachedThemes() {
        const cachedThemes = await this.getCachedThemes();
        for (const theme of cachedThemes) {
            this.activateTheme(theme, true);
        }
    }
    registerObservers() {
        var _a;
        this.unregisterDesktop = (_a = this.webApplication.getDesktopService()) === null || _a === void 0 ? void 0 : _a.registerUpdateObserver((component) => {
            if (component.active && component.isTheme()) {
                this.deactivateTheme(component.uuid);
                setTimeout(() => {
                    this.activateTheme(component);
                    this.cacheThemeState().catch(console.error);
                }, 10);
            }
        });
        this.unregisterStream = this.application.streamItems(common_1.ContentType.Theme, ({ changed, inserted, source }) => {
            const items = changed.concat(inserted);
            const themes = items;
            for (const theme of themes) {
                if (theme.active) {
                    this.activateTheme(theme);
                }
                else {
                    this.deactivateTheme(theme.uuid);
                }
            }
            if (source !== models_1.PayloadEmitSource.LocalRetrieved) {
                this.cacheThemeState().catch(console.error);
            }
        });
    }
    deactivateAllThemes() {
        const activeThemes = this.activeThemes.slice();
        for (const uuid of activeThemes) {
            this.deactivateTheme(uuid);
        }
    }
    activateTheme(theme, skipEntitlementCheck = false) {
        if (this.activeThemes.find((uuid) => uuid === theme.uuid)) {
            return;
        }
        if (!skipEntitlementCheck &&
            this.application.features.getFeatureStatus(theme.identifier) !== services_1.FeatureStatus.Entitled) {
            return;
        }
        const url = this.application.componentManager.urlForComponent(theme);
        if (!url) {
            return;
        }
        this.activeThemes.push(theme.uuid);
        const link = document.createElement('link');
        link.href = url;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.media = 'screen,print';
        link.id = theme.uuid;
        link.onload = () => {
            this.syncThemeColorMetadata();
            if (this.application.isNativeMobileWeb()) {
                setTimeout(() => {
                    var _a;
                    this.application
                        .mobileDevice()
                        .handleThemeSchemeChange((_a = theme.package_info.isDark) !== null && _a !== void 0 ? _a : false, this.getBackgroundColor());
                });
            }
        };
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    getBackgroundColor() {
        const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--sn-stylekit-background-color').trim();
        return bgColor.length ? bgColor : '#ffffff';
    }
    /**
     * Syncs the active theme's background color to the 'theme-color' meta tag
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color
     */
    syncThemeColorMetadata() {
        const themeColorMetaElement = document.querySelector('meta[name="theme-color"]');
        if (!themeColorMetaElement) {
            return;
        }
        themeColorMetaElement.setAttribute('content', this.getBackgroundColor());
    }
    deactivateTheme(uuid) {
        var _a;
        if (!this.activeThemes.includes(uuid)) {
            return;
        }
        const element = document.getElementById(uuid);
        if (element) {
            element.disabled = true;
            (_a = element.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(element);
        }
        (0, utils_1.removeFromArray)(this.activeThemes, uuid);
        if (this.activeThemes.length === 0 && this.application.isNativeMobileWeb()) {
            this.application.mobileDevice().handleThemeSchemeChange(false, '#ffffff');
        }
    }
    async cacheThemeState() {
        const themes = this.application.items.findItems(this.activeThemes);
        const mapped = themes.map((theme) => {
            const payload = theme.payloadRepresentation();
            return (0, models_1.CreateDecryptedLocalStorageContextPayload)(payload);
        });
        return this.application.setValue(CachedThemesKey, mapped, services_1.StorageValueModes.Nonwrapped);
    }
    async getCachedThemes() {
        const cachedThemes = (await this.application.getValue(CachedThemesKey, services_1.StorageValueModes.Nonwrapped));
        if (cachedThemes) {
            const themes = [];
            for (const cachedTheme of cachedThemes) {
                const payload = this.application.items.createPayloadFromObject(cachedTheme);
                const theme = this.application.items.createItemFromPayload(payload);
                themes.push(theme);
            }
            return themes;
        }
        else {
            return [];
        }
    }
}
exports.ThemeManager = ThemeManager;
