"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RevisionManager = void 0;
const responses_1 = require("@standardnotes/responses");
const AbstractService_1 = require("../Service/AbstractService");
class RevisionManager extends AbstractService_1.AbstractService {
    constructor(revisionApiService, internalEventBus) {
        super(internalEventBus);
        this.revisionApiService = revisionApiService;
        this.internalEventBus = internalEventBus;
    }
    async listRevisions(itemUuid) {
        const result = await this.revisionApiService.listRevisions(itemUuid.value);
        if ((0, responses_1.isErrorResponse)(result)) {
            throw new Error(result.data.error.message);
        }
        return result.data.revisions;
    }
    async deleteRevision(itemUuid, revisionUuid) {
        const result = await this.revisionApiService.deleteRevision(itemUuid.value, revisionUuid.value);
        if ((0, responses_1.isErrorResponse)(result)) {
            throw new Error(result.data.error.message);
        }
        return result.data.message;
    }
    async getRevision(itemUuid, revisionUuid) {
        const result = await this.revisionApiService.getRevision(itemUuid.value, revisionUuid.value);
        if ((0, responses_1.isErrorResponse)(result)) {
            throw new Error(result.data.error.message);
        }
        return result.data.revision;
    }
}
exports.RevisionManager = RevisionManager;
