"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UuidMap = void 0;
const Utils_1 = require("../Utils/Utils");
/**
 * Maps a UUID to an array of UUIDS to establish either direct or inverse
 * relationships between UUID strings (represantative of items or payloads).
 */
class UuidMap {
    constructor() {
        /** uuid to uuids that we have a relationship with */
        this.directMap = {};
        /** uuid to uuids that have a relationship with us */
        this.inverseMap = {};
    }
    makeCopy() {
        const copy = new UuidMap();
        copy.directMap = Object.assign({}, this.directMap);
        copy.inverseMap = Object.assign({}, this.inverseMap);
        return copy;
    }
    getDirectRelationships(uuid) {
        return this.directMap[uuid] || [];
    }
    getInverseRelationships(uuid) {
        return this.inverseMap[uuid] || [];
    }
    establishRelationship(uuidA, uuidB) {
        this.establishDirectRelationship(uuidA, uuidB);
        this.establishInverseRelationship(uuidA, uuidB);
    }
    deestablishRelationship(uuidA, uuidB) {
        this.deestablishDirectRelationship(uuidA, uuidB);
        this.deestablishInverseRelationship(uuidA, uuidB);
    }
    setAllRelationships(uuid, relationships) {
        const previousDirect = this.directMap[uuid] || [];
        this.directMap[uuid] = relationships;
        /** Remove all previous values in case relationships have changed
         * The updated references will be added afterwards.
         */
        for (const previousRelationship of previousDirect) {
            this.deestablishInverseRelationship(uuid, previousRelationship);
        }
        /** Now map current relationships */
        for (const newRelationship of relationships) {
            this.establishInverseRelationship(uuid, newRelationship);
        }
    }
    removeFromMap(uuid) {
        /** Items that we reference */
        const directReferences = this.directMap[uuid] || [];
        for (const directReference of directReferences) {
            (0, Utils_1.removeFromArray)(this.inverseMap[directReference] || [], uuid);
        }
        delete this.directMap[uuid];
        /** Items that are referencing us */
        const inverseReferences = this.inverseMap[uuid] || [];
        for (const inverseReference of inverseReferences) {
            (0, Utils_1.removeFromArray)(this.directMap[inverseReference] || [], uuid);
        }
        delete this.inverseMap[uuid];
    }
    establishDirectRelationship(uuidA, uuidB) {
        const index = this.directMap[uuidA] || [];
        (0, Utils_1.addIfUnique)(index, uuidB);
        this.directMap[uuidA] = index;
    }
    establishInverseRelationship(uuidA, uuidB) {
        const inverseIndex = this.inverseMap[uuidB] || [];
        (0, Utils_1.addIfUnique)(inverseIndex, uuidA);
        this.inverseMap[uuidB] = inverseIndex;
    }
    deestablishDirectRelationship(uuidA, uuidB) {
        const index = this.directMap[uuidA] || [];
        (0, Utils_1.removeFromArray)(index, uuidB);
        this.directMap[uuidA] = index;
    }
    deestablishInverseRelationship(uuidA, uuidB) {
        const inverseIndex = this.inverseMap[uuidB] || [];
        (0, Utils_1.removeFromArray)(inverseIndex, uuidA);
        this.inverseMap[uuidB] = inverseIndex;
    }
}
exports.UuidMap = UuidMap;
