"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertService = exports.ButtonType = void 0;
/* istanbul ignore file */
var ButtonType;
(function (ButtonType) {
    ButtonType[ButtonType["Info"] = 0] = "Info";
    ButtonType[ButtonType["Danger"] = 1] = "Danger";
})(ButtonType = exports.ButtonType || (exports.ButtonType = {}));
class AlertService {
    showErrorAlert(error) {
        return this.alert(error.text, error.title);
    }
}
exports.AlertService = AlertService;
